export enum RoleActionType {
  GET_ROLES = "GET_ROLES",
  GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS",
  GET_ROLES_FAILURE = "GET_ROLES_FAILURE",
  CREATE_ROLE = "CREATE_ROLE",
  CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS",
  CREATE_ROLE_FAILURE = "CREATE_ROLE_FAILURE",
  UPDATE_ROLE = "UPDATE_ROLE",
  UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS",
  UPDATE_ROLE_FAILURE = "UPDATE_ROLE_FAILURE",
  DELETE_ROLE = "DELETE_ROLE",
  DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS",
  DELETE_ROLE_FAILURE = "DELETE_ROLE_FAILURE",
}
