import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "antd";
import { PageRoutesConstant } from "../PageRoutes.constant";

const PageRoutes: FC = () => {
  return (
    <Layout>
      <Routes>
        {Object.keys(PageRoutesConstant.Page).map((key) => (
          <Route
            key={key}
            path={PageRoutesConstant.Page[key].path}
            Component={PageRoutesConstant.Page[key].component}
          />
        ))}
      </Routes>
    </Layout>
  );
};

export default PageRoutes;
