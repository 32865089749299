import { httpWithTokenInHeader } from "clients/api.clients.base";
import { UserClient, UserListRequestDto } from "clients/api.generated.clients";
import { Dispatch } from "react";
import { UserActionType } from "store/user/actionTypes";
import { Action } from "store/user/actions";

const userClient = new UserClient("", httpWithTokenInHeader);

export const addUser =
  (values: any, cb?: (res) => void) => async (dispatch: Dispatch<Action>) => {
    const user = {
      ...values,
    };

    dispatch({
      type: UserActionType.ADD_USER,
    });

    await userClient
      .addUser(user)
      .then((response) => {
        if (response.success) {
          dispatch({
            type: UserActionType.ADD_USER_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: UserActionType.ADD_USER_FAILURE,
            payload: response?.message,
          });
        }
        cb && cb(response);
      })
      .catch((err) => {
        dispatch({
          type: UserActionType.ADD_USER_FAILURE,
          payload: err.message,
        });
        cb && cb(err);
      });
  };

export const getUsers =
  (values: UserListRequestDto, cb?: (res) => void) =>
  async (dispatch: Dispatch<Action>) => {
    const userListRequest = {
      ...values,
    };

    dispatch({
      type: UserActionType.GET_USERS,
    });
    await userClient
      .getAllUsers(userListRequest)
      .then((response) => {
        if (response.success) {
          dispatch({
            type: UserActionType.GET_USERS_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: UserActionType.GET_USERS_FAILURE,
            payload: response?.message,
          });
        }
        cb && cb(response);
      })
      .catch((err) => {
        dispatch({
          type: UserActionType.GET_USERS_FAILURE,
          payload: err.message,
        });
        cb && cb(err);
      });
  };

export const updateUserRole =
  (values: any, cb?: (res) => void) => async (dispatch: Dispatch<Action>) => {
    const user = {
      ...values,
    };

    dispatch({
      type: UserActionType.UPDATE_USER_ROLE,
    });

    await userClient
      .updateUserRole(user)
      .then((response) => {
        if (response.success) {
          dispatch({
            type: UserActionType.UPDATE_USER_ROLE_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: UserActionType.UPDATE_USER_ROLE_FAILURE,
            payload: response?.message,
          });
        }
        cb && cb(response);
      })
      .catch((err) => {
        dispatch({
          type: UserActionType.UPDATE_USER_ROLE_FAILURE,
          payload: err.message,
        });
        cb && cb(err);
      });
  };

export const getUserNamesByRoleId =
  (value: string, cb?: (res) => void) => async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: UserActionType.GET_USER_NAMES,
    });
    await userClient
      .getAllUserNamesByRoleId(value)
      .then((response) => {
        if (response.success) {
          dispatch({
            type: UserActionType.GET_USER_NAMES_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: UserActionType.GET_USER_NAMES_FAILURE,
            payload: response?.message,
          });
        }
        cb && cb(response);
      })
      .catch((err) => {
        dispatch({
          type: UserActionType.GET_USER_NAMES_FAILURE,
          payload: err.message,
        });
        cb && cb(err);
      });
  };

export const getUserRolesAndPermissionsByUserName =
  (cb?: (res) => void) => async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: UserActionType.GET_USER_ROLES_AND_PERMISSIONS,
    });
    await userClient
      .getUserRolesAndPermissionsByUserName()
      .then((response) => {
        if (response.success) {
          dispatch({
            type: UserActionType.GET_USER_ROLES_AND_PERMISSIONS_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: UserActionType.GET_USER_ROLES_AND_PERMISSIONS_FAILURE,
            payload: response?.message,
          });
        }
        cb && cb(response);
      })
      .catch((err) => {
        dispatch({
          type: UserActionType.GET_USER_ROLES_AND_PERMISSIONS_FAILURE,
          payload: err.message,
        });
        cb && cb(err);
      });
  };

export const updateUserSettings =
  (values: any, cb?: (res) => void) => async (dispatch: Dispatch<Action>) => {
    const settings = {
      ...values,
    };

    dispatch({
      type: UserActionType.UPDATE_USER_SETTINGS,
    });

    await userClient
      .updateUserSettings(settings)
      .then((response) => {
        if (response.success) {
          dispatch({
            type: UserActionType.UPDATE_USER_SETTINGS_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: UserActionType.UPDATE_USER_SETTINGS_FAILURE,
            payload: response?.message,
          });
        }
        cb && cb(response);
      })
      .catch((err) => {
        dispatch({
          type: UserActionType.UPDATE_USER_SETTINGS_FAILURE,
          payload: err.message,
        });
        cb && cb(err);
      });
  };

export const getUserSettingsByUserName =
  (cb?: (res) => void) => async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: UserActionType.GET_USER_SETTINGS,
    });
    await userClient
      .getUserSettingsByUserName()
      .then((response) => {
        if (response.success) {
          dispatch({
            type: UserActionType.GET_USER_SETTINGS_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: UserActionType.GET_USER_SETTINGS_FAILURE,
            payload: response?.message,
          });
        }
        cb && cb(response);
      })
      .catch((err) => {
        dispatch({
          type: UserActionType.GET_USER_SETTINGS_FAILURE,
          payload: err.message,
        });
        cb && cb(err);
      });
  };

export const setUserSettings = (settings) => (dispatch: Dispatch<Action>) => {
  dispatch({
    type: UserActionType.SET_USER_SETTINGS,
    payload: settings,
  });
};
