import React from "react";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { createRoot } from "react-dom/client";
import reportWebVitals from "reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import {
  getAzureADConfig,
  msalConfig,
} from "common/settings/config/AzureAuthenticationConfig";
import "bootstrap/dist/css/bootstrap.css";
import "antd/dist/reset.css";
import "./index.css";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export let msalInstance: any;

const loadAzureConfig = async () => {
  await getAzureADConfig();

  const configuration = msalConfig();
  msalInstance = new PublicClientApplication(configuration);
  const accounts = msalInstance.getAllAccounts();

  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  msalInstance.addEventCallback((event: any) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const { account } = event.payload;
      msalInstance.setActiveAccount(account);
    }

    if (event.eventType === EventType.LOGIN_FAILURE) {
      console.log(EventType.LOGIN_FAILURE, JSON.stringify(event));
    }
  });
};

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

loadAzureConfig().then(() => {
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App msalInstance={msalInstance} />
      </BrowserRouter>
    </React.StrictMode>
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
