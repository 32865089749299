import { Messages } from "common/constants/Messages";
import { useTypedSelector } from "./useTypedSelector";
import { UserDto } from "clients/api.generated.clients";

export const hasAccessByRoles = (role: string | string[]): boolean => {
  const { user, error } = useTypedSelector((state) => state.users);
  if (error === Messages.AccessDenied) {
    return false;
  } else {
    if (Array.isArray(role)) {
      if (role.length === 0) {
        return true;
      } else {
        return role.some((item) => user?.roles?.includes(item));
      }
    } else {
      if (role === "") {
        return true;
      } else {
        return user?.roles?.includes(role);
      }
    }
  }
};

export const hasAccessByPermissions = (
  permission: string | string[]
): boolean => {
  const { user, error } = useTypedSelector((state) => state.users);
  if (error === Messages.AccessDenied) {
    return false;
  } else {
    if (Array.isArray(permission)) {
      if (permission.length === 0) {
        return true;
      } else {
        return permission.some((item) => user?.permissions?.includes(item));
      }
    } else {
      if (permission === "") {
        return true;
      } else {
        return user?.permissions?.includes(permission);
      }
    }
  }
};

export const hasAccessByUserRoles = (userRoles : string | string[], error: string, role:  string | string[]) => {
  if (error === Messages.AccessDenied) {
    return false;
  }
  else{
    if (!userRoles) {
      return false;
    }
    if (Array.isArray(role)) {
      if (role.length === 0) {
        return true;
      } else {
        return role.some(item => userRoles.includes(item));
      }
    } else {
      return role === "" || userRoles.includes(role);
    }
  }
};

export const hasAccessByUserPermissions = (
  user: UserDto,
  error: string,
  permission: string | string[]
): boolean => {
  if (error === Messages.AccessDenied) {
    return false;
  } else {
    if (Array.isArray(permission)) {
      if (permission.length === 0) {
        return true;
      } else {
        return permission.some((item) => user?.permissions?.includes(item));
      }
    } else {
      if (permission === "") {
        return true;
      } else {
        return user?.permissions?.includes(permission);
      }
    }
  }
};