export enum ClaimActionType {
  GET_MOST_RECENT_CLAIM = "GET_MOST_RECENT_CLAIM",
  GET_MOST_RECENT_CLAIM_SUCCESS = "GET_MOST_RECENT_CLAIM_SUCCESS",
  GET_MOST_RECENT_CLAIM_FAILURE = "GET_MOST_RECENT_CLAIM_FAILURE",
  GET_CLAIMS_WITHIN_3_YEARS = "GET_CLAIMS_WITHIN_3_YEARS",
  GET_CLAIMS_WITHIN_3_YEARS_SUCCESS = "GET_CLAIMS_WITHIN_3_YEARS_SUCCESS",
  GET_CLAIMS_WITHIN_3_YEARS_FAILURE = "GET_CLAIMS_WITHIN_3_YEARS_FAILURE",
  GET_ALL_CLAIMS_WITHIN_3_YEARS = "GET_ALL_CLAIMS_WITHIN_3_YEARS",
  GET_ALL_CLAIMS_WITHIN_3_YEARS_SUCCESS = "GET_ALL_CLAIMS_WITHIN_3_YEARS_SUCCESS",
  GET_ALL_CLAIMS_WITHIN_3_YEARS_FAILURE = "GET_ALL_CLAIMS_WITHIN_3_YEARS_FAILURE",
  EXPORT_DATA_EXCEL = "EXPORT_DATA_EXCEL",
  EXPORT_DATA_EXCEL_SUCCESS = "EXPORT_DATA_EXCEL_SUCCESS",
  EXPORT_DATA_EXCEL_FAILURE = "EXPORT_DATA_EXCEL_FAILURE",
  GET_CLAIM_TYPES = "GET_CLAIM_TYPES",
  GET_CLAIM_TYPES_SUCCESS = "GET_CLAIM_TYPES_SUCCESS",
  GET_CLAIM_TYPES_FAILURE = "GET_CLAIM_TYPES_FAILURE",
}
