export enum AnalysisActionType {
    GET_PAYOR_ANALYSIS = "GET_PAYOR_ANALYSIS",
    GET_PAYOR_ANALYSIS_SUCCESS = "GET_PAYOR_ANALYSIS_SUCCESS",
    GET_PAYOR_ANALYSIS_FAILURE = "GET_PAYOR_ANALYSIS_FAILURE",
    GET_LINEOFBUSINESS_ANALYSIS = "GET_LINEOFBUSINESS_ANALYSIS",
    GET_LINEOFBUSINESS_ANALYSIS_SUCCESS = "GET_LINEOFBUSINESS_ANALYSIS_SUCCESS",
    GET_LINEOFBUSINESS_ANALYSIS_FAILURE = "GET_LINEOFBUSINESS_ANALYSIS_FAILURE",
    GET_CLINIC_ANALYSIS = "GET_CLINIC_ANALYSIS",
    GET_CLINIC_ANALYSIS_SUCCESS = "GET_CLINIC_ANALYSIS_SUCCESS",
    GET_CLINIC_ANALYSIS_FAILURE = "GET_CLINIC_ANALYSIS_FAILURE",
    GET_PROVIDERS_ANALYSIS = "GET_PROVIDERS_ANALYSIS",
    GET_PROVIDERS_ANALYSIS_SUCCESS = "GET_PROVIDERS_ANALYSIS_SUCCESS",
    GET_PROVIDERS_ANALYSIS_FAILURE = "GET_PROVIDERS_ANALYSIS_FAILURE",
    EXPORT_EXCEL_PAYOR_ANALYSIS = "EXPORT_EXCEL_PAYOR_ANALYSIS",
    EXPORT_EXCEL_PAYOR_ANALYSIS_SUCCESS = "EXPORT_EXCEL_PAYOR_ANALYSIS_SUCCESS",
    EXPORT_EXCEL_PAYOR_ANALYSIS_FAILURE = "EXPORT_EXCEL_PAYOR_ANALYSIS_FAILURE",
    EXPORT_EXCEL_LINEOFBUSINESS_ANALYSIS = "EXPORT_EXCEL_LINEOFBUSINESS_ANALYSIS",
    EXPORT_EXCEL_LINEOFBUSINESS_ANALYSIS_SUCCESS = "EXPORT_EXCEL_LINEOFBUSINESS_ANALYSIS_SUCCESS",
    EXPORT_EXCEL_LINEOFBUSINESS_ANALYSIS_FAILURE = "EXPORT_EXCEL_LINEOFBUSINESS_ANALYSIS_FAILURE",
    EXPORT_EXCEL_PROVIDERS_ANALYSIS = "EXPORT_EXCEL_PROVIDERS_ANALYSIS",
    EXPORT_EXCEL_PROVIDERS_ANALYSIS_SUCCESS = "EXPORT_EXCEL_PROVIDERS_ANALYSIS_SUCCESS",
    EXPORT_EXCEL_PROVIDERS_ANALYSIS_FAILURE = "EXPORT_EXCEL_PROVIDERS_ANALYSIS_FAILURE",
    EXPORT_EXCEL_CLINIC_ANALYSIS = "EXPORT_EXCEL_CLINIC_ANALYSIS",
    EXPORT_EXCEL_CLINIC_ANALYSIS_SUCCESS = "EXPORT_EXCEL_CLINIC_ANALYSIS_SUCCESS",
    EXPORT_EXCEL_CLINIC_ANALYSIS_FAILURE = "EXPORT_EXCEL_CLINIC_ANALYSIS_FAILURE",
}
