import React, { FC, useEffect, useState } from "react";
import {
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Typography,
  message,
} from "antd";
import { CreateRoleConstants } from "./CreateRole.constant";
import { validateRequired, validateStringWithSpace } from "hooks/validator";
import CustomButton from "common/components/button/CustomButton";
import { useActions } from "hooks/useActions";
import { useTypedSelector } from "hooks/useTypedSelector";
import { PermissionDto } from "clients/api.generated.clients";
import { LoadingOutlined } from "@ant-design/icons";
import { PageRoutesConstant } from "common/router/PageRoutes.constant";
import { useNavigate } from "react-router-dom";
import { Messages } from "common/constants/Messages";
import { Permissions } from "common/constants/Permissions";
import { hasAccessByPermissions } from "hooks/useAccess";
import "./CreateRole.style.scss";

const { Title } = Typography;
const { TextArea } = Input;

const CreateRole: FC = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { getPermissions, createRole } = useActions();
  const navigate = useNavigate();

  const permissions = useTypedSelector((state) => state.permissions);
  const [permissionList, setPermissionList] = useState<
    { label: string; value: string }[]
  >([]);

  const hasAccess = hasAccessByPermissions(Permissions.CreateRoles);

  useEffect(() => {
    if (!hasAccess) {
      navigate(PageRoutesConstant.Page.AccessDenied.path, {
        replace: true,
      });
    }
    getPermissions({});
  }, []);

  useEffect(() => {
    const newPermissionList = (permissions?.data?.items ?? []).map(
      ({ id, name }: PermissionDto) => ({
        label: name,
        value: id,
      })
    );
    setPermissionList(newPermissionList);
  }, [permissions]);

  const onCreateRole = () => {
    setIsLoading(true);
    form
      .validateFields()
      .then((values) => {
        createRole({ ...values }, (res) => {
          if (res.success) {
            form.resetFields();
            message.success(CreateRoleConstants.SuccessMessage, 5);
          } else {
            if (res.message === Messages.AccessDenied) {
              navigate(PageRoutesConstant.Page.AccessDenied.path, {
                replace: true,
              });
            }
            message.error(res.message, 5);
          }
          setIsLoading(false);
        });
      })
      .catch((info) => {
        setIsLoading(false);
        console.log("Validate Failed:", info);
      });
  };

  return (
    <div className="create_role_content">
      <Form form={form} layout="vertical" onFinish={onCreateRole}>
        <Title level={3} className="create_role_title">
          {CreateRoleConstants.PageTitle}
        </Title>
        <Row gutter={[16, 0]}>
          <Col xs={24} xl={14} xxl={16}>
            <Form.Item
              name="name"
              label={CreateRoleConstants.FormItem.Name}
              rules={[{ required: true, validator: validateStringWithSpace }]}
            >
              <Input type="text" autoComplete="off" />
            </Form.Item>
          </Col>
          <Col xs={24} xl={14} xxl={16}>
            <Form.Item
              name="description"
              label={CreateRoleConstants.FormItem.Description}
              rules={[{ required: true, validator: validateRequired }]}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Title level={5} className="create_role_permission_title">
              {CreateRoleConstants.PermissionTitle}
            </Title>
          </Col>
          <Col span={24}>
            <Spin
              size="large"
              tip="Loading..."
              spinning={permissions.loading}
              indicator={<LoadingOutlined />}
            >
              <Form.Item name="permissions">
                <Checkbox.Group>
                  <Space direction="vertical">
                    {permissionList.map(({ label, value }) => (
                      <Checkbox
                        key={value}
                        value={value}
                        className="create_role_permission_name"
                      >
                        {label}
                      </Checkbox>
                    ))}
                  </Space>
                </Checkbox.Group>
              </Form.Item>
            </Spin>
          </Col>
        </Row>
        <Row align="middle" justify="end">
          <Col>
            <CustomButton
              backgroundColor="#B8C7B9"
              text={CreateRoleConstants.SubmitButtonText}
              fontColor="#000000"
              radius={8}
              width={128}
              font={16}
              height={40}
              buttonType="submit"
              onClick={onCreateRole}
              disabled={isLoading}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CreateRole;
