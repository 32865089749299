import { LineofBusinessAction } from "./../store/targets/lobAction";
import { httpWithTokenInHeader } from "clients/api.clients.base";
import {
  ClinicTargetRequestDto,
  LineOfBusinessTargetRequestDto,
  PayorTargetRequestDto,
  TargetListRequestDto,
  TargetsClient,
} from "clients/api.generated.clients";
import { Dispatch } from "react";
import { ClinicTargetsActionType } from "store/targets/clinicActionTypes";
import { ClinicAction } from "store/targets/clinicActions";
import { LineofBusinessTargetsActionType } from "store/targets/lobActionType";
import { PayorAction } from "store/targets/payorAction";
import { PayorTargetsActionType } from "store/targets/payorActionType";

const targetsClient = new TargetsClient("", httpWithTokenInHeader);

export const getPayorTargets =
  (values: TargetListRequestDto, cb?: (res) => void) =>
  async (dispatch: Dispatch<PayorAction>) => {
    const targetListRequest = {
      ...values,
    };

    dispatch({
      type: PayorTargetsActionType.GET_PAYOR_TARGETS,
    });

    await targetsClient
      .getPayorTargets(targetListRequest)
      .then((response) => {
        if (response.success) {
          dispatch({
            type: PayorTargetsActionType.GET_PAYOR_TARGETS_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: PayorTargetsActionType.GET_PAYOR_TARGETS_FAILURE,
            payload: response?.message,
          });
        }
        cb && cb(response);
      })
      .catch((err) => {
        dispatch({
          type: PayorTargetsActionType.GET_PAYOR_TARGETS_FAILURE,
          payload: err.message,
        });
        cb && cb(err);
      });
  };

export const getLineOfBusinessTargets =
  (values: TargetListRequestDto, cb?: (res) => void) =>
  async (dispatch: Dispatch<LineofBusinessAction>) => {
    const targetListRequest = {
      ...values,
    };

    dispatch({
      type: LineofBusinessTargetsActionType.GET_LINE_OF_BUSINESS_TARGETS,
    });

    await targetsClient
      .getLineOfBusinessTargets(targetListRequest)
      .then((response) => {
        if (response.success) {
          dispatch({
            type: LineofBusinessTargetsActionType.GET_LINE_OF_BUSINESS_TARGETS_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: LineofBusinessTargetsActionType.GET_LINE_OF_BUSINESS_TARGETS_FAILURE,
            payload: response?.message,
          });
        }
        cb && cb(response);
      })
      .catch((err) => {
        dispatch({
          type: LineofBusinessTargetsActionType.GET_LINE_OF_BUSINESS_TARGETS_FAILURE,
          payload: err.message,
        });
        cb && cb(err);
      });
  };

export const getClinicTargets =
  (values: TargetListRequestDto, cb?: (res) => void) =>
  async (dispatch: Dispatch<ClinicAction>) => {
    const targetListRequest = {
      ...values,
    };

    dispatch({
      type: ClinicTargetsActionType.GET_CLINIC_TARGETS,
    });

    await targetsClient
      .getClinicTargets(targetListRequest)
      .then((response) => {
        if (response.success) {
          dispatch({
            type: ClinicTargetsActionType.GET_CLINIC_TARGETS_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: ClinicTargetsActionType.GET_CLINIC_TARGETS_FAILURE,
            payload: response?.message,
          });
        }
        cb && cb(response);
      })
      .catch((err) => {
        dispatch({
          type: ClinicTargetsActionType.GET_CLINIC_TARGETS_FAILURE,
          payload: err.message,
        });
        cb && cb(err);
      });
  };

export const setPayorTarget =
  (values: PayorTargetRequestDto, cb?: (res) => void) =>
  async (dispatch: Dispatch<PayorAction>) => {
    const targetListRequest = {
      ...values,
    };

    dispatch({
      type: PayorTargetsActionType.SET_PAYOR_TARGETS,
    });

    await targetsClient
      .setPayorTarget(targetListRequest)
      .then((response) => {
        if (response.success) {
          dispatch({
            type: PayorTargetsActionType.SET_PAYOR_TARGETS_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: PayorTargetsActionType.SET_PAYOR_TARGETS_FAILURE,
            payload: response?.message,
          });
        }
        cb && cb(response);
      })
      .catch((err) => {
        dispatch({
          type: PayorTargetsActionType.SET_PAYOR_TARGETS_FAILURE,
          payload: err.message,
        });
        cb && cb(err);
      });
  };

export const setLineOfBusinessTarget =
  (values: LineOfBusinessTargetRequestDto, cb?: (res) => void) =>
  async (dispatch: Dispatch<LineofBusinessAction>) => {
    const targetListRequest = {
      ...values,
    };

    dispatch({
      type: LineofBusinessTargetsActionType.SET_LINE_OF_BUSINESS_TARGETS,
    });

    await targetsClient
      .setLineOfBusinessTarget(targetListRequest)
      .then((response) => {
        if (response.success) {
          dispatch({
            type: LineofBusinessTargetsActionType.SET_LINE_OF_BUSINESS_TARGETS_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: LineofBusinessTargetsActionType.SET_LINE_OF_BUSINESS_TARGETS_FAILURE,
            payload: response?.message,
          });
        }
        cb && cb(response);
      })
      .catch((err) => {
        dispatch({
          type: LineofBusinessTargetsActionType.SET_LINE_OF_BUSINESS_TARGETS_FAILURE,
          payload: err.message,
        });
        cb && cb(err);
      });
  };

export const setClinicTarget =
  (values: ClinicTargetRequestDto, cb?: (res) => void) =>
  async (dispatch: Dispatch<ClinicAction>) => {
    const targetListRequest = {
      ...values,
    };

    dispatch({
      type: ClinicTargetsActionType.SET_CLINIC_TARGETS,
    });

    await targetsClient
      .setClinicTarget(targetListRequest)
      .then((response) => {
        if (response.success) {
          dispatch({
            type: ClinicTargetsActionType.SET_CLINIC_TARGETS_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: ClinicTargetsActionType.SET_CLINIC_TARGETS_FAILURE,
            payload: response?.message,
          });
        }
        cb && cb(response);
      })
      .catch((err) => {
        dispatch({
          type: ClinicTargetsActionType.SET_CLINIC_TARGETS_FAILURE,
          payload: err.message,
        });
        cb && cb(err);
      });
  };
