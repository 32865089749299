import { PaginationResponseDtoOfChangesHistoryDto } from "clients/api.generated.clients";
import { Action } from "./actions";
import { ChangesHistoryActionType } from "./actionTypes";

export interface ChangesHistoryState {
  loading: boolean;
  error: string | undefined;
  data?: PaginationResponseDtoOfChangesHistoryDto | undefined;
}

const initialState = {
  loading: false,
  error: undefined,
  data: undefined,
};

const ChangesHistoryReducer = (
  state: ChangesHistoryState = initialState,
  action: Action
): ChangesHistoryState => {
  switch (action.type) {
    case ChangesHistoryActionType.GET_CHANGES_HISTORY:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case ChangesHistoryActionType.GET_CHANGES_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload.data,
      };

    case ChangesHistoryActionType.GET_CHANGES_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default ChangesHistoryReducer;
