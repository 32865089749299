import { Roles } from "common/constants/Roles";
import { PageRoutesConstant } from "common/router/PageRoutes.constant";
import {
  ArrowCircleIcon,
  PadIcon,
  PatientIcon,
  StepBarIcon,
  UploadedReportsIcon,
} from "assets/icons";
import { ReportRoutesConstant } from "common/router/ReportRoutesConstant";
import { AppstoreOutlined, PercentageOutlined } from "@ant-design/icons";
import { AdminFlowRoutesConstant } from "common/router/AdminFlowRoutes.constant";
import { Permissions } from "common/constants/Permissions";

export const MainHeaderConstants = {
  MenuList: [
    {
      title: "Home",
      path: PageRoutesConstant.Page.Home.path,
      roles: [],
      permissions: [],
    },
    {
      title: "Reports",
      path: PageRoutesConstant.Page.Reports.path,
      roles: [Roles.Admin, Roles.COIPA_User, Roles.Clinic_User],
      permissions: [],
    },
    {
      title: "Admin",
      path: PageRoutesConstant.Page.Admin.path,
      roles: [Roles.Admin, Roles.COIPA_Admin, Roles.Clinic_Admin],
      permissions: [],
    },
  ],
  ReportMenuList: [
    {
      icon: PatientIcon,
      title: "Patients",
      path: ReportRoutesConstant.Report.Patients.path,
      viewBox: "0 0 24 24",
      roles: [],
      permissions: [],
    },
    {
      icon: PadIcon,
      title: "Claims",
      path: ReportRoutesConstant.Report.Claims.path,
      viewBox: "0 0 7 18",
      roles: [],
      permissions: [],
    },
    {
      icon: ArrowCircleIcon,
      title: "Specific Gap Analysis",
      viewBox: "0 0 19 19",
      disabled: true,
      roles: [],
      permissions: [],
    },
    {
      icon: StepBarIcon,
      title: "General Gap Analysis",
      path: ReportRoutesConstant.Report.Analysis.path,
      viewBox: "0 0 15 19",
      roles: [],
      permissions: [],
    },
  ],
  AdminMenuList: [
    {
      icon: AppstoreOutlined,
      title: "Dashboard",
      path: PageRoutesConstant.Page.Admin.path,
      viewBox: "0 0 24 24",
      roles: [Roles.Admin, Roles.COIPA_Admin, Roles.Clinic_Admin],
      permissions: [],
    },
    {
      icon: UploadedReportsIcon,
      title: "Uploaded Reports",
      path: AdminFlowRoutesConstant.Admin.UploadedReports.path,
      viewBox: "0 0 24 24",
      roles: [Roles.Admin],
      permissions: [Permissions.UploadedReports],
    },
    {
      icon: PercentageOutlined,
      title: "Targets",
      path: AdminFlowRoutesConstant.Admin.Targets.path,
      viewBox: "0 0 24 24",
      roles: [Roles.Admin],
      permissions: [Permissions.ViewTargets],
    },
  ],
};
