import { httpWithTokenInHeader } from "clients/api.clients.base";
import {
  ClaimClient,
  ClaimListRequestDto,
} from "clients/api.generated.clients";
import { Dispatch } from "react";
import { ClaimActionType } from "store/claim/actionTypes";
import { Action } from "store/claim/actions";

const claimClient = new ClaimClient("", httpWithTokenInHeader);

export const getMostRecentClaim =
  (value: string, cb?: (res) => void) => async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ClaimActionType.GET_MOST_RECENT_CLAIM,
    });
    await claimClient
      .getMostRecentClaim(value)
      .then((response) => {
        if (response.success) {
          dispatch({
            type: ClaimActionType.GET_MOST_RECENT_CLAIM_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: ClaimActionType.GET_MOST_RECENT_CLAIM_FAILURE,
            payload: response?.message,
          });
        }
        cb && cb(response);
      })
      .catch((err) => {
        dispatch({
          type: ClaimActionType.GET_MOST_RECENT_CLAIM_FAILURE,
          payload: err.message,
        });
        cb && cb(err);
      });
  };

export const getClaimsWithin3Years =
  (value: string, cb?: (res) => void) => async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ClaimActionType.GET_CLAIMS_WITHIN_3_YEARS,
    });
    await claimClient
      .getClaimsWithin3Years(value)
      .then((response) => {
        if (response.success) {
          dispatch({
            type: ClaimActionType.GET_CLAIMS_WITHIN_3_YEARS_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: ClaimActionType.GET_CLAIMS_WITHIN_3_YEARS_FAILURE,
            payload: response?.message,
          });
        }
        cb && cb(response);
      })
      .catch((err) => {
        dispatch({
          type: ClaimActionType.GET_CLAIMS_WITHIN_3_YEARS_FAILURE,
          payload: err.message,
        });
        cb && cb(err);
      });
  };

export const getAllClaimsWithin3Years =
  (values: ClaimListRequestDto, cb?: (res) => void) =>
  async (dispatch: Dispatch<Action>) => {
    const claimListRequest = {
      ...values,
    };

    dispatch({
      type: ClaimActionType.GET_ALL_CLAIMS_WITHIN_3_YEARS,
    });
    await claimClient
      .getAllClaimsWithin3Years(claimListRequest)
      .then((response) => {
        if (response.success) {
          dispatch({
            type: ClaimActionType.GET_ALL_CLAIMS_WITHIN_3_YEARS_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: ClaimActionType.GET_ALL_CLAIMS_WITHIN_3_YEARS_FAILURE,
            payload: response?.message,
          });
        }
        cb && cb(response);
      })
      .catch((err) => {
        dispatch({
          type: ClaimActionType.GET_ALL_CLAIMS_WITHIN_3_YEARS_FAILURE,
          payload: err.message,
        });
        cb && cb(err);
      });
  };

export const exportClaimData =
  (values: ClaimListRequestDto, cb?: (res) => void) =>
  async (dispatch: Dispatch<Action>) => {
    const claimListRequest = {
      ...values,
    };

    dispatch({
      type: ClaimActionType.EXPORT_DATA_EXCEL,
    });

    await claimClient
      .exportData(claimListRequest)
      .then((response) => {
        if (response.success) {
          dispatch({
            type: ClaimActionType.EXPORT_DATA_EXCEL_SUCCESS,
            payload: response.success,
          });
        } else {
          dispatch({
            type: ClaimActionType.EXPORT_DATA_EXCEL_FAILURE,
            payload: response?.message,
          });
        }
        cb && cb(response);
      })
      .catch((err) => {
        dispatch({
          type: ClaimActionType.EXPORT_DATA_EXCEL_FAILURE,
          payload: err.message,
        });
        cb && cb(err);
      });
  };

export const getClaimTypes = () => (dispatch: Dispatch<Action>) => {
  dispatch({
    type: ClaimActionType.GET_CLAIM_TYPES,
  });
  claimClient
    .getClaimTypes()
    .then((response) => {
      if (response.success) {
        dispatch({
          type: ClaimActionType.GET_CLAIM_TYPES_SUCCESS,
          payload: response,
        });
      } else {
        dispatch({
          type: ClaimActionType.GET_CLAIM_TYPES_FAILURE,
          payload: response?.message,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: ClaimActionType.GET_CLAIM_TYPES_FAILURE,
        payload: err.message,
      });
    });
};
