/**
 *
 * @param obj
 * @param keys
 * @returns A new object that only includes the specified keys from obj
 */
export const selectKeys = <T extends Record<string, string>, K extends keyof T>(
  obj: T,
  ...keys: K[]
) => {
  return keys.reduce((res, cur) => {
    res[cur] = obj[cur];
    return res;
  }, {} as Pick<T, K>);
};
