import React, { FC, useEffect, useMemo, useState } from "react";
import { Col, Row, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useActions } from "hooks/useActions";
import { useDebounced } from "hooks/useDebounced";
import { useTypedSelector } from "hooks/useTypedSelector";
import { PatientKeyDto } from "clients/api.generated.clients";
import "./PatientSearch.style.scss";

interface PatientSearchProps {
  selectedResult?: PatientKeyDto;
  updateSelectedResult: (selectedResult?: PatientKeyDto) => void;
}

const invalidCharacterRegex = /[!@#$%^&*(){}?/|]/;
const alphanumericWhitespaceRegex = /[a-zA-Z0-9\s]/g;

const patientKeyToString = (item: PatientKeyDto | undefined) => {
  if (!item) return undefined;
  const fullName =
    item.fullName ||
    [item.firstName, item.middleName, item.lastName].filter(Boolean).join(" ");
  return `${fullName} (${item.memberId})`;
};

const PatientSearch: FC<PatientSearchProps> = ({
  selectedResult,
  updateSelectedResult,
}) => {
  const { searchPatients } = useActions();
  const searchPatientsDebounced = useDebounced(200, searchPatients);
  const { isLoading: isSearchLoading, result: searchResults } =
    useTypedSelector((state) => state.patients.search);

  const [searchValue, setSearchValue] = useState<string>();

  const handleSearch = (newValue: string) => {
    const cleaned = newValue.trim();
    setSearchValue(cleaned);
    if (cleaned) {
      searchPatientsDebounced(cleaned);
    }
  };

  const handleClear = () => {
    updateSelectedResult(undefined);
    setSearchValue(undefined);
  };

  const handleChange = (newValue: string) => {
    const memberId = newValue;

    const searchInput = document.getElementById("patient_search");
    if (!newValue && searchInput) {
      searchInput.blur();
    }

    const selectedResult = searchResults?.data?.find(
      (r) => r.memberId == memberId
    );
    updateSelectedResult(selectedResult);
  };

  const renderOptions = !searchValue
    ? null
    : searchResults?.data.map((item) => (
        <Select.Option key={item.memberId} value={item.memberId}>
          <div
            className="text-truncate"
            style={{
              color: "#818181",
              fontWeight: 400,
            }}
          >
            <BoldSearchMatch
              searchResult={patientKeyToString(item)}
              searchValue={searchValue}
            />
          </div>
        </Select.Option>
      ));

  const handleRemoveFirstOption = () => {
    const selectElement = document.querySelector(".desktop") as HTMLElement;
    if (selectElement) {
      const firstOptionElement = selectElement.querySelector(
        ".ant-select-item-option"
      ) as HTMLOptionElement;
      if (
        firstOptionElement &&
        (firstOptionElement.value === "" ||
          firstOptionElement.value === undefined)
      ) {
        firstOptionElement.style.display = "none";
      }
    }
  };

  const notFoundContent = useMemo(() => {
    if (searchValue && !searchResults?.data.length) {
      if (invalidCharacterRegex.test(searchValue)) {
        return `${searchValue.replace(
          alphanumericWhitespaceRegex,
          ""
        )} is not a valid character`;
      } else if (renderOptions?.length === 0) {
        return "No Results Found";
      } else {
        return "Loading...";
      }
    }
    return undefined;
  }, [searchValue, searchResults]);

  useEffect(() => {
    if (searchValue?.trim() === "") {
      setSearchValue(undefined);
    }
  }, [searchValue]);

  useEffect(() => {
    handleRemoveFirstOption();
  }, [searchValue === undefined]);

  return (
    <div>
      <Row>
        <Col span={24}>
          <Select
            id="patient_search"
            className="search"
            popupClassName="search_item desktop"
            showSearch
            size="large"
            suffixIcon={<SearchOutlined />}
            value={patientKeyToString(selectedResult)}
            allowClear={true}
            placeholder="Enter Name or Member ID"
            defaultActiveFirstOption={false}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            onFocus={handleRemoveFirstOption}
            onClear={handleClear}
            optionFilterProp="children"
            notFoundContent={notFoundContent}
            loading={isSearchLoading}
            dropdownRender={(menu) => (
              <div>
                {menu}
                {((!searchValue && !searchResults?.data.length) ||
                  searchResults?.data.length > 5) && (
                  <div className="narrow_down">
                    Enter more characters to narrow down results.
                  </div>
                )}
              </div>
            )}
          >
            {searchValue && searchValue.trim() !== ""
              ? renderOptions
              : (searchValue === "" ||
                  searchValue === null ||
                  searchValue === undefined) && (
                  <Select.Option value="">{null}</Select.Option>
                )}
          </Select>
        </Col>
      </Row>
    </div>
  );
};

type BoldSearchMatchProps = {
  searchResult: string;
  searchValue: string;
};
const BoldSearchMatch: FC<BoldSearchMatchProps> = ({
  searchResult,
  searchValue,
}) => {
  if (!searchValue || searchValue.trim() === "") return <>{searchResult}</>;

  const parts = useMemo(() => {
    const searchRegExp = new RegExp(`^(.*)(${searchValue})(.*)$`, "i");
    const matchResult = searchRegExp.exec(searchResult);
    if (!matchResult || matchResult.length < 4) return null;
    const [prefix, searchMatch, suffix] = matchResult.slice(1);
    return { prefix, searchMatch, suffix };
  }, [searchResult, searchValue]);

  if (!parts) return <>{searchResult}</>;

  const { prefix, searchMatch, suffix } = parts;

  return (
    <>
      {prefix}
      <span style={{ fontWeight: "bold" }}>{searchMatch}</span>
      {suffix}
    </>
  );
};

export default PatientSearch;
