import { Content } from "common/interfaces/Content";
import { useContext } from "react";
import { AppContext } from "../context/AppProvider";

const UseContent = (): Content => {
  const { content } = useContext(AppContext);
  const { value, copyright, address } = content;

  if (!content) throw new Error("Please add App provider");

  return {
    value,
    copyright,
    address,
  };
};

export default UseContent;
