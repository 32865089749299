import {
  PaginationResponseDtoOfPermissionDto,
} from "clients/api.generated.clients";
import { Action } from "./actions";
import { PermissionActionType } from "./actionTypes";

export interface PermissionsState {
  loading: boolean;
  error: string | undefined;
  data: PaginationResponseDtoOfPermissionDto | undefined;
}

const initialState = {
  loading: false,
  error: undefined,
  data: undefined,
};

const PermissionReducer = (
  state: PermissionsState = initialState,
  action: Action
): PermissionsState => {
  switch (action.type) {
    case PermissionActionType.GET_PERMISSIONS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case PermissionActionType.GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload.data,
      };

    case PermissionActionType.GET_PERMISSIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default PermissionReducer;
