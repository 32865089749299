import React, { FC } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { AdminDashboardLayout } from "common/components/admindashboardlayout/AdminDashboardLayout";
import { AdminFlowRoutesConstant } from "../AdminFlowRoutes.constant";
import { PageRoutesConstant } from "../PageRoutes.constant";
import { Layout } from "antd";

const AdminRoutes = () => {
  return (
    <Routes>
      {Object.keys(AdminFlowRoutesConstant.Admin).map((key) => {
        const route = AdminFlowRoutesConstant.Admin[key];
        return (
          <Route
            key={key}
            path={route.path.replace(PageRoutesConstant.Page.Admin.path, "")}
            Component={route.component}
          />
        );
      })}
    </Routes>
  );
};

const AdminFlowRoutes: FC = () => {
  const location = useLocation();

  const shouldRenderAdminDashboardLayout = [
    AdminFlowRoutesConstant.Admin.UploadedReports.path,
    AdminFlowRoutesConstant.Admin.Targets.path,
  ].includes(location.pathname);

  return shouldRenderAdminDashboardLayout ? (
    <Layout>
      <AdminRoutes />
    </Layout>
  ) : (
    <AdminDashboardLayout>
      <AdminRoutes />
    </AdminDashboardLayout>
  );
};

export default AdminFlowRoutes;
