export type Loader<T> = {
  isLoading?: boolean;
  error?: unknown;
  value?: T;
};

export async function getLoaderValue<T>(
  setLoader: React.Dispatch<React.SetStateAction<Loader<T>>>,
  getValue: () => Promise<T>
) {
  try {
    setLoader({
      isLoading: true,
    });
    const value = await getValue();
    setLoader({
      value,
      isLoading: false,
    });
  } catch (error) {
    setLoader({
      error,
      isLoading: false,
    });
  }
}
