import React from "react";
import Icon, {
  CustomIconComponentProps,
} from "@ant-design/icons/lib/components/Icon";

const WriteSvg = () => (
  <svg
    id="Layer_1"
    enableBackground="new 0 0 64 64"
    height="40"
    viewBox="0 0 64 64"
    width="40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m46 58c-1.104 0-2-.896-2-2s.896-2 2-2h.002c0-1.104.894-2 1.999-2s1.999.896 1.999 2c0 2.206-1.794 4-4 4z" />
    </g>
    <g>
      <path d="m48 56c-1.104 0-2-.896-2-2v-19.615c0-1.104.896-2 2-2s2 .896 2 2v19.615c0 1.104-.896 2-2 2z" />
    </g>
    <g>
      <path d="m46 58h-36c-1.104 0-2-.896-2-2s.896-2 2-2h36c1.104 0 2 .896 2 2s-.896 2-2 2z" />
    </g>
    <g>
      <path d="m10 58c-2.206 0-4-1.794-4-4 0-1.104.896-2 2-2s2 .896 2 2v.003c1.104 0 2 .894 2 1.998s-.896 1.999-2 1.999z" />
    </g>
    <g>
      <path d="m8 56c-1.104 0-2-.896-2-2v-36c0-1.104.896-2 2-2s2 .896 2 2v36c0 1.104-.896 2-2 2z" />
    </g>
    <g>
      <path d="m7.999 20c-1.105 0-1.999-.896-1.999-2 0-2.206 1.794-4 4-4 1.104 0 2 .896 2 2s-.896 2-2 2h-.002c0 1.104-.894 2-1.999 2z" />
    </g>
    <g>
      <path d="m29.615 18h-19.615c-1.104 0-2-.896-2-2s.896-2 2-2h19.615c1.104 0 2 .896 2 2s-.895 2-2 2z" />
    </g>
    <g>
      <path d="m27.931 30.998c-.512 0-1.024-.195-1.414-.586-.781-.781-.781-2.047 0-2.828l19.533-19.533c.78-.781 2.048-.781 2.828 0 .781.781.781 2.047 0 2.828l-19.533 19.533c-.39.391-.903.586-1.414.586z" />
    </g>
    <g>
      <path d="m26.002 40c-.139 0-.28-.014-.421-.045-1.08-.231-1.768-1.295-1.536-2.374l1.931-9.002c.231-1.081 1.297-1.772 2.375-1.536 1.08.231 1.768 1.295 1.536 2.374l-1.931 9.002c-.202.939-1.031 1.581-1.954 1.581z" />
    </g>
    <g>
      <path d="m35.002 38.068c-.512 0-1.024-.195-1.414-.586-.781-.781-.781-2.047 0-2.828l19.533-19.533c.78-.781 2.048-.781 2.828 0 .781.781.781 2.047 0 2.828l-19.533 19.533c-.39.391-.902.586-1.414.586z" />
    </g>
    <g>
      <path d="m25.998 40c-.923 0-1.752-.642-1.954-1.58-.231-1.08.456-2.144 1.536-2.375l9.002-1.932c1.077-.229 2.143.455 2.375 1.535.231 1.08-.456 2.144-1.536 2.375l-9.002 1.932c-.141.03-.282.045-.421.045z" />
    </g>
    <g>
      <path d="m51 22.071c-.512 0-1.024-.195-1.414-.586l-7.071-7.071c-.781-.781-.781-2.047 0-2.828.78-.781 2.048-.781 2.828 0l7.071 7.071c.781.781.781 2.047 0 2.828-.39.391-.902.586-1.414.586z" />
    </g>
    <g>
      <path d="m54.535 18.535c-.512 0-1.024-.195-1.415-.586-.781-.781-.781-2.048 0-2.829.554-.553.859-1.307.859-2.12s-.305-1.567-.859-2.12c-1.11-1.111-3.135-1.11-4.243-.001-.781.781-2.047.781-2.829.001-.781-.781-.781-2.048 0-2.829 1.31-1.311 3.068-2.032 4.95-2.032s3.641.722 4.95 2.032c1.309 1.309 2.03 3.066 2.03 4.949s-.721 3.641-2.031 4.95c-.388.39-.9.585-1.412.585z" />
    </g>
  </svg>
);

export const WriteIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={WriteSvg} {...props} />
);
