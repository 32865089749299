import React, { FC, useEffect, useState } from "react";
import { Col, Form, Row, Select, Spin, Typography, message } from "antd";
import CustomButton from "common/components/button/CustomButton";
import { useActions } from "hooks/useActions";
import { useTypedSelector } from "hooks/useTypedSelector";
import { PermissionDto, RoleDto, UserDto } from "clients/api.generated.clients";
import { UpdateUserRoleConstant } from "./UpdateUserRole.constant";
import { LoadingOutlined } from "@ant-design/icons";
import { validateRequired } from "hooks/validator";
import { useNavigate } from "react-router-dom";
import { Messages } from "common/constants/Messages";
import { PageRoutesConstant } from "common/router/PageRoutes.constant";
import "../update-user-role/UpdateUserRole.style.scss";

const { Title, Paragraph } = Typography;
const { Option } = Select;

interface UpdateUserRoleProps {
  userIds: string[];
  onPopupClose: () => void;
}

const UpdateUserRole: FC<UpdateUserRoleProps> = ({ userIds, onPopupClose }) => {
  const navigate = useNavigate();
  const { getRoles, getPermissions, updateUserRole } = useActions();
  const { users, roles, permissions } = useTypedSelector((state) => state);
  const [form] = Form.useForm();
  const [userNameList, setUserNameList] = useState<string[]>([]);
  const [roleNameList, setRoleNameList] = useState([]);
  const [permissionNameList, setPermissionNameList] = useState<string[]>([]);
  const [newPermissionNameList, setNewPermissionNameList] = useState<string[]>(
    []
  );
  const [roleList, setRoleList] = useState<RoleDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getRoles({});
    getPermissions({});
  }, []);

  useEffect(() => {
    const userList = Object.values(users?.data?.items ?? [])?.filter((u) =>
      userIds.includes(u.id)
    ) as UserDto[];
    setUserNameList(userList.map((user) => user.userName));
    setRoleNameList(
      [].concat(
        ...userList
          .filter((user) => user.roles.length > 0)
          .map((user) => user.roles)
      )
    );
    const permissions = [].concat(
      ...userList
        .filter((user) => user.permissions.length > 0)
        .map((user) => user.permissions)
    );
    setPermissionNameList(permissions);
    setNewPermissionNameList(permissions);
  }, [users]);

  useEffect(() => {
    setRoleList(
      Object.values(roles?.data?.items ?? [])?.map(
        (role: RoleDto) => role
      ) as RoleDto[]
    );
  }, [roles]);

  const handleRoleChange = (value: string) => {
    const permissionIds = roleList.find((r) => r.id === value).permissions;
    const validPermissionNames = (permissions?.data?.items ?? [])
      .filter((p: PermissionDto) => permissionIds.includes(p.id))
      .map((p) => p.name);
    setNewPermissionNameList([...permissionNameList, ...validPermissionNames]);
  };

  const onUpdateUserRole = () => {
    setIsLoading(true);
    form
      .validateFields()
      .then((values) => {
        updateUserRole({ userIds: userIds, ...values }, (res) => {
          if (res.success) {
            form.resetFields();
            onPopupClose();
            message.success(UpdateUserRoleConstant.SuccessMessageText, 5);
          } else {
            if (res.message === Messages.AccessDenied) {
              navigate(PageRoutesConstant.Page.AccessDenied.path, {
                replace: true,
              });
            }
            message.error(res.message, 5);
          }
          setIsLoading(false);
        });
      })
      .catch((info) => {
        setIsLoading(false);
        console.log("Validate Failed:", info);
      });
  };

  return (
    <div className="update_userrole">
      <Form form={form} onFinish={onUpdateUserRole}>
        <Title className="update_userrole_title" level={3}>
          {userNameList.length > 1
            ? UpdateUserRoleConstant.BatchPageTitle
            : UpdateUserRoleConstant.PageTitle}
        </Title>
        <Row gutter={[0, { xs: 12, sm: 24 }]} justify="end">
          <Col xs={24}>
            <Title level={5} className="update_userrole_title_text mb-0">
              {UpdateUserRoleConstant.UsersTitle}
            </Title>
            <Spin
              size="small"
              tip="Loading..."
              spinning={users.loading}
              indicator={<LoadingOutlined />}
            >
              {userNameList.map((name, index) => (
                <Paragraph
                  key={index}
                  className="update_userrole_description_text mb-0"
                >
                  {name}
                </Paragraph>
              ))}
            </Spin>
          </Col>
          <Col xs={24} sm={12}>
            <Title level={5} className="update_userrole_title_text mb-0">
              {UpdateUserRoleConstant.RolesTitle}
            </Title>
            <Spin
              size="small"
              tip="Loading..."
              spinning={users.loading}
              indicator={<LoadingOutlined />}
            >
              <Paragraph className="update_userrole_description_text mb-0">
                {[...new Set(roleNameList)].join(", ")}
              </Paragraph>
            </Spin>
          </Col>
          <Col className="update_userrole_select_wrap" xs={24} sm={12}>
            <Form.Item
              name="roleId"
              label=""
              className="mt-1 mb-0"
              rules={[{ required: true, validator: validateRequired }]}
            >
              <Select
                loading={roles.loading}
                placeholder="Select New Role"
                className="update_userrole_select"
                onChange={handleRoleChange}
                size="large"
              >
                {roleList.map((role) => (
                  <Option key={role.id} value={role.id}>
                    {role.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Title level={5} className="update_userrole_title_text mb-0">
              {UpdateUserRoleConstant.PermissionTitle}
            </Title>
            <Spin
              size="small"
              tip="Loading..."
              spinning={users.loading}
              indicator={<LoadingOutlined />}
            >
              <Paragraph className="update_userrole_description_text mb-0">
                {[...new Set(newPermissionNameList)].join(", ")}
              </Paragraph>
            </Spin>
          </Col>
          <Col className="update_userrole_save_changes text-end" span={24}>
            <CustomButton
              backgroundColor="#B8C7B9"
              font={16}
              text={UpdateUserRoleConstant.SubmitButtonText}
              radius={8}
              width={148}
              height={40}
              onClick={onUpdateUserRole}
              buttonType="submit"
              disabled={isLoading}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default UpdateUserRole;
