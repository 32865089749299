import { Col, Row, Typography, Button, Tooltip } from "antd";
import React, { FC } from "react";
import { HomeConstants } from "./Home.constant";
import { HomeDoctor, Heart } from "assets/images";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useMsal } from "@azure/msal-react";
import { Link } from "react-router-dom";
import { hasAccessByRoles } from "hooks/useAccess";
import "./Home.style.scss";

const { Paragraph, Title } = Typography;

const Home: FC = () => {
  const { accounts } = useMsal();
  const name = accounts?.[0]?.idTokenClaims?.name;

  return (
    <div className="home_content">
      <Row
        gutter={[
          { xs: 0, sm: 24 },
          { xs: 20, sm: 24 },
        ]}
      >
        <Col
          xs={{ span: 24, order: 2 }}
          md={{ span: 12, order: 1 }}
          xl={{ span: 8, order: 1 }}
          xxl={{ span: 6, order: 1 }}
        >
          <Row className="home_content_left_panel">
            <Col xs={24}>
              <img
                className="home_content_homedoctor_image"
                src={HomeDoctor}
                loading="lazy"
                alt="Home_Doctor_Img"
              />
            </Col>
            <Col className="home_content_patient_care">
              <Row justify="center" align="middle" gutter={[0, 24]}>
                <Col xs={24}>
                  <Paragraph className="home_content_patient_care_title_text mb-0">
                    {HomeConstants.PatientCareTitle}
                  </Paragraph>
                </Col>
                <Col xs={24}>
                  <img
                    className="home_content_patient_care_heart_image"
                    src={Heart}
                    loading="lazy"
                    alt=""
                  />
                </Col>
                <Col xs={24}>
                  <Paragraph className="home_content_patient_care_description_text mb-0">
                    {HomeConstants.PatientCareDescription}
                  </Paragraph>
                </Col>
                <Col xs={24}>
                  <Tooltip
                    title="Coming Soon..."
                    color="#50834B"
                    autoAdjustOverflow
                  >
                    <Button
                      className="home_content_patient_care_button"
                      disabled
                    >
                      {HomeConstants.PatientCareButtonText}
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col
          xs={{ span: 24, order: 1 }}
          md={{ span: 12, order: 2 }}
          xl={{ span: 16, order: 2 }}
          xxl={{ span: 18, order: 2 }}
        >
          <div className="home_tiles">
            <Row
              gutter={[
                { xs: 6, md: 24 },
                { xs: 6, md: 24 },
              ]}
            >
              <Col xs={24}>
                <Title level={2} className="home_welcome_text mb-0">
                  {HomeConstants.WelcomeText} {name}
                </Title>
              </Col>
            </Row>
            <Row
              gutter={[
                { xs: 6, md: 24 },
                { xs: 6, md: 24 },
              ]}
            >
              {HomeConstants.Tiles.map(
                (item, index) =>
                  hasAccessByRoles(item.roles) && (
                    <Col key={index} xs={24} xl={12} xxl={8}>
                      <Tooltip
                        title={item.Tooltip}
                        color="#50834B"
                        autoAdjustOverflow
                      >
                        <Link
                          to={item.Path === "PatientSearch" ? "" : item.Path}
                          className={`text-decoration-none ${item.Path === "PatientSearch"
                              ? "tile_patient_search"
                              : item.Path
                            } ${item.Disabled ? "disabled" : ""}`}
                        >
                          <div className="home_cards">
                            <Title level={4} className="home_cards_card_title">
                              {item.Title}
                            </Title>
                            <Paragraph className="home_cards_card-description mb-0">
                              {item.Description}
                            </Paragraph>
                            <div className="home_cards_card_footer">
                              <item.Icon className="home_cards_card_footer_image" />
                              <Button
                                type="link"
                                className="home_cards_card_footer_action"
                              >
                                Get Started
                                <ArrowRightOutlined className="home_cards_card_footer_action_icon" />
                              </Button>
                            </div>
                          </div>
                        </Link>
                      </Tooltip>
                    </Col>
                  )
              )}
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
