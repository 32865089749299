import { PaginationResponseDtoOfAnalysisViewDto } from "clients/api.generated.clients";
import { Action } from "./actions";
import { AnalysisActionType } from "./actionTypes";

export interface AnalysisState {
  loading: boolean;
  error: string | undefined;
  data: PaginationResponseDtoOfAnalysisViewDto | undefined;
  payorExportLoading: boolean;
  lineOfBusinessExportLoading: boolean;
  clinicExportLoading: boolean;
  providerExportLoading: boolean;
}

const initialState: AnalysisState = {
  loading: false,
  error: undefined,
  data: undefined,
  payorExportLoading: false,
  lineOfBusinessExportLoading: false,
  clinicExportLoading: false,
  providerExportLoading: false,
};

const AnalysisReducer = (
  state: AnalysisState = initialState,
  action: Action
): AnalysisState => {
  switch (action.type) {
    case AnalysisActionType.GET_PAYOR_ANALYSIS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case AnalysisActionType.GET_PAYOR_ANALYSIS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload.data,
      };

    case AnalysisActionType.GET_PAYOR_ANALYSIS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: undefined,
      };

    case AnalysisActionType.GET_LINEOFBUSINESS_ANALYSIS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case AnalysisActionType.GET_LINEOFBUSINESS_ANALYSIS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload.data,
      };

    case AnalysisActionType.GET_LINEOFBUSINESS_ANALYSIS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: undefined,
      };

    case AnalysisActionType.GET_CLINIC_ANALYSIS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case AnalysisActionType.GET_CLINIC_ANALYSIS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload.data,
      };

    case AnalysisActionType.GET_CLINIC_ANALYSIS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: undefined,
      };

    case AnalysisActionType.GET_PROVIDERS_ANALYSIS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case AnalysisActionType.GET_PROVIDERS_ANALYSIS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload.data,
      };

    case AnalysisActionType.GET_PROVIDERS_ANALYSIS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: undefined,
      };

    case AnalysisActionType.EXPORT_EXCEL_PAYOR_ANALYSIS:
      return {
        ...state,
        payorExportLoading: true,
        error: undefined,
      };

    case AnalysisActionType.EXPORT_EXCEL_PAYOR_ANALYSIS_SUCCESS:
      return {
        ...state,
        payorExportLoading: false,
        error: undefined,
      };

    case AnalysisActionType.EXPORT_EXCEL_PAYOR_ANALYSIS_FAILURE:
      return {
        ...state,
        payorExportLoading: false,
        error: action.payload,
        data: undefined,
      };

    case AnalysisActionType.EXPORT_EXCEL_LINEOFBUSINESS_ANALYSIS:
      return {
        ...state,
        lineOfBusinessExportLoading: true,
        error: undefined,
      };

    case AnalysisActionType.EXPORT_EXCEL_LINEOFBUSINESS_ANALYSIS_SUCCESS:
      return {
        ...state,
        lineOfBusinessExportLoading: false,
        error: undefined,
      };

    case AnalysisActionType.EXPORT_EXCEL_LINEOFBUSINESS_ANALYSIS_FAILURE:
      return {
        ...state,
        lineOfBusinessExportLoading: false,
        error: action.payload,
        data: undefined,
      };

    case AnalysisActionType.EXPORT_EXCEL_PROVIDERS_ANALYSIS:
      return {
        ...state,
        providerExportLoading: true,
        error: undefined,
      };

    case AnalysisActionType.EXPORT_EXCEL_PROVIDERS_ANALYSIS_SUCCESS:
      return {
        ...state,
        providerExportLoading: false,
        error: undefined,
      };

    case AnalysisActionType.EXPORT_EXCEL_PROVIDERS_ANALYSIS_FAILURE:
      return {
        ...state,
        providerExportLoading: false,
        error: action.payload,
        data: undefined,
      };

    case AnalysisActionType.EXPORT_EXCEL_CLINIC_ANALYSIS:
      return {
        ...state,
        clinicExportLoading: true,
        error: undefined,
      };

    case AnalysisActionType.EXPORT_EXCEL_CLINIC_ANALYSIS_SUCCESS:
      return {
        ...state,
        clinicExportLoading: false,
        error: undefined,
      };

    case AnalysisActionType.EXPORT_EXCEL_CLINIC_ANALYSIS_FAILURE:
      return {
        ...state,
        clinicExportLoading: false,
        error: action.payload,
        data: undefined,
      };

    default:
      return state;
  }
};

export default AnalysisReducer;
