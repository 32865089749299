import { Permissions } from "common/constants/Permissions";
import { AdminFlowRoutesConstant } from "common/router/AdminFlowRoutes.constant";

export const AdminDashboardConstants = {
  Title: "Admin Dashboard",
  MenuList: [
    {
      title: "User List",
      path: AdminFlowRoutesConstant.Admin.UserList.path,
      permissions: [Permissions.UserList],
    },
    {
      title: "Add User",
      path: AdminFlowRoutesConstant.Admin.AddUser.path,
      permissions: [Permissions.AddUsers],
    },
    {
      title: "Create Role",
      path: AdminFlowRoutesConstant.Admin.CreateRole.path,
      permissions: [Permissions.CreateRoles],
    },
    {
      title: "Adjust Role Permissions",
      path: AdminFlowRoutesConstant.Admin.AdjustRolePermission.path,
      permissions: [Permissions.AdjustRolePermissions],
    },
    {
      title: "View History",
      path: AdminFlowRoutesConstant.Admin.AdminChangesHistory.path,
      permissions: [Permissions.ViewHistory],
    }
  ],
};
