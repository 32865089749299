import { combineReducers } from "redux";
import PermissionReducer from "./permission/reducer";
import RoleReducer from "./role/reducer";
import UserReducer from "./user/reducer";
import PatientReducer from "./patient/reducer";
import ChangesHistoryReducer from "./changeshistory/reducer";
import ClaimReducer from "./claim/reducer";
import PayorReducer from "./payor/reducer";
import LineOfBusinessReducer from "./lineofbusiness/reducer";
import BlobStorageReducer from "./blobstorage/reducer";
import ProviderGroupReducer from "./providergroup/reducer";
import ProviderReducer from "./provider/reducer";
import AnalysisReducer from "./analysis/reducer";
import PayorTargetsReducer from "./targets/payorReducer";
import ClinicTargetsReducer from "./targets/clinicReducer";
import lineofBusinessTargetsReducer from "./targets/lobReducer";

const reducers = combineReducers({
  permissions: PermissionReducer,
  roles: RoleReducer,
  users: UserReducer,
  patients: PatientReducer,
  changesHistory: ChangesHistoryReducer,
  claims: ClaimReducer,
  payors: PayorReducer,
  lineOfBusiness: LineOfBusinessReducer,
  blobStorage: BlobStorageReducer,
  providerGroup: ProviderGroupReducer,
  provider: ProviderReducer,
  analysis: AnalysisReducer,
  payorTargets: PayorTargetsReducer,
  linofBusinessTargets: lineofBusinessTargetsReducer,
  clinicTargets: ClinicTargetsReducer,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
