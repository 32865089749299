import { httpWithTokenInHeader } from "clients/api.clients.base";
import {
  AnalysisClient,
  AnalysisRequestDto,
} from "clients/api.generated.clients";
import { Dispatch } from "redux";
import { AnalysisActionType } from "store/analysis/actionTypes";
import { Action } from "store/analysis/actions";

const analysisClient = new AnalysisClient("", httpWithTokenInHeader);

export const getPayorsAnalysisReports =
  (values: AnalysisRequestDto, cb?: (res) => void) =>
    async (dispatch: Dispatch<Action>) => {
      const analysisRequest = {
        ...values,
      };

      dispatch({
        type: AnalysisActionType.GET_PAYOR_ANALYSIS,
      });

      await analysisClient
        .getPayorsAnalysisReports(analysisRequest)
        .then((response) => {
          if (response.success) {
            dispatch({
              type: AnalysisActionType.GET_PAYOR_ANALYSIS_SUCCESS,
              payload: response,
            });
          } else {
            dispatch({
              type: AnalysisActionType.GET_PAYOR_ANALYSIS_FAILURE,
              payload: response?.message,
            });
          }
          cb && cb(response);
        })
        .catch((err) => {
          dispatch({
            type: AnalysisActionType.GET_PAYOR_ANALYSIS_FAILURE,
            payload: err.message,
          });
          cb && cb(err);
        });
    };

export const getLOBAnalysisReports =
  (values: AnalysisRequestDto, cb?: (res) => void) =>
    async (dispatch: Dispatch<Action>) => {
      const analysisRequest = {
        ...values,
      };

      dispatch({
        type: AnalysisActionType.GET_LINEOFBUSINESS_ANALYSIS,
      });

      await analysisClient
        .getLOBAnalysisReports(analysisRequest)
        .then((response) => {
          if (response.success) {
            dispatch({
              type: AnalysisActionType.GET_LINEOFBUSINESS_ANALYSIS_SUCCESS,
              payload: response,
            });
          } else {
            dispatch({
              type: AnalysisActionType.GET_LINEOFBUSINESS_ANALYSIS_FAILURE,
              payload: response?.message,
            });
          }
          cb && cb(response);
        })
        .catch((err) => {
          dispatch({
            type: AnalysisActionType.GET_LINEOFBUSINESS_ANALYSIS_FAILURE,
            payload: err.message,
          });
          cb && cb(err);
        });
    };

export const getClinicAnalysisReports =
  (values: AnalysisRequestDto, cb?: (res) => void) =>
    async (dispatch: Dispatch<Action>) => {
      const analysisRequest = {
        ...values,
      };

      dispatch({
        type: AnalysisActionType.GET_CLINIC_ANALYSIS,
      });

      await analysisClient
        .getClinicAnalysisReports(analysisRequest)
        .then((response) => {
          if (response.success) {
            dispatch({
              type: AnalysisActionType.GET_CLINIC_ANALYSIS_SUCCESS,
              payload: response,
            });
          } else {
            dispatch({
              type: AnalysisActionType.GET_CLINIC_ANALYSIS_FAILURE,
              payload: response?.message,
            });
          }
          cb && cb(response);
        })
        .catch((err) => {
          dispatch({
            type: AnalysisActionType.GET_CLINIC_ANALYSIS_FAILURE,
            payload: err.message,
          });
          cb && cb(err);
        });
    };

export const getProvidersAnalysisReports =
  (values: AnalysisRequestDto, cb?: (res) => void) =>
    async (dispatch: Dispatch<Action>) => {
      const analysisRequest = {
        ...values,
      };

      dispatch({
        type: AnalysisActionType.GET_PROVIDERS_ANALYSIS,
      });

      await analysisClient
        .getProvidersAnalysisReports(analysisRequest)
        .then((response) => {
          if (response.success) {
            dispatch({
              type: AnalysisActionType.GET_PROVIDERS_ANALYSIS_SUCCESS,
              payload: response,
            });
          } else {
            dispatch({
              type: AnalysisActionType.GET_PROVIDERS_ANALYSIS_FAILURE,
              payload: response?.message,
            });
          }
          cb && cb(response);
        })
        .catch((err) => {
          dispatch({
            type: AnalysisActionType.GET_PROVIDERS_ANALYSIS_FAILURE,
            payload: err.message,
          });
          cb && cb(err);
        });
    };

export const exportPayorAnalysisData =
  (values: AnalysisRequestDto, cb?: (res) => void) =>
    async (dispatch: Dispatch<Action>) => {
      const analysisRequest = {
        ...values,
      };

      dispatch({
        type: AnalysisActionType.EXPORT_EXCEL_PAYOR_ANALYSIS,
      });

      await analysisClient
        .exportPayorsAnalysisReports(analysisRequest)
        .then((response) => {
          if (response.success) {
            dispatch({
              type: AnalysisActionType.EXPORT_EXCEL_PAYOR_ANALYSIS_SUCCESS,
              payload: response.success,
            });
          } else {
            dispatch({
              type: AnalysisActionType.EXPORT_EXCEL_PAYOR_ANALYSIS_FAILURE,
              payload: response?.message,
            });
          }
          cb && cb(response);
        })
        .catch((err) => {
          dispatch({
            type: AnalysisActionType.EXPORT_EXCEL_PAYOR_ANALYSIS_FAILURE,
            payload: err.message,
          });
          cb && cb(err);
        });
    };

export const exportLOBAnlysisData =
  (values: AnalysisRequestDto, cb?: (res) => void) =>
    async (dispatch: Dispatch<Action>) => {
      const analysisRequest = {
        ...values,
      };

      dispatch({
        type: AnalysisActionType.EXPORT_EXCEL_LINEOFBUSINESS_ANALYSIS,
      });

      await analysisClient
        .exportLOBAnalysisReports(analysisRequest)
        .then((response) => {
          if (response.success) {
            dispatch({
              type: AnalysisActionType.EXPORT_EXCEL_LINEOFBUSINESS_ANALYSIS_SUCCESS,
              payload: response.success,
            });
          } else {
            dispatch({
              type: AnalysisActionType.EXPORT_EXCEL_LINEOFBUSINESS_ANALYSIS_FAILURE,
              payload: response?.message,
            });
          }
          cb && cb(response);
        })
        .catch((err) => {
          dispatch({
            type: AnalysisActionType.EXPORT_EXCEL_LINEOFBUSINESS_ANALYSIS_FAILURE,
            payload: err.message,
          });
          cb && cb(err);
        });
    };

export const exportProviderAnlysisData =
  (values: AnalysisRequestDto, cb?: (res) => void) =>
    async (dispatch: Dispatch<Action>) => {
      const analysisRequest = {
        ...values,
      };

      dispatch({
        type: AnalysisActionType.EXPORT_EXCEL_PROVIDERS_ANALYSIS,
      });

      await analysisClient
        .exportProvidersAnalysisReports(analysisRequest)
        .then((response) => {
          if (response.success) {
            dispatch({
              type: AnalysisActionType.EXPORT_EXCEL_PROVIDERS_ANALYSIS_SUCCESS,
              payload: response.success,
            });
          } else {
            dispatch({
              type: AnalysisActionType.EXPORT_EXCEL_PROVIDERS_ANALYSIS_FAILURE,
              payload: response?.message,
            });
          }
          cb && cb(response);
        })
        .catch((err) => {
          dispatch({
            type: AnalysisActionType.EXPORT_EXCEL_PROVIDERS_ANALYSIS_FAILURE,
            payload: err.message,
          });
          cb && cb(err);
        });
    };

export const exportClinicAnlysisData =
  (values: AnalysisRequestDto, cb?: (res) => void) =>
    async (dispatch: Dispatch<Action>) => {
      const analysisRequest = {
        ...values,
      };

      dispatch({
        type: AnalysisActionType.EXPORT_EXCEL_CLINIC_ANALYSIS,
      });

      await analysisClient
        .exportClinicsAnalysisReports(analysisRequest)
        .then((response) => {
          if (response.success) {
            dispatch({
              type: AnalysisActionType.EXPORT_EXCEL_CLINIC_ANALYSIS_SUCCESS,
              payload: response.success,
            });
          } else {
            dispatch({
              type: AnalysisActionType.EXPORT_EXCEL_CLINIC_ANALYSIS_FAILURE,
              payload: response?.message,
            });
          }
          cb && cb(response);
        })
        .catch((err) => {
          dispatch({
            type: AnalysisActionType.EXPORT_EXCEL_CLINIC_ANALYSIS_FAILURE,
            payload: err.message,
          });
          cb && cb(err);
        });
    };
