import React, { FC, useEffect, useState } from "react";
import {
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Typography,
  message,
} from "antd";
import CustomButton from "common/components/button/CustomButton";
import { validateEmail } from "hooks/validator";
import { useTypedSelector } from "hooks/useTypedSelector";
import { useActions } from "hooks/useActions";
import { LoadingOutlined } from "@ant-design/icons";
import { AddUserConstant } from "./AddUser.constatnt";
import { useNavigate } from "react-router-dom";
import { Messages } from "common/constants/Messages";
import { PageRoutesConstant } from "common/router/PageRoutes.constant";
import { Permissions } from "common/constants/Permissions";
import { hasAccessByPermissions } from "hooks/useAccess";
import "./AddUser.style.scss";

const { Title } = Typography;

const AddUser: FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { getRoles, addUser } = useActions();
  const { roles } = useTypedSelector((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const hasAccess = hasAccessByPermissions(Permissions.AddUsers);

  useEffect(() => {
    if (!hasAccess) {
      navigate(PageRoutesConstant.Page.AccessDenied.path, {
        replace: true,
      });
    }
    getRoles({});
  }, []);

  const onAddUser = () => {
    setIsLoading(true);
    form
      .validateFields()
      .then((values) => {
        addUser({ ...values }, (res) => {
          if (res.success) {
            form.resetFields();
            message.success(AddUserConstant.SucessMessage, 5);
          } else {
            if (res.message === Messages.AccessDenied) {
              navigate(PageRoutesConstant.Page.AccessDenied.path, {
                replace: true,
              });
            }
            message.error(res.message, 5);
          }
          setIsLoading(false);
        });
      })
      .catch((info) => {
        setIsLoading(false);
        console.log("Validate Failed:", info);
      });
  };
  return (
    <div className="add_user">
      <Form form={form} layout="vertical" onFinish={onAddUser}>
        <Row>
          <Col span={24}>
            <Title className="add_user_title" level={2}>
              {AddUserConstant.PageTitle}
            </Title>
          </Col>
          <Col xs={24} xl={14} xxl={16}>
            <Form.Item
              name="userName"
              label="User Name"
              rules={[
                {
                  required: true,
                  validator: validateEmail,
                  validateTrigger: "onBlur",
                },
              ]}
            >
              <Input type="text" autoComplete="off" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Title level={5} className="add_user_role_title">
              {AddUserConstant.RolesTitle}
            </Title>
          </Col>
          <Col span={24}>
            <Spin
              size="large"
              tip="Loading..."
              spinning={roles.loading}
              indicator={<LoadingOutlined />}
            >
              <Form.Item name="roles">
                <Checkbox.Group>
                  <Space direction="vertical">
                    {Object.values(roles?.data?.items ?? [])?.map((role) => (
                      <Checkbox
                        key={role.id}
                        value={role.id}
                        className="add_user_role_name"
                      >
                        {role.name}
                      </Checkbox>
                    ))}
                  </Space>
                </Checkbox.Group>
              </Form.Item>
            </Spin>
          </Col>
          <Col xs={24} className="add_user_wrap text-end">
            <CustomButton
              className="add_user_custombutton"
              backgroundColor="#B8C7B9"
              text={AddUserConstant.SubmitButtonText}
              fontColor="#000000"
              radius={8}
              width={128}
              font={16}
              height={40}
              buttonType="submit"
              onClick={onAddUser}
              disabled={isLoading}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddUser;
