import React, { FC, useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Form,
  FormInstance,
  MenuProps,
  Popover,
  Row,
  Select,
  Table,
  Tabs,
  Tag,
  Typography,
} from "antd";
import CustomButton from "common/components/button/CustomButton";
import { SliderIcon } from "assets/icons";
import {
  DATE_FORMAT_DEFAULT,
  FormatDataDate,
  FormatDate,
  ParseDayJsDate,
} from "common/utilities/format";
import {
  LeftOutlined,
  LoadingOutlined,
  MenuOutlined,
  RightOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { UploadedReportsConstant } from "./UploadedReports.constant";
import dayjs from "dayjs";
import { useActions } from "hooks/useActions";
import { useTypedSelector } from "hooks/useTypedSelector";
import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";
import JSZip from "jszip";
import { ColumnType } from "antd/es/table";
import { Loader, getLoaderValue } from "common/utilities/loader";
import useResponsive from "hooks/useResponsive";
import { BlobStorageState } from "store/blobstorage/reducer";
import { Messages } from "common/constants/Messages";
import { PageRoutesConstant } from "common/router/PageRoutes.constant";
import { useNavigate } from "react-router-dom";
import { SorterResult, TablePaginationConfig } from "antd/es/table/interface";
import { Permissions } from "common/constants/Permissions";
import "./UploadedReports.style.scss";

const { Title, Text } = Typography;

type FilterFormValues = {
  uploadStartDate?: dayjs.Dayjs;
  uploadEndDate?: dayjs.Dayjs;
};

type BlobResult = {
  key: string;
  name: string;
  uploadDate?: Date;
};

const EmptyFormFields: FilterFormValues = {
  uploadStartDate: undefined,
  uploadEndDate: undefined,
};

const defaultLastTab = "Processing";

const UploadedReports: FC = () => {
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  const [filterForm] = Form.useForm<FilterFormValues>();

  const { getSignedBlobServiceUri } = useActions();
  const blobStorage = useTypedSelector((state) => state.blobStorage);
  const { permissions } = useTypedSelector((state) => state.users?.user);
  const [blobs, setBlobs] = useState<Loader<BlobResult[]>>({});
  const directories = [
    ...(blobStorage?.data?.directories || []),
    defaultLastTab,
  ];
  const [activeTab, setActiveTab] = useState<string>(directories[0]);

  useEffect(() => {
    // Call the method initially when the component mounts
    getSignedBlobServiceUri();

    // Set up an interval to call the method every 5 minutes
    const intervalId = setInterval(getSignedBlobServiceUri, 300000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (
      blobStorage?.error === Messages.AccessDenied ||
      !permissions?.includes(Permissions.UploadedReports)
    ) {
      navigate(PageRoutesConstant.Page.AccessDenied.path, {
        replace: true,
      });
    }
  }, [blobStorage?.error]);

  const getBlobFiles = (
    activeTab: string,
    uploadStartDate?: dayjs.Dayjs,
    uploadEndDate?: dayjs.Dayjs,
    searchedName?: string
  ) => {
    const { serviceUri, containerName } = blobStorage.data;
    getLoaderValue(setBlobs, () =>
      listBlobFilesInDirectory(
        serviceUri,
        containerName,
        activeTab,
        uploadStartDate,
        uploadEndDate,
        searchedName
      )
    );
  };

  useEffect(() => {
    if (
      !blobs.value &&
      !blobs.isLoading &&
      !blobStorage?.loading &&
      !!blobStorage?.data
    ) {
      getBlobFiles(activeTab);
    }
  }, [blobs, blobStorage]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const [isFilterOptionOpen, setIsFilterOptionOpen] = useState<boolean>(false);

  const [filteredValue, setFilteredValue] = useState<{
    uploadStartDate?: string;
    uploadEndDate?: string;
  }>({});

  const [sortedInfo, setSortedInfo] = useState<SorterResult<BlobResult>>({});

  const onResetAll = () => {
    setCurrentPage(1);
    getBlobFiles(activeTab);
    filterForm.setFieldsValue(EmptyFormFields);
    setFilteredValue({});
    setSelectedFileNameSearch(undefined);
    setSortedInfo({});
    setSelectedRowKeys([]);
  };

  const onFilter = () => {
    filterForm
      .validateFields()
      .then((values) => {
        setFilteredValue({
          uploadStartDate: FormatDate(values.uploadStartDate),
          uploadEndDate: FormatDate(values.uploadEndDate),
        });
        getBlobFiles(activeTab, values.uploadStartDate, values.uploadEndDate);
        setIsFilterOptionOpen(false);
        setCurrentPage(1);
      })
      .catch((info) => {
        console.log("Filter Validate Failed:", info);
      });
  };
  const onClearFilter = () => {
    setCurrentPage(1);
    filterForm.setFieldsValue(EmptyFormFields);
    setFilteredValue({});
    getBlobFiles(activeTab);
    setIsFilterOptionOpen(false);
  };
  const onCloseFilterOptions = () => {
    setIsFilterOptionOpen(false);
  };

  const [isDownloading, setIsDownloading] = useState(false);

  const downloadSelectedBlobs = async () => {
    const { serviceUri, containerName } = blobStorage.data;
    setIsDownloading(true);
    try {
      await downloadBlobs(
        serviceUri,
        containerName,
        selectedRowKeys,
        getTabLabel(activeTab).replace(" ", "_")
      );
    } catch (error) {
      console.log("Download Blobs Error - ", error);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: unknown,
    sorter: SorterResult<BlobResult>
  ) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
    setSortedInfo(sorter);
  };

  const onTabChange = (tab: string) => {
    setCurrentPage(1);
    setPageSize(10);
    filterForm.setFieldsValue(EmptyFormFields);
    setFilteredValue({});
    setSelectedRowKeys([]);
    setActiveTab(tab);
    setSelectedFileNameSearch(undefined);
    getBlobFiles(tab);
  };

  const tableLocale = {
    emptyText: "Reports Not Found",
  };

  const handleOpenFilterOptionsChange = (newOpen: boolean) => {
    setIsFilterOptionOpen(newOpen);
  };

  const removeFilteredValue = (key: keyof FilterFormValues) => {
    if (key === "uploadStartDate" || key === "uploadEndDate") {
      filterForm.setFieldsValue({
        uploadStartDate: undefined,
        uploadEndDate: undefined,
      });
    } else {
      filterForm.setFieldValue(key, undefined);
    }
    const values = filterForm.getFieldsValue();
    setFilteredValue({
      uploadStartDate: FormatDate(values.uploadStartDate),
      uploadEndDate: FormatDate(values.uploadEndDate),
    });
    getBlobFiles(activeTab);
    setIsFilterOptionOpen(false);
    setCurrentPage(1);
  };

  const [selectedFileNameSearch, setSelectedFileNameSearch] =
    useState<string>();
  const updateSelectedFileNameSearch = (selectedResult?: string) => {
    setSelectedFileNameSearch(selectedResult);
    getBlobFiles(
      activeTab,
      filterForm.getFieldValue("uploadStartDate"),
      filterForm.getFieldValue("uploadEndDate"),
      selectedResult
    );
    setCurrentPage(1);
  };

  const getSortOrder = (columnKey: string) => {
    return sortedInfo.columnKey === columnKey ? sortedInfo.order : null;
  };

  const columns: ColumnType<BlobResult>[] = [
    {
      title: isMobile ? (
        "Name"
      ) : (
        <span
          onClick={(e) => {
            e.stopPropagation();
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <FileNameSearch
            selectedResult={selectedFileNameSearch}
            updateSelectedResult={updateSelectedFileNameSearch}
            activeTab={activeTab}
            blobStorage={blobStorage}
          />
        </span>
      ),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: getSortOrder("name"),
      render: (_, record) => {
        return <Text className="text-break">{record.name}</Text>;
      },
    },
    {
      title: "Upload Date",
      dataIndex: "uploadDate",
      key: "uploadDate",
      sorter: (a, b) => {
        const dateA = dayjs(FormatDataDate(a.uploadDate));
        const dateB = dayjs(FormatDataDate(b.uploadDate));
        return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
      },
      sortOrder: getSortOrder("uploadDate"),
      render: (_, record) => {
        return record.uploadDate ? FormatDataDate(record.uploadDate, true) : "";
      },
    },
  ];

  const getTabLabel = (directory: string): string => {
    if (directory === "Reports") {
      return "Client Reports";
    } else if (directory === "Processed") {
      return "Raw Claims Data";
    } else {
      return directory;
    }
  };

  return (
    <div className="page_layout uploaded_files">
      <Row>
        <Col xs={24} className="px-md-4">
          <Row align="middle" gutter={[10, 10]} className="mb-4">
            <Col>
              <Popover
                arrow={false}
                placement="bottomRight"
                content={
                  <FilterOptions
                    form={filterForm}
                    onFilter={onFilter}
                    onClearFilter={onClearFilter}
                    onCloseFilterOptions={onCloseFilterOptions}
                  />
                }
                trigger="click"
                open={isFilterOptionOpen}
                onOpenChange={handleOpenFilterOptionsChange}
                className="uploaded_files_filter_options"
                overlayClassName="uploaded_files_filter_options_popup"
              >
                <SliderIcon />
              </Popover>
            </Col>
            <Col className="d-flex flex-wrap">
              {filteredValue &&
                Object.keys(filteredValue).map(
                  (key: keyof FilterFormValues, i) =>
                    filteredValue[key] !== null &&
                    filteredValue[key] !== "" &&
                    filteredValue[key] !== undefined && (
                      <Tag
                        key={i}
                        bordered={false}
                        closable={true}
                        className="filter-val-tag my-1"
                        onClose={() => removeFilteredValue(key)}
                      >
                        {filteredValue[key]}
                      </Tag>
                    )
                )}
            </Col>
            <Col>
              <Button
                className="uploaded_files_reset_all_button"
                onClick={onResetAll}
                size="large"
                type="text"
              >
                Reset All
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Tabs
            size="large"
            type="card"
            className="uploaded_files_report-tabs"
            onChange={onTabChange}
            items={directories.map((dir) => {
              return {
                label: getTabLabel(dir),
                key: dir,
                children: (
                  <Table
                    showSorterTooltip={false}
                    dataSource={blobs.value}
                    columns={columns}
                    locale={tableLocale}
                    rowSelection={{
                      selectedRowKeys,
                      type: "checkbox",
                      onChange: (record) =>
                        setSelectedRowKeys(record.map((r) => r.toString())),
                    }}
                    onChange={handleTableChange}
                    title={() => (
                      <TableTitle
                        activeTab={activeTab}
                        isDownloadDisabled={
                          selectedRowKeys.length === 0 || isDownloading
                        }
                        onDownload={downloadSelectedBlobs}
                        isLoading={isDownloading}
                      />
                    )}
                    pagination={{
                      current: currentPage,
                      hideOnSinglePage: true,
                      pageSize: pageSize,
                      prevIcon: (
                        <CustomButton
                          iconPosition="left"
                          icon={<LeftOutlined />}
                          className="uploaded_files_custom_pagination_btn prev"
                          text={UploadedReportsConstant.PrevButtonText}
                          font={16}
                          radius={8}
                          width={111}
                        />
                      ),
                      nextIcon: (
                        <CustomButton
                          iconPosition="right"
                          icon={<RightOutlined />}
                          className="uploaded_files_custom_pagination_btn next"
                          text={UploadedReportsConstant.NextButtonText}
                          font={16}
                          radius={8}
                          width={111}
                        />
                      ),
                    }}
                    loading={{
                      size: "large",
                      tip: "Loading...",
                      spinning: blobs.isLoading,
                      indicator: <LoadingOutlined />,
                    }}
                  />
                ),
              };
            })}
          ></Tabs>
        </Col>
      </Row>
    </div>
  );
};

type DownloadReportButtonProps = {
  activeTab: string;
  isDownloadDisabled?: boolean;
  onDownload: () => void;
  isLoading?: boolean;
};

const TableTitle: FC<DownloadReportButtonProps> = (props) => {
  const items: MenuProps["items"] = [
    {
      label: <DownloadReportsButton {...props} />,
      key: "0",
    },
  ];

  const getTitle = (tab: string): string => {
    if (tab === "Reports") {
      return "Client Reports";
    } else if (tab === "Processed") {
      return "Raw Claims Data Reports";
    } else {
      return `${tab} Reports`;
    }
  };

  return (
    <Row align="middle">
      <Col xs={20} sm={18} className="uploaded_files_header">
        <Title level={4} className="mb-0 uploaded_files_header_title">
          {getTitle(props.activeTab)}
        </Title>
      </Col>
      <Col sm={6} className="uploaded_files_header_btn_wrapper">
        <DownloadReportsButton {...props} />
      </Col>
      <Col xs={4} className="uploaded_files_menu">
        <Dropdown
          menu={{ items }}
          trigger={["click"]}
          className="uploaded_files_header_dropdown"
          overlayClassName="uploaded_files_header_dropdown_overlay"
        >
          <MenuOutlined style={{ fontSize: 24, color: "#808080" }} />
        </Dropdown>
      </Col>
    </Row>
  );
};

const DownloadReportsButton: FC<DownloadReportButtonProps> = ({
  isDownloadDisabled,
  onDownload,
  isLoading,
}) => {
  return (
    <CustomButton
      className="uploaded_files_header_btn_wrapper_export_data_btn"
      text={UploadedReportsConstant.DownloadFilesButtonText}
      radius={8}
      loading={isLoading}
      disabled={isDownloadDisabled}
      onClick={onDownload}
    />
  );
};

const listBlobFilesInDirectory = async (
  serviceUri: string,
  containerName: string,
  directory: string,
  uploadStartDate?: dayjs.Dayjs,
  uploadEndDate?: dayjs.Dayjs,
  searchedName?: string
): Promise<BlobResult[]> => {
  const blobServiceClient = new BlobServiceClient(serviceUri);

  const containerClient: ContainerClient =
    blobServiceClient.getContainerClient(containerName);

  const iterator = containerClient
    .listBlobsFlat({
      prefix: `${directory === defaultLastTab ? "New" : directory}/`,
    })
    .byPage({});
  const blobs: BlobResult[] = [];
  for await (const response of iterator) {
    const blobItems = response.segment.blobItems.filter(
      (blob) => blob.properties && blob.properties["ResourceType"] === "file"
    );
    for (const blob of blobItems) {
      const blobKey = blob.name;
      const blobName = blobKey.substring(blobKey.lastIndexOf("/") + 1);

      const blobCreatedOn = blob.properties.createdOn;

      if (uploadStartDate && uploadEndDate) {
        if (
          ParseDayJsDate(blobCreatedOn).format(DATE_FORMAT_DEFAULT) >=
            uploadStartDate.format(DATE_FORMAT_DEFAULT) &&
          ParseDayJsDate(blobCreatedOn).format(DATE_FORMAT_DEFAULT) <=
            uploadEndDate.format(DATE_FORMAT_DEFAULT)
        ) {
          blobs.push({
            key: blobKey,
            name: blobName,
            uploadDate: blobCreatedOn,
          });
        }
      } else {
        blobs.push({
          key: blobKey,
          name: blobName,
          uploadDate: blobCreatedOn,
        });
      }
    }
  }
  return searchedName
    ? blobs.filter((blob) => blob.name.includes(searchedName))
    : blobs;
};

const downloadBlobs = async (
  serviceUri: string,
  containerName: string,
  blobNames: string[],
  zipFileName: string
) => {
  const blobServiceClient = new BlobServiceClient(serviceUri);
  const containerClient = blobServiceClient.getContainerClient(containerName);

  if (blobNames.length > 1) {
    const zip = new JSZip();
    const fetchPromises = blobNames.map(async (blobName) => {
      const blobClient = containerClient.getBlobClient(blobName);
      const fileName = decodeURIComponent(
        new URL(blobClient.url).pathname.split("/").pop()
      );
      try {
        const response = await fetch(blobClient.url);
        if (!response.ok) {
          throw new Error(`Failed to fetch ${blobClient.url}`);
        }
        const blob = await response.blob();
        zip.file(fileName, blob);
      } catch (error) {
        console.error(`Error fetching ${blobClient.url}:`, error);
      }
    });

    await Promise.all(fetchPromises);
    // Generate the zip file
    await zip.generateAsync({ type: "blob" }).then((blob) => {
      // Create a download link and trigger the download
      const url = window.URL.createObjectURL(blob);
      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = `${zipFileName}_files.zip`;
      downloadLink.click();
      window.URL.revokeObjectURL(url);
    });
  } else {
    const blobClient = containerClient.getBlobClient(blobNames[0]);
    const downloadLink = document.createElement("a");
    const fileName = new URL(blobClient.url).pathname.split("/").pop();
    downloadLink.href = blobClient.url;
    downloadLink.download = fileName;
    downloadLink.click();
  }
};

type FilterOptionsProps = {
  form: FormInstance;
  onFilter: () => void;
  onClearFilter: () => void;
  onCloseFilterOptions: () => void;
};

const FilterOptions: FC<FilterOptionsProps> = ({
  form,
  onFilter,
  onClearFilter,
  onCloseFilterOptions,
}) => {
  return (
    <div className="p-2">
      <Form form={form}>
        <Row gutter={[15, 10]}>
          <Col xs={24}>
            <Row gutter={[7, 12]}>
              <Col xs={24}>
                <Title
                  level={5}
                  className="uploaded_files_filter_options_title"
                >
                  Upload Date
                </Title>
                <Form.Item name="uploadStartDate" label="" className="mb-0">
                  <DatePicker
                    size="large"
                    className="uploaded_files_filter_options_custom_date_picker"
                    format={DATE_FORMAT_DEFAULT}
                    placeholder="Start Date"
                    onChange={(date) =>
                      !date ||
                      (date.isAfter(form.getFieldValue("uploadEndDate")) &&
                        form.setFieldValue("uploadEndDate", ""))
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item name="uploadEndDate" label="" className="mb-0">
                  <DatePicker
                    size="large"
                    className="uploaded_files_filter_options_custom_date_picker"
                    format={DATE_FORMAT_DEFAULT}
                    placeholder="End Date"
                    disabledDate={(date) =>
                      !date ||
                      date.isBefore(form.getFieldValue("uploadStartDate"))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24}>
            <Row gutter={[5, 5]}>
              <Col xs={24}>
                <CustomButton
                  className="uploaded_files_filter_options_custom_button"
                  text="Filter"
                  backgroundColor="#B8C7B9"
                  radius={8}
                  font={16}
                  color="#1A1419"
                  buttonType="submit"
                  onClick={onFilter}
                ></CustomButton>
              </Col>
              <Col xs={24}>
                <Row gutter={[5, { xs: 5, md: 0 }]}>
                  <Col xs={12} sm={12}>
                    <CustomButton
                      className="uploaded_files_filter_options_custom_button"
                      backgroundColor="#B8C7B9"
                      text="Clear"
                      radius={8}
                      font={16}
                      color="#1A1419"
                      onClick={onClearFilter}
                    ></CustomButton>
                  </Col>
                  <Col xs={12}>
                    <CustomButton
                      className="uploaded_files_filter_options_custom_button"
                      backgroundColor="#B8C7B9"
                      text="Close"
                      radius={8}
                      font={16}
                      color="#1A1419"
                      onClick={onCloseFilterOptions}
                    ></CustomButton>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

type FileNameSearchProps = {
  selectedResult?: string;
  updateSelectedResult: (selectedResult?: string) => void;
  activeTab: string;
  blobStorage: BlobStorageState;
};

const FileNameSearch: FC<FileNameSearchProps> = ({
  selectedResult,
  updateSelectedResult,
  activeTab,
  blobStorage,
}) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [blobs, setBlobs] = useState<Loader<BlobResult[]>>({});
  useEffect(() => {
    if (
      !blobs.value &&
      !blobs.isLoading &&
      !blobStorage?.loading &&
      !!blobStorage?.data
    ) {
      const { serviceUri, containerName } = blobStorage.data;
      getLoaderValue(setBlobs, () =>
        listBlobFilesInDirectory(serviceUri, containerName, activeTab)
      );
    }
  }, [blobs, blobStorage]);

  useEffect(() => {
    setSearchValue(undefined);
  }, [activeTab]);

  const [blobNames, setBlobNames] = useState<string[]>([]);

  const handleSearch = (newValue: string) => {
    setSearchValue(newValue.trim());
    setBlobNames(
      newValue
        ? blobs?.value
            ?.filter((blob) =>
              blob.name.toLowerCase().includes(newValue.toLowerCase())
            )
            .map((blob) => blob.name)
        : []
    );
    setTimeout(() => {
      if (newValue.trim() === "") {
        handleRemoveFirstOption();
      }
    }, 100);
  };

  const handleClear = () => {
    updateSelectedResult(undefined);
    setSearchValue(undefined);
  };

  const handleChange = (newValue: string) => {
    const searchInput = document.getElementById("file_name_search");
    if (!newValue && searchInput) {
      searchInput.blur();
    }

    updateSelectedResult(newValue);
  };

  const renderOptions = !searchValue
    ? null
    : blobNames?.map((item) => (
        <Select.Option key={item} value={item}>
          <div
            className="text-truncate"
            style={{
              color: "#818181",
              fontWeight: 400,
            }}
          >
            <BoldSearchMatch searchResult={item} searchValue={searchValue} />
          </div>
        </Select.Option>
      ));

  const handleRemoveFirstOption = () => {
    const selectElement = document.querySelector(
      `.${activeTab}`
    ) as HTMLElement;
    if (selectElement) {
      const firstOptionElement = selectElement.querySelector(
        ".ant-select-item-option"
      ) as HTMLOptionElement;
      if (
        firstOptionElement &&
        (firstOptionElement.value === "" ||
          firstOptionElement.value === undefined)
      ) {
        firstOptionElement.style.display = "none";
      }
    }
  };

  const notFoundContent = useMemo(() => {
    if (searchValue && !blobNames?.length) {
      return "No Results Found";
    }
    return undefined;
  }, [searchValue, blobNames]);

  useEffect(() => {
    if (searchValue?.trim() === "") {
      setSearchValue(undefined);
    }
  }, [searchValue]);

  return (
    <div>
      <Row>
        <Col span={24}>
          <Select
            id="file_name_search"
            className="uploaded_files_search"
            popupClassName={`uploaded_files_search_item ${activeTab}`}
            showSearch
            size="large"
            suffixIcon={<SearchOutlined />}
            value={selectedResult}
            allowClear={true}
            placeholder="Name Search"
            defaultActiveFirstOption={false}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            onFocus={handleRemoveFirstOption}
            onClear={handleClear}
            optionFilterProp="children"
            notFoundContent={notFoundContent}
            dropdownRender={(menu) => (
              <div>
                {menu}
                {((!searchValue && !blobNames?.length) ||
                  blobNames?.length > 5) && (
                  <div className="narrow_down">
                    Enter more characters to narrow down results.
                  </div>
                )}
              </div>
            )}
          >
            {renderOptions}
          </Select>
        </Col>
      </Row>
    </div>
  );
};

type BoldSearchMatchProps = {
  searchResult: string;
  searchValue: string;
};
const BoldSearchMatch: FC<BoldSearchMatchProps> = ({
  searchResult,
  searchValue,
}) => {
  if (!searchValue || searchValue.trim() === "") return <>{searchResult}</>;

  const parts = useMemo(() => {
    const searchRegExp = new RegExp(`^(.*)(${searchValue})(.*)$`, "i");
    const matchResult = searchRegExp.exec(searchResult);
    if (!matchResult || matchResult.length < 4) return null;
    const [prefix, searchMatch, suffix] = matchResult.slice(1);
    return { prefix, searchMatch, suffix };
  }, [searchResult, searchValue]);

  if (!parts) return <>{searchResult}</>;

  const { prefix, searchMatch, suffix } = parts;

  return (
    <>
      {prefix}
      <span style={{ fontWeight: "bold" }}>{searchMatch}</span>
      {suffix}
    </>
  );
};

export default UploadedReports;
