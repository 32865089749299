import {
  ArrowCircleIcon,
  LeafIcon,
  PadIcon,
  StepBarIcon,
  PatientIcon,
} from "assets/icons";
import { Roles } from "common/constants/Roles";

export const HomeConstants = {
  WelcomeText: "Welcome Back,",
  PatientCareTitle: "Excel In Patient Care",
  PatientCareDescription:
    "See patients with complex needs and multiple chronic conditions that rely on your enhanced care and specialized approach.",
  PatientCareButtonText: "Review High Need Patients",
  Tiles: [
    {
      Title: "Create a customized claims report",
      Description:
        "Generate a customized claims report by selecting from various filters of patient data.",
      Icon: PadIcon,
      ActionText: "GET STARTED",
      Path: "/reports/claims",
      roles: [],
    },
    {
      Title: "search patient data",
      Description:
        "Get all the info for a particular patient by searching their First name, Last name, or Member ID.",
      Icon: LeafIcon,
      ActionText: "GET STARTED",
      Path: "/reports/patients", //"PatientSearch",
      roles: [],
    },
    {
      Title: "Build a general gap analysis",
      Description:
        "See all measures’ performance rate and get closer to hitting the target rate.",
      Icon: StepBarIcon,
      ActionText: "GET STARTED",
      Path: "/reports/analysis",
      roles: [],
    },
    {
      Title: "build a specific gap analysis",
      Description:
        "Track the performance rate for a specific measure across all patients.",
      Icon: ArrowCircleIcon,
      ActionText: "GET STARTED",
      Path: "",
      Disabled: true,
      Tooltip: "Coming Soon...",
      roles: [],
    },
    {
      Title: "My patients due for",
      Description: "My patients due for",
      Icon: PatientIcon,
      ActionText: "GET STARTED",
      Path: "",
      Disabled: true,
      Tooltip: "Coming Soon...",
      roles: [Roles.Admin, Roles.Clinic_Admin],
    },
  ],
};
