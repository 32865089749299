import {
  PaginationResponseDtoOfRoleDto,
  RoleDto,
} from "clients/api.generated.clients";
import { Action } from "./actions";
import { RoleActionType } from "./actionTypes";

export interface RolesState {
  loading: boolean;
  error: string | undefined;
  data?: PaginationResponseDtoOfRoleDto | undefined;
  add?: RoleDto | undefined;
  update: boolean;
  delete: boolean;
}

const initialState = {
  loading: false,
  error: undefined,
  data: undefined,
  add: undefined,
  update: false,
  delete: false,
};

const RoleReducer = (
  state: RolesState = initialState,
  action: Action
): RolesState => {
  switch (action.type) {
    case RoleActionType.GET_ROLES:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case RoleActionType.GET_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload.data,
      };

    case RoleActionType.GET_ROLES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RoleActionType.CREATE_ROLE:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case RoleActionType.CREATE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        add: action.payload.data,
      };

    case RoleActionType.CREATE_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RoleActionType.UPDATE_ROLE:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case RoleActionType.UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        update: action.payload.data,
      };

    case RoleActionType.UPDATE_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RoleActionType.DELETE_ROLE:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case RoleActionType.DELETE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        delete: action.payload.data,
      };

    case RoleActionType.DELETE_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default RoleReducer;
