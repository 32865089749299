import {
  ResponseDtoOfListOfPatientKeyDto,
  PaginationResponseDtoOfPatientDto,
  PatientDto,
} from "clients/api.generated.clients";
import { Action } from "./actions";
import { PatientActionType } from "./actionTypes";

export interface PatientsState {
  loading: boolean;
  error: string | undefined;
  data: PaginationResponseDtoOfPatientDto | undefined;
  exportLoading: boolean;
  search: {
    result?: ResponseDtoOfListOfPatientKeyDto;
    isLoading?: boolean;
    error?: string | undefined;
  };
  patient: PatientDto | undefined;
}

const initialState: PatientsState = {
  loading: false,
  error: undefined,
  data: undefined,
  exportLoading: false,
  search: {},
  patient: undefined,
};

const PatientReducer = (
  state: PatientsState = initialState,
  action: Action
): PatientsState => {
  switch (action.type) {
    case PatientActionType.GET_PATIENTS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case PatientActionType.GET_PATIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload.data,
      };

    case PatientActionType.GET_PATIENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PatientActionType.EXPORT_DATA_EXCEL:
      return {
        ...state,
        exportLoading: true,
        error: undefined,
      };

    case PatientActionType.EXPORT_DATA_EXCEL_SUCCESS:
      return {
        ...state,
        exportLoading: false,
        error: undefined,
      };

    case PatientActionType.EXPORT_DATA_EXCEL_FAILURE:
      return {
        ...state,
        exportLoading: false,
        error: action.payload,
      };

    case PatientActionType.SEARCH_PATIENTS:
      return {
        ...state,
        search: {
          isLoading: true,
          error: undefined,
        },
      };

    case PatientActionType.SEARCH_PATIENTS_SUCCESS:
      return {
        ...state,
        search: {
          isLoading: false,
          error: undefined,
          result: action.payload,
        },
      };

    case PatientActionType.SEARCH_PATIENTS_FAILURE:
      return {
        ...state,
        search: {
          isLoading: false,
          error: action.payload,
        },
      };

    case PatientActionType.GET_PATIENT_BY_MEMBERID:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case PatientActionType.GET_PATIENT_BY_MEMBERID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        patient: action.payload.data,
      };

    case PatientActionType.GET_PATIENT_BY_MEMBERID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default PatientReducer;
