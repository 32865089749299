export enum UserActionType {
  ADD_USER = "ADD_USER",
  ADD_USER_SUCCESS = "ADD_USER_SUCCESS",
  ADD_USER_FAILURE = "ADD_USER_FAILURE",
  GET_USERS = "GET_USERS",
  GET_USERS_SUCCESS = "GET_USERS_SUCCESS",
  GET_USERS_FAILURE = "GET_USERS_FAILURE",
  UPDATE_USER_ROLE = "UPDATE_USER_ROLE",
  UPDATE_USER_ROLE_SUCCESS = "UPDATE_USER_ROLE_SUCCESS",
  UPDATE_USER_ROLE_FAILURE = "UPDATE_USER_ROLE_FAILURE",
  GET_USER_NAMES = "GET_USER_NAMES",
  GET_USER_NAMES_SUCCESS = "GET_USER_NAMES_SUCCESS",
  GET_USER_NAMES_FAILURE = "GET_USER_NAMES_FAILURE",
  GET_USER_ROLES_AND_PERMISSIONS = "GET_USER_ROLES_AND_PERMISSIONS",
  GET_USER_ROLES_AND_PERMISSIONS_SUCCESS = "GET_USER_ROLES_AND_PERMISSIONS_SUCCESS",
  GET_USER_ROLES_AND_PERMISSIONS_FAILURE = "GET_USER_ROLES_AND_PERMISSIONS_FAILURE",
  UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS",
  UPDATE_USER_SETTINGS_SUCCESS = "UPDATE_USER_SETTINGS_SUCCESS",
  UPDATE_USER_SETTINGS_FAILURE = "UPDATE_USER_SETTINGS_FAILURE",
  GET_USER_SETTINGS = "GET_USER_SETTINGS",
  GET_USER_SETTINGS_SUCCESS = "GET_USER_SETTINGS_SUCCESS",
  GET_USER_SETTINGS_FAILURE = "GET_USER_SETTINGS_FAILURE",
  SET_USER_SETTINGS = "SET_USER_SETTINGS",
}
