export const CreateRoleConstants = {
  PageTitle: "Create Role",
  PermissionTitle: "Permissions",
  FormItem: {
    Name: "Role Name",
    Description: "Description",
  },
  SuccessMessage: "Role created successfully!",
  SubmitButtonText: "Create Role",
};
