import { Button, ButtonProps } from "antd";
import { ButtonHTMLType } from "antd/es/button";
import React, { FC, Ref } from "react";
import "./CustomButton.style.scss";

interface CustomButtonProps extends ButtonProps {
  backgroundColor?: string;
  text?: string;
  fontColor?: string;
  image?: string;
  font?: number;
  radius?: number;
  height?: number;
  border?: string;
  width?: number;
  buttonType?: ButtonHTMLType;
  reference?: Ref<HTMLElement>;
  iconPosition?: string | "left" | "right";
}

const CustomButton: FC<CustomButtonProps> = (props: CustomButtonProps) => {
  const {
    backgroundColor,
    text,
    font,
    radius,
    className,
    disabled,
    height,
    fontColor,
    border,
    onClick,
    icon,
    image,
    width,
    buttonType,
    reference,
    loading,
    iconPosition,
  } = props;

  return (
    <>
      <Button
        disabled={disabled}
        className={`custom_btn ${className ? className : ""}`}
        style={{
          backgroundColor: backgroundColor,
          borderRadius: radius,
          fontSize: font,
          height: height || "auto",
          color: fontColor || "#000000",
          border: border,
          width: width || "auto",
        }}
        onClick={onClick}
        htmlType={buttonType}
        ref={reference}
        loading={loading}
      >
        {iconPosition === "left" && icon}
        {text}
        {iconPosition === "right" && icon}
        {image && <img alt="" src={image} />}
        {!iconPosition && icon}
      </Button>
    </>
  );
};

export default CustomButton;
