import { composeWithDevTools } from "@redux-devtools/extension";
import { applyMiddleware, legacy_createStore as createStore } from "redux";
import thunk from "redux-thunk";
import reducers, { RootState } from "./rootReducer";

const stateVersion = 4; // increment this whenever the shape of the state changes to avoid loading old invalid states
const stateKey = `state:v${stateVersion}`;

const saveToLocalStorage = (state: RootState) => {
  const serializedState = JSON.stringify(state);
  localStorage.setItem(stateKey, serializedState);
};

const loadFromLocalStorage = (): RootState => {
  const serializedState = localStorage.getItem(stateKey);
  if (serializedState === null) return undefined;
  return JSON.parse(serializedState);
};

const presistedState = loadFromLocalStorage();
const store = createStore(
  reducers,
  presistedState,
  composeWithDevTools(applyMiddleware(thunk))
);
store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
