import AccessDenied from "pages/access-denied/AccessDenied";
import Home from "pages/home/Home";

interface RouteKey {
  [key: string]: {
    path: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component?: any;
  };
}

interface IRoute {
  Page: RouteKey;
}

export const PageRoutesConstant: IRoute = {
  Page: {
    Home: {
      path: "/",
      component: Home,
    },
    Admin: {
      path: "/admin",
    },
    AccessDenied: {
      path: "/access-denied",
      component: AccessDenied,
    },
    Reports: {
      path: "/reports",
    },
  },
};
