import { Col, Row, Typography, message } from "antd";
import React, { FC, useState } from "react";
import CustomButton from "common/components/button/CustomButton";
import { useNavigate } from "react-router";
import { AdminFlowRoutesConstant } from "common/router/AdminFlowRoutes.constant";
import { useActions } from "hooks/useActions";
import { DeleteRoleConstant } from "./DeleteRole.Constants";
import { useTypedSelector } from "hooks/useTypedSelector";
import { Messages } from "common/constants/Messages";
import { PageRoutesConstant } from "common/router/PageRoutes.constant";
import "./DeleteRole.style.scss";

const { Title, Paragraph } = Typography;

interface DeleteRoleProps {
  roleId: string;
  onPopupClose: () => void;
}

const DeleteRole: FC<DeleteRoleProps> = ({ roleId, onPopupClose }) => {
  const { userNames } = useTypedSelector((state) => state.users);
  const navigate = useNavigate();
  const { deleteRole } = useActions();
  const [isLoading, setIsLoading] = useState(false);

  const onCancel = () => {
    onPopupClose();
  };

  const onDeleteRole = () => {
    setIsLoading(true);
    deleteRole(roleId, (res) => {
      if (res.success) {
        onPopupClose();
        message.success(DeleteRoleConstant.SuccessMessageText, 5);
      } else {
        if (res.message === Messages.AccessDenied) {
          navigate(PageRoutesConstant.Page.AccessDenied.path, {
            replace: true,
          });
        }
        message.error(res.message, 5);
      }
      setIsLoading(false);
    });
  };

  return (
    <div className="delete_role">
      <Row>
        <Col>
          <Title className="delete_role_title" level={2}>
            {DeleteRoleConstant.PageTitle}
          </Title>
        </Col>
        {userNames.length > 0 ? (
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Paragraph className="delete_role_description">
                  {DeleteRoleConstant.UserListText}
                </Paragraph>
              </Col>
              <Col span={24}>
                <Title level={5} className="delete_role_title_text mb-0">
                  {DeleteRoleConstant.UsersTitle}
                </Title>
                {userNames.map((userName, index) => (
                  <Paragraph
                    key={`${index}_${userName}`}
                    className="delete_role_description_text mb-0"
                  >
                    {userName}
                  </Paragraph>
                ))}
              </Col>
              <Col className="delete_role_btn_wrap mt-5" span={24}>
                <CustomButton
                  text={DeleteRoleConstant.ListButtonText}
                  type="link"
                  width={104}
                  backgroundColor="#B8C7B9"
                  font={16}
                  fontColor="#000000"
                  height={40}
                  className="delete_role_userlist_btn"
                  onClick={() =>
                    navigate(AdminFlowRoutesConstant.Admin.UserList.path)
                  }
                ></CustomButton>
                <CustomButton
                  text={DeleteRoleConstant.CancelButtonText}
                  type="link"
                  width={92}
                  backgroundColor="#B8C7B9"
                  font={16}
                  fontColor="#000000"
                  height={40}
                  className="delete_role_cancel_btn ms-2"
                  onClick={onCancel}
                ></CustomButton>
              </Col>
            </Row>
          </Col>
        ) : (
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Paragraph className="delete_role_description">
                  {DeleteRoleConstant.DeleteRoleText}
                </Paragraph>
              </Col>
              <Col className="delete_role_btn_wrap mt-5" span={24}>
                <CustomButton
                  text={DeleteRoleConstant.DeleteButtonText}
                  type="link"
                  width={127}
                  backgroundColor="#B8C7B9"
                  font={16}
                  fontColor="#000000"
                  height={40}
                  className="delete_role_cancel_btn ms-2"
                  onClick={onDeleteRole}
                  disabled={isLoading}
                ></CustomButton>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default DeleteRole;
