import {
  Button,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Progress,
  Row,
  Select,
  SelectProps,
  Switch,
  Table,
  Tabs,
  Typography,
} from "antd";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useActions } from "hooks/useActions";
import { useTypedSelector } from "hooks/useTypedSelector";
import CustomButton from "common/components/button/CustomButton";
import {
  LeftOutlined,
  LoadingOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { FormatDataDate } from "common/utilities/format";
import { ColumnsType } from "antd/es/table";
import {
  AnalysisRequestDto,
  AnalysisViewDto,
  Measure,
} from "clients/api.generated.clients";
import { SorterResult } from "antd/es/table/interface";
import dayjs from "dayjs";
import { AnalysisTabs } from "common/constants/Tabs";
import { DownloadFile } from "common/utilities/converter";
import { AnalysisReportConstant } from "./AnalysisReport.constant";
import { RangePickerProps } from "antd/es/date-picker";
import "./AnalysisReport.style.scss";

const { Text, Title } = Typography;

const tabs = [
  { key: AnalysisTabs.Payor, label: "Payor View" },
  { key: AnalysisTabs.LineOfBusiness, label: "Line of Business View" },
  { key: AnalysisTabs.Clinic, label: "Clinic View" },
  { key: AnalysisTabs.Provider, label: "Provider View" },
];

const measureOptions = [
  { value: Measure.AWV, label: "AWV" },
  { value: Measure.A1C, label: "A1C" },
];

type AnalysisViewRow = AnalysisViewDto & { key: string };

type FilterFormValues = {
  measure: Measure;
  payors?: string[];
  lineOfBusinessGroups?: string[];
  clinics?: string[];
  providers?: string[];
};

const EmptyFormFields: FilterFormValues = {
  measure: undefined,
  payors: undefined,
  lineOfBusinessGroups: undefined,
  clinics: undefined,
  providers: undefined,
};

const AnalysisReport: FC = () => {
  const {
    getPayorsAnalysisReports,
    getLOBAnalysisReports,
    getClinicAnalysisReports,
    getProvidersAnalysisReports,
  } = useActions();
  const [currentTab, setCurrentTab] = useState<string>(AnalysisTabs.Payor);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<AnalysisViewRow>>(
    {}
  );
  const [filterForm] = Form.useForm<FilterFormValues>();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showYOY, setShowYOY] = useState(false);
  const currentDate = () => {
    const nowDate = new Date();
    const nowDayOfMonth = nowDate.getDate();
    const monthsToSubtract = nowDayOfMonth >= 20 ? 1 : 2;
    nowDate.setMonth(new Date().getMonth() - monthsToSubtract);
    return nowDate;
  };
  const [analysisRequest, setAnalysisRequest] = useState<AnalysisRequestDto>({
    filterDate: FormatDataDate(currentDate()),
    measure: Measure.AWV,
    page: 1,
    pageSize: 10,
  });

  const {
    exportPayorAnalysisData,
    exportLOBAnlysisData,
    exportProviderAnlysisData,
    exportClinicAnlysisData,
  } = useActions();

  const onExportDataClick = () => {
    if (currentTab === AnalysisTabs.Payor) {
      exportPayorAnalysisData(
        { ...analysisRequest, page: -1, pageSize: -1, hasYOY: showYOY },
        (res) => {
          if (res.success) {
            DownloadFile(
              res?.data?.contentType,
              res?.data?.rawData,
              res?.data?.fileName
            );
          }
        }
      );
    }
    if (currentTab === AnalysisTabs.LineOfBusiness) {
      exportLOBAnlysisData(
        { ...analysisRequest, page: -1, pageSize: -1, hasYOY: showYOY },
        (res) => {
          if (res.success) {
            DownloadFile(
              res?.data?.contentType,
              res?.data?.rawData,
              res?.data?.fileName
            );
          }
        }
      );
    }
    if (currentTab === AnalysisTabs.Clinic) {
      exportClinicAnlysisData(
        { ...analysisRequest, page: -1, pageSize: -1, hasYOY: showYOY },
        (res) => {
          if (res.success) {
            DownloadFile(
              res?.data?.contentType,
              res?.data?.rawData,
              res?.data?.fileName
            );
          }
        }
      );
    }
    if (currentTab === AnalysisTabs.Provider) {
      exportProviderAnlysisData(
        { ...analysisRequest, page: -1, pageSize: -1, hasYOY: showYOY },
        (res) => {
          if (res.success) {
            DownloadFile(
              res?.data?.contentType,
              res?.data?.rawData,
              res?.data?.fileName
            );
          }
        }
      );
    }
  };

  const analysis = useTypedSelector((state) => state.analysis);

  useEffect(() => {
    if (analysis.data === undefined) {
      setAnalysisRequest((prevAnalysisRequest) => ({
        ...prevAnalysisRequest,
        ...analysisRequest,
      }));
    }
  }, [analysisRequest, onExportDataClick]);

  useEffect(() => {
    if (currentTab == AnalysisTabs.Payor) {
      getPayorsAnalysisReports(analysisRequest);
    } else if (currentTab === AnalysisTabs.LineOfBusiness) {
      getLOBAnalysisReports(analysisRequest);
    } else if (currentTab === AnalysisTabs.Clinic) {
      getClinicAnalysisReports(analysisRequest);
    } else if (currentTab === AnalysisTabs.Provider) {
      getProvidersAnalysisReports(analysisRequest);
    }
  }, [currentTab, analysisRequest]);

  const handleTabChange = (currentTab: string) => {
    setCurrentTab(currentTab);
    setSortedInfo({});
    setCurrentPage(1);
    setPageSize(10);
    setAnalysisRequest({
      ...analysisRequest,
      filterDate: FormatDataDate(analysisRequest.filterDate),
      page: 1,
      pageSize: 10,
    });
  };

  const handleTableChange: any = (
    pagination,
    filters,
    sorter: SorterResult<AnalysisViewRow>
  ) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
    setSortedInfo(sorter);
    setAnalysisRequest({
      ...analysisRequest,
      sortBy: sorter.column ? sorter.columnKey.toString() : undefined,
      sortOrderAsc: sorter.order === "ascend",
      page: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const analysisViewData = useMemo<AnalysisViewDto[] | undefined>(
    () =>
      (analysis?.data?.items ?? [])?.map((analysis: AnalysisViewDto) => ({
        key: analysis.name,
        ...analysis,
      })),
    [analysis?.data]
  );

  const handleDateChange = (value) => {
    setAnalysisRequest({
      ...analysisRequest,
      filterDate: FormatDataDate(value),
    });
  };

  const onYOYChange = async (checked: boolean) => {
    setShowYOY(checked);
  };

  const getSortOrder = (columnKey: string) => {
    return !analysis.loading && sortedInfo.columnKey === columnKey
      ? sortedInfo.order
      : null;
  };

  const isNATarget = () => {
    if (currentTab === AnalysisTabs.Payor) {
      return false;
    } else if (currentTab === AnalysisTabs.LineOfBusiness) {
      return analysisRequest?.payors?.length > 1;
    } else if (
      currentTab === AnalysisTabs.Clinic ||
      currentTab === AnalysisTabs.Provider
    ) {
      return (
        !analysisRequest?.lineOfBusinessGroups ||
        analysisRequest?.lineOfBusinessGroups?.length > 1
      );
    }
  };

  const setTargetValue = (target?: number) => {
    return isNATarget() ? "N/A" : `${target || 0}%`;
  };

  const setGaptoTargetValue = (gaptoTarget: number) => {
    return isNATarget() ||
      ([AnalysisTabs.Clinic, AnalysisTabs.Provider].includes(currentTab) &&
        analysisRequest.lineOfBusinessGroups?.length !== 1)
      ? "N/A"
      : gaptoTarget;
  };

  const setSortOrder = () => {
    return analysisViewData.length > 0 && !isNATarget();
  };

  const getMeasureLabel = (measure: Measure) =>
    ({
      [Measure.AWV]: "AWV %",
      [Measure.A1C]: "A1C %",
    }[measure]);

  const getMeasureKey = (measure: Measure) =>
    ({
      [Measure.AWV]: "awv",
      [Measure.A1C]: "a1C",
    }[measure]);

  const columns: ColumnsType<AnalysisViewRow> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "25%",
      sorter: analysisViewData.length > 0 ? true : false,
      sortOrder: getSortOrder("name"),
      render: (_, record, index) => {
        return (
          <Row key={index} gutter={[0, 12]}>
            <Col span={24} className="analysis_view_table_item-middle">
              <Text>{record.name}</Text>
            </Col>
            {showYOY && (
              <Col span={24} className="analysis_view_table_item-middle">
                <Text>{`${record.pName} (${dayjs(analysisRequest.filterDate)
                  .subtract(1, "year")
                  .format("MM/YYYY")})`}</Text>
              </Col>
            )}
          </Row>
        );
      },
    },
    {
      title: getMeasureLabel(analysisRequest.measure),
      dataIndex: getMeasureKey(analysisRequest.measure),
      key: getMeasureKey(analysisRequest.measure),
      width: "25%",
      align: "center",
      sorter: analysisViewData.length > 0 ? true : false,
      sortOrder: getSortOrder(getMeasureKey(analysisRequest.measure)),
      render: (_, record, index) => {
        return (
          <Row key={index} gutter={[0, 12]}>
            <Col span={24} className="analysis_view_table_item-middle">
              <TableViewChart
                percent={
                  analysisRequest.measure === Measure.AWV
                    ? record.awv
                    : analysisRequest.measure === Measure.A1C
                    ? record.a1C
                    : 0
                }
              />
            </Col>
            {showYOY && (
              <Col span={24} className="analysis_view_table_item-middle">
                <TableViewChart
                  percent={
                    analysisRequest.measure === Measure.AWV
                      ? record.pawv
                      : analysisRequest.measure === Measure.A1C
                      ? record.pA1C
                      : 0
                  }
                  showYOY={showYOY}
                />
              </Col>
            )}
          </Row>
        );
      },
    },
    {
      title: "Target",
      dataIndex: "target",
      key: "target",
      width: "12%",
      align: "center",
      render: (_, record, index) => {
        return (
          <Row key={index} gutter={[0, 12]}>
            <Col span={24} className="analysis_view_table_item-middle">
              <Text>{setTargetValue(record.target)}</Text>
            </Col>
            {showYOY && (
              <Col span={24} className="analysis_view_table_item-middle">
                <Text>{setTargetValue(record.pTarget)}</Text>
              </Col>
            )}
          </Row>
        );
      },
    },
    {
      title: "Completed",
      dataIndex: "completed",
      key: "completed",
      width: "12%",
      align: "center",
      sorter: analysisViewData.length > 0 ? true : false,
      sortOrder: getSortOrder("completed"),
      render: (_, record, index) => {
        return (
          <Row gutter={[0, 12]}>
            <Col span={24}>
              <TableViewTergetBox
                data={record.completed}
                className="analysis_view_table_completed"
              />
            </Col>
            {showYOY && (
              <Col span={24}>
                <TableViewTergetBox
                  data={record.pCompleted}
                  className="analysis_view_table_completed"
                />
              </Col>
            )}
          </Row>
        );
      },
    },
    {
      title: "Gap to target",
      dataIndex: "gapToTarget",
      key: "gapToTarget",
      width: "14%",
      align: "center",
      sorter: setSortOrder(),
      sortOrder: getSortOrder("gapToTarget"),
      render: (_, record, index) => {
        return (
          <Row key={index} gutter={[0, 12]}>
            <Col span={24}>
              <TableViewTergetBox
                data={setGaptoTargetValue(record.gapToTarget)}
                className="analysis_view_table_gaptotarget"
              />
            </Col>
            {showYOY && (
              <Col span={24}>
                <TableViewTergetBox
                  data={setGaptoTargetValue(record.pGapToTarget)}
                  className="analysis_view_table_gaptotarget"
                />
              </Col>
            )}
          </Row>
        );
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      width: "12%",
      align: "center",
      sorter: analysisViewData.length > 0 ? true : false,
      sortOrder: getSortOrder("total"),
      render: (_, record, index) => {
        return (
          <Row key={index} gutter={[0, 12]}>
            <Col span={24}>
              <TableViewTergetBox
                data={record.total}
                className="analysis_view_table_total"
              />
            </Col>
            {showYOY && (
              <Col span={24}>
                <TableViewTergetBox
                  data={record.pTotal}
                  className="analysis_view_table_total"
                />
              </Col>
            )}
          </Row>
        );
      },
    },
  ];

  const onResetAllFilter = () => {
    setCurrentPage(1);
    setPageSize(10);
    setSortedInfo({});
    filterForm.setFieldsValue(EmptyFormFields);
    filterForm.setFieldValue("measure", Measure.AWV);
    setAnalysisRequest({
      ...analysisRequest,
      measure: Measure.AWV,
      payors: undefined,
      lineOfBusinessGroups: undefined,
      clinics: undefined,
      providers: undefined,
      sortBy: undefined,
      sortOrderAsc: undefined,
      page: 1,
      pageSize: pageSize,
    });
  };

  const onApplyFilter = () => {
    filterForm
      .validateFields()
      .then((values) => {
        setAnalysisRequest({
          ...analysisRequest,
          measure: values.measure,
          payors: values.payors,
          lineOfBusinessGroups: values.lineOfBusinessGroups,
          clinics: values.clinics,
          providers: values.providers,
          sortBy: undefined,
          sortOrderAsc: undefined,
          page: 1,
          pageSize: pageSize,
        });
        setCurrentPage(1);
      })
      .catch((info) => {
        console.log("Filter Validate Failed:", info);
      });
  };

  const handleDeselect = () => {
    filterForm
      .validateFields()
      .then((values) => {
        setAnalysisRequest({
          ...analysisRequest,
          measure: values.measure,
          payors: values.payors,
          lineOfBusinessGroups: values.lineOfBusinessGroups,
          clinics: values.clinics,
          providers: values.providers,
        });
      })
      .catch((info) => {
        console.log("Filter Validate Failed:", info);
      });
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    const jan2022 = dayjs("2022-01-01");
    const currentDayOfMonth = dayjs().date();
    const monthsToSubtract = currentDayOfMonth >= 20 ? 1 : 2;
    const monthsAfter = dayjs()
      .subtract(monthsToSubtract, "months")
      .endOf("day");

    return (
      current && (current.isBefore(jan2022) || current.isAfter(monthsAfter))
    );
  };

  return (
    <div className="page_layout">
      <div className="analysis">
        <Row>
          <Col>
            <Text className="analysis_title">
              <b>Analysis</b> Report
            </Text>
          </Col>
        </Row>
        <br />
        <Row gutter={[24, 10]}>
          <Col xs={24} md={6} xl={5}>
            <Filters
              form={filterForm}
              onApplyFilter={onApplyFilter}
              onResetAllFilter={onResetAllFilter}
              handleDeselect={handleDeselect}
            />
          </Col>
          <Col xs={24} md={18} xl={19}>
            <Row>
              <Col span={24}>
                <Tabs
                  size="large"
                  type="card"
                  className="analysis_box_tabs"
                  defaultActiveKey={currentTab}
                  onChange={(value) => handleTabChange(value)}
                  items={tabs.map((tab) => {
                    return {
                      label: tab.label,
                      key: tab.key,
                      children: (
                        <div className="analysis_view_table">
                          <Table
                            dataSource={analysisViewData}
                            showSorterTooltip={false}
                            columns={columns}
                            title={() => (
                              <Row
                                align="middle"
                                className="d-flex align-items-center"
                                gutter={[0, { xs: 10 }]}
                              >
                                <Col
                                  xs={24}
                                  sm={12}
                                  md={12}
                                  className="d-flex align-items-center justify-content-center justify-content-sm-start"
                                >
                                  <Title level={5} className="mb-0">
                                    {Measure[analysisRequest.measure]} values -
                                  </Title>
                                  <DatePicker
                                    className="ms-1"
                                    format={"MM/YYYY"}
                                    value={dayjs(analysisRequest.filterDate)}
                                    picker="month"
                                    onChange={handleDateChange}
                                    size="large"
                                    allowClear={false}
                                    disabledDate={disabledDate}
                                  />
                                </Col>
                                <Col xs={24} sm={12} md={12}>
                                  <Row
                                    align="middle"
                                    justify={{ xs: "center", sm: "end" }}
                                    gutter={[10, 10]}
                                  >
                                    <Col className="d-flex align-items-center justify-content-end">
                                      <Title level={5} className="mb-0">
                                        Show YoY
                                      </Title>
                                      <Switch
                                        className="ms-2"
                                        defaultChecked={showYOY}
                                        onChange={onYOYChange}
                                      />
                                    </Col>
                                    <Col>
                                      <CustomButton
                                        className="analysis_view_table_title_export_data_btn"
                                        text={
                                          analysis?.payorExportLoading
                                            ? "Loading…"
                                            : AnalysisReportConstant.ExportDataButtonText
                                        }
                                        font={16}
                                        radius={8}
                                        onClick={onExportDataClick}
                                        loading={
                                          analysis?.payorExportLoading ||
                                          analysis?.lineOfBusinessExportLoading ||
                                          analysis?.clinicExportLoading ||
                                          analysis?.providerExportLoading
                                        }
                                        disabled={
                                          analysis?.data?.items?.length === 0 ||
                                          analysis?.payorExportLoading ||
                                          analysis?.lineOfBusinessExportLoading ||
                                          analysis?.clinicExportLoading ||
                                          analysis?.providerExportLoading
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            )}
                            loading={{
                              size: "large",
                              tip: "Loading...",
                              spinning: analysis?.loading,
                              indicator: <LoadingOutlined />,
                            }}
                            onChange={handleTableChange}
                            pagination={{
                              current: currentPage,
                              hideOnSinglePage: true,
                              total: analysis?.data?.totalCount,
                              pageSize: pageSize,
                              prevIcon: (
                                <CustomButton
                                  iconPosition="left"
                                  icon={<LeftOutlined />}
                                  className="analysis_view_table_custom_pagination_btn prev"
                                  text={AnalysisReportConstant.PrevButtonText}
                                  font={16}
                                  radius={8}
                                  width={111}
                                />
                              ),
                              nextIcon: (
                                <CustomButton
                                  iconPosition="right"
                                  icon={<RightOutlined />}
                                  className="analysis_view_table_custom_pagination_btn next"
                                  text={AnalysisReportConstant.NextButtonText}
                                  font={16}
                                  radius={8}
                                  width={111}
                                />
                              ),
                            }}
                            scroll={{ x: "1000px" }}
                          />
                        </div>
                      ),
                    };
                  })}
                ></Tabs>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

type FiltersProps = {
  form: FormInstance;
  onApplyFilter: () => void;
  onResetAllFilter: () => void;
  handleDeselect: () => void;
};

const Filters: FC<FiltersProps> = ({
  form,
  onApplyFilter,
  onResetAllFilter,
  handleDeselect,
}) => {
  const {
    getAllPayors,
    getAllLineOfBusinessGroups,
    getAllProviderGroupNames,
    getProviders,
  } = useActions();
  const payors = useTypedSelector((state) => state.payors);
  const lineOfBusiness = useTypedSelector((state) => state.lineOfBusiness);
  const providerGroup = useTypedSelector((state) => state.providerGroup);
  const providers = useTypedSelector((state) => state.provider);

  useEffect(() => {
    getAllPayors();
    getAllLineOfBusinessGroups();
    getAllProviderGroupNames();
    getProviders();
  }, []);

  const payorOptions = useMemo<SelectProps["options"]>(
    () =>
      payors?.data.map((payor) => ({
        label: payor,
        value: payor,
      })),
    [payors?.data]
  );

  const lineOfBusinessOptions = useMemo<SelectProps["options"]>(
    () =>
      lineOfBusiness?.groups.map((lob) => ({
        label: lob,
        value: lob,
      })),
    [lineOfBusiness?.groups]
  );

  const clinicOptions = useMemo<SelectProps["options"]>(
    () =>
      providerGroup?.groups.map((group) => ({
        label: group,
        value: group,
      })),
    [providerGroup?.groups]
  );

  const providerOptions = useMemo<SelectProps["options"]>(
    () =>
      providers?.data.map((provider) => ({
        label: provider,
        value: provider,
      })),
    [providers?.data]
  );

  return (
    <div className="analysis_box">
      <div className="analysis_box_filter">
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Text className="analysis_box_text">
              Build a report by choosing parameters below:
            </Text>
          </Col>
          <Col span={24}>
            <Form form={form}>
              <Row gutter={[7, 12]}>
                <Col span={24}>
                  <Title level={5} className="mb-0 analysis_box_filter_title">
                    Measure
                  </Title>
                  <Form.Item
                    className="mb-0"
                    name="measure"
                    initialValue={Measure.AWV}
                  >
                    <Select
                      size="large"
                      placeholder="Select a Measure"
                      allowClear
                      popupClassName="analysis_box_filter_options_popup"
                      options={measureOptions}
                      onClear={handleDeselect}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Title level={5} className="mb-0 analysis_box_filter_title">
                    Payors
                  </Title>
                  <Form.Item className="mb-0" name="payors">
                    <Select
                      size="large"
                      placeholder="Select a Payors"
                      allowClear
                      popupClassName="analysis_box_filter_options_popup"
                      options={payorOptions}
                      mode="multiple"
                      onDeselect={handleDeselect}
                      onClear={handleDeselect}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Title level={5} className="mb-0 analysis_box_filter_title">
                    Line of Businesses
                  </Title>
                  <Form.Item className="mb-0" name="lineOfBusinessGroups">
                    <Select
                      size="large"
                      placeholder="Select a Line of Businesses"
                      allowClear
                      popupClassName="analysis_box_filter_options_popup"
                      options={lineOfBusinessOptions}
                      mode="multiple"
                      onDeselect={handleDeselect}
                      onClear={handleDeselect}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Title level={5} className="mb-0 analysis_box_filter_title">
                    Clinics
                  </Title>
                  <Form.Item className="mb-0" name="clinics">
                    <Select
                      size="large"
                      placeholder="Select a Clinics"
                      allowClear
                      popupClassName="analysis_box_filter_options_popup"
                      options={clinicOptions}
                      mode="multiple"
                      onDeselect={handleDeselect}
                      onClear={handleDeselect}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Title level={5} className="mb-0 analysis_box_filter_title">
                    Providers
                  </Title>
                  <Form.Item className="mb-0" name="providers">
                    <Select
                      size="large"
                      placeholder="Select a Providers"
                      allowClear
                      popupClassName="analysis_box_filter_options_popup"
                      options={providerOptions}
                      mode="multiple"
                      onDeselect={handleDeselect}
                      onClear={handleDeselect}
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>
              <br />
              <Row gutter={[10, 10]}>
                <Col span={12}>
                  <Button
                    type="default"
                    size="large"
                    className="analysis_box_filter_custom_button"
                    onClick={onResetAllFilter}
                  >
                    Reset All
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    type="primary"
                    size="large"
                    className="analysis_box_filter_custom_button"
                    onClick={onApplyFilter}
                  >
                    Apply
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

type TableViewChartProps = {
  percent: number;
  showYOY?: boolean | undefined;
};
const TableViewChart: FC<TableViewChartProps> = ({ percent, showYOY }) => {
  return (
    <div className="analysis_chart_percent">
      <Progress
        percent={percent}
        trailColor="transparent"
        className="mb-1"
        rootClassName={`${showYOY && percent > 0 ? "border-yoy" : ""}`}
        strokeColor={`${showYOY ? "rgb(251, 225, 214)" : ""}`}
        format={(per) => `${per}%`}
      />
    </div>
  );
};

type TableViewTergetBox = {
  data: number | string;
  className?: string;
};
const TableViewTergetBox: FC<TableViewTergetBox> = ({ data, className }) => {
  return <div className={`analysis_view_box ${className}`}>{data}</div>;
};

export default AnalysisReport;
