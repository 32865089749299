import React, { FC, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Layout } from "antd";
import UseTheme from "common/settings/theme/UseTheme";
import MainHeader from "common/components/header/MainHeader";
import AdminFlowRoutes from "./components/AdminFlowRoutes";
import PageRoutes from "./components/PageRoutes";
import MainFooter from "common/components/footer/MainFooter";
import RoleAccess from "common/components/access/RoleAccess";
import { useActions } from "hooks/useActions";
import { useTypedSelector } from "hooks/useTypedSelector";
import { Roles } from "common/constants/Roles";
import ReportRoutes from "./components/ReportRoutes";

const { Content } = Layout;

const MainRoutes: FC = () => {
  const { favicon, title } = UseTheme();

  const { getUserRolesAndPermissionsByUserName } = useActions();
  const { user } = useTypedSelector((state) => state.users);
  useEffect(() => {
    getUserRolesAndPermissionsByUserName();
  }, [!user]);

  return (
    <>
      <Helmet>
        <link rel="shortcut icon" type="image/png" href={favicon} />
        <title>{title}</title>
      </Helmet>
      <Layout>
        <MainHeader />
        <Content className="main-content">
          <Routes>
            <Route
              element={
                <RoleAccess
                  roles={[Roles.Admin, Roles.COIPA_Admin, Roles.Clinic_Admin]}
                />
              }
            >
              <Route path="/admin/*" Component={AdminFlowRoutes} />
            </Route>
            <Route
              element={
                <RoleAccess
                  roles={[Roles.Admin, Roles.COIPA_User, Roles.Clinic_User]}
                />
              }
            >
              <Route path="/reports/*" Component={ReportRoutes} />
            </Route>
            <Route path="/*" Component={PageRoutes} />
          </Routes>
        </Content>
        <MainFooter />
      </Layout>
    </>
  );
};

export default MainRoutes;
