import React, { FC, useEffect, useState } from "react";
import { Theme } from "common/interfaces/Theme";
import { Content } from "common/interfaces/Content";
import { AppProvider } from "common/settings/context/AppProvider";
import MainRoutes from "common/router/MainRoutes";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
  PublicClientApplication,
} from "@azure/msal-browser";
import {
  MsalAuthenticationTemplate,
  MsalProvider,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "common/settings/config/AzureAuthenticationConfig";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "common/components/error/ErrorFallback";
import { useNavigate } from "react-router";
import { Provider } from "react-redux";
import store from "store/store";

const AppContent: FC = () => {
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const login = loginRequest();
    if (inProgress === InteractionStatus.None) {
      if (accounts?.length > 0) {
        instance
          .acquireTokenSilent({
            ...login,
            account: accounts[0],
          })
          .catch(async (error) => {
            if (
              InteractionRequiredAuthError.isInteractionRequiredError(
                error.errorCode
              )
            ) {
              instance
                .acquireTokenRedirect({
                  ...login,
                  redirectStartPage: window.location.origin,
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          });
      } else {
        if (!isAuthenticated) {
          instance
            .loginRedirect({
              ...login,
              redirectStartPage: window.location.origin,
            })
            .catch((err) => {
              console.error(err);
            });
          instance.handleRedirectPromise().catch((err) => {
            console.error(err);
          });
        }
      }
    }
  }, [isAuthenticated, instance, inProgress, accounts]);

  const [theme, setTheme] = useState<Theme>();
  const [content, setContent] = useState<Content>();

  const getContent = (host) =>
    fetch(`/contents/${host}.json`)
      .then((res) => res.json())
      .then((res) => setContent(res));

  const getTheme = (host) =>
    fetch(`/themes/${host}.json`)
      .then((res) => res.json())
      .then((res) => setTheme(res));

  useEffect(() => {
    const host = window.location.hostname;

    // if (host == ".com") {
    //   host = window.location.hostname.split(".")[0];
    // }
    // if (process.env.NODE_ENV == "development") {
    //   // Change host to whatever theme you want to run in local e.g. coipa
    //   host = "coipa";
    // }

    getTheme(host);
    getContent(host);
  }, []);

  const navigate = useNavigate();

  return (
    <div>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <Provider store={store}>
          {isAuthenticated ? (
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onReset={() => navigate("/")}
            >
              <AppProvider theme={theme} content={content}>
                <Provider store={store}>
                  <MainRoutes />
                </Provider>
              </AppProvider>
            </ErrorBoundary>
          ) : (
            <></>
          )}
        </Provider>
      </MsalAuthenticationTemplate>
    </div>
  );
};

interface Props {
  msalInstance: PublicClientApplication;
}

const App: FC<Props> = ({ msalInstance }) => {
  return (
    <MsalProvider instance={msalInstance}>
      <AppContent />
    </MsalProvider>
  );
};

export default App;
