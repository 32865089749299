import { httpWithTokenInHeader } from "clients/api.clients.base";
import { PatientClient } from "clients/api.generated.clients";
import { Dispatch } from "react";
import { ProviderGroupActionType } from "store/providergroup/actionTypes";
import { Action } from "store/providergroup/actions";

const patientClient = new PatientClient("", httpWithTokenInHeader);

export const getAllProviderGroupNames =
  () => async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ProviderGroupActionType.GET_PROVIDER_GROUP_NAMES,
    });
    await patientClient
      .getAllProviderGroupNames()
      .then((response) => {
        if (response.success) {
          dispatch({
            type: ProviderGroupActionType.GET_PROVIDER_GROUP_NAMES_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: ProviderGroupActionType.GET_PROVIDER_GROUP_NAMES_FAILURE,
            payload: response?.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ProviderGroupActionType.GET_PROVIDER_GROUP_NAMES_FAILURE,
          payload: err.message,
        });
      });
  };
