import {
  EndSessionRequest,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { loginRequest } from "common/settings/config/AzureAuthenticationConfig";
import { msalInstance } from "index";

type TokenDecoded = {
  aud: string;
  exp: number;
  iss: string;
  tid: string;
};

const decodeToken = (token: string): TokenDecoded | undefined => {
  try {
    return token ? JSON.parse(atob(token.split(".")[1])) : undefined;
  } catch (error) {
    console.warn(error);
    return undefined;
  }
};

export const getToken = async () => {
  const account = msalInstance.getActiveAccount();
  const login = loginRequest();
  const aadConfig = localStorage.getItem("aadConfig");
  const { clientId, authority } = aadConfig && JSON.parse(aadConfig);

  if (!account) {
    throw Error(
      "No active account! Verify a user has been signed in and setActiveAccount has been called."
    );
  }

  try {
    const response = await msalInstance.acquireTokenSilent({
      account,
      ...login,
    });

    const decodedJwt = decodeToken(response.idToken);
    const expired = decodedJwt && Date.now() >= decodedJwt.exp * 1000;

    if (expired === undefined || expired) {
      localStorage.clear();
      const logOutRequest: EndSessionRequest = {
        authority: authority,
        account: account,
        postLogoutRedirectUri: "/",
      };
      msalInstance.logoutRedirect(logOutRequest);
      msalInstance
        .loginRedirect({
          ...login,
          redirectStartPage: window.location.origin,
        })
        .catch((err) => {
          console.error(err);
        });
      msalInstance.handleRedirectPromise().catch((err) => {
        console.error(err);
      });
    } else {
      return `${response.idToken}&${clientId}`;
    }
  } catch (error) {
    if (
      InteractionRequiredAuthError.isInteractionRequiredError(
        (error as any).errorCode
      )
    ) {
      const response = await msalInstance.acquireTokenPopup(login);
      return `${response.idToken}&${clientId}`;
    }
  }
};

const fetchTokenForAuthFromLocalStorage =
  (headerName: string) => async (url: RequestInfo, init?: RequestInit) => {
    const customRequestInit: RequestInit = { ...init };
    const token = await getToken();

    if (token) {
      customRequestInit.headers = customRequestInit.headers
        ? new Headers(customRequestInit.headers)
        : new Headers();
      customRequestInit.headers.append(headerName, `Bearer ${token}`);
    }
    return fetch(url, customRequestInit);
  };

export const httpWithTokenInHeader = {
  fetch: fetchTokenForAuthFromLocalStorage("Authorization"),
};
