import { httpWithTokenInHeader } from "clients/api.clients.base";
import {
  ChangesHistoryClient,
  ChangesHistoryListRequestDto,
} from "clients/api.generated.clients";
import { Dispatch } from "react";
import { ChangesHistoryActionType } from "store/changeshistory/actionTypes";
import { Action } from "store/changeshistory/actions";

const changesHistoryClient = new ChangesHistoryClient(
  "",
  httpWithTokenInHeader
);

export const getAllChangesHistory =
  (values: ChangesHistoryListRequestDto, cb?: (res) => void) =>
  async (dispatch: Dispatch<Action>) => {
    const changesHistoryListRequest = {
      ...values,
    };

    dispatch({
      type: ChangesHistoryActionType.GET_CHANGES_HISTORY,
    });
    await changesHistoryClient
      .getAllChangesHistory(changesHistoryListRequest)
      .then((response) => {
        if (response.success) {
          dispatch({
            type: ChangesHistoryActionType.GET_CHANGES_HISTORY_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: ChangesHistoryActionType.GET_CHANGES_HISTORY_FAILURE,
            payload: response?.message,
          });
        }
        cb && cb(response);
      })
      .catch((err) => {
        dispatch({
          type: ChangesHistoryActionType.GET_CHANGES_HISTORY_FAILURE,
          payload: err.message,
        });
        cb && cb(err);
      });
  };
