import { httpWithTokenInHeader } from "clients/api.clients.base";
import { PatientClient } from "clients/api.generated.clients";
import { Dispatch } from "react";
import { PayorActionType } from "store/payor/actionTypes";
import { Action } from "store/payor/actions";

const patientClient = new PatientClient("", httpWithTokenInHeader);

export const getAllPayors = () => async (dispatch: Dispatch<Action>) => {
  dispatch({
    type: PayorActionType.GET_PAYORS,
  });
  await patientClient
    .getAllPayors()
    .then((response) => {
      if (response.success) {
        dispatch({
          type: PayorActionType.GET_PAYORS_SUCCESS,
          payload: response,
        });
      } else {
        dispatch({
          type: PayorActionType.GET_PAYORS_FAILURE,
          payload: response?.message,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: PayorActionType.GET_PAYORS_FAILURE,
        payload: err.message,
      });
    });
};
