import { selectKeys } from "common/utilities/object";

export const TableFieldsName = {
  dob: "DOB",
  serviceDate: "ServiceDate",
  diagnoses: "Diagnoses",
  rxDescription: "RxDescription",
  type: "Type",
  items: "Items",
};

export const ShownFieldsName = {
  lineNumber: "LineNumber",
  reversalIndicator: "ReversalIndicator",
  benefitCode: "BenefitCode",
  placeOfService: "PlaceOfService",
  typeOfService: "TypeOfService",
  paidDate: "PaidDate",
  networkIndicator: "NetworkIndicator",
  revenueCode: "RevenueCode",
  procedureCode: "ProcedureCode",
  procedureModifiers: "ProcedureModifiers",
  billedAmount: "BilledAmount",
  eligibleAmount: "EligibleAmount",
  paidAmount: "PaidAmount",
  checkNumber: "CheckNumber",
  claimStatusCode: "ClaimStatusCode",
};
export const ShownInitialFieldsName = selectKeys(ShownFieldsName, "lineNumber", "typeOfService", "procedureCode", "procedureModifiers");

export const ShownFieldsLabel: Record<keyof typeof ShownFieldsName, string> = {
  lineNumber: "Item",
  reversalIndicator: "Reversal Indicator",
  benefitCode: "Benefit Code",
  placeOfService: "Place Of Service",
  typeOfService: "Type Of Service",
  paidDate: "Paid Date",
  networkIndicator: "Network Indicator",
  revenueCode: "Revenue Code",
  procedureCode: "Procedure Code",
  procedureModifiers: "Procedure Modifiers",
  billedAmount: "Billed Amount",
  eligibleAmount: "Eligible Amount",
  paidAmount: "Paid Amount",
  checkNumber: "Check Number",
  claimStatusCode: "Claim Status Code",
};
