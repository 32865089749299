import React from "react";
import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const UploadedReportsSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      d="M6.41659 2.74996H3.66659V5.49996H1.83325V0.916626H6.41659V2.74996ZM20.1666 5.49996V0.916626H15.5833V2.74996H18.3333V5.49996H20.1666ZM6.41659 19.25H3.66659V16.5H1.83325V21.0833H6.41659V19.25ZM18.3333 16.5V19.25H15.5833V21.0833H20.1666V16.5H18.3333ZM15.5833 5.49996H6.41659V16.5H15.5833V5.49996ZM17.4166 16.5C17.4166 17.5083 16.5916 18.3333 15.5833 18.3333H6.41659C5.40825 18.3333 4.58325 17.5083 4.58325 16.5V5.49996C4.58325 4.49163 5.40825 3.66663 6.41659 3.66663H15.5833C16.5916 3.66663 17.4166 4.49163 17.4166 5.49996V16.5ZM13.7499 7.33329H8.24992V9.16663H13.7499V7.33329ZM13.7499 10.0833H8.24992V11.9166H13.7499V10.0833ZM13.7499 12.8333H8.24992V14.6666H13.7499V12.8333Z"
      fill="#98A897"
    />
  </svg>
);

export const UploadedReportsIcon = (
  props: Partial<CustomIconComponentProps>
) => <Icon component={UploadedReportsSvg} {...props} />;
