import { ProviderGroupActionType } from "./actionTypes";
import { Action } from "./actions";

export interface ProviderGroupState {
  loading: boolean;
  error: string | undefined;
  groups: string[] | [];
}

const initialState = {
  loading: false,
  error: undefined,
  groups: [],
};

const ProviderGroupReducer = (
  state: ProviderGroupState = initialState,
  action: Action
): ProviderGroupState => {
  switch (action.type) {
    case ProviderGroupActionType.GET_PROVIDER_GROUP_NAMES:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case ProviderGroupActionType.GET_PROVIDER_GROUP_NAMES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        groups: action.payload.data,
      };

    case ProviderGroupActionType.GET_PROVIDER_GROUP_NAMES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default ProviderGroupReducer;
