import React from "react";
import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const ArrowCircleSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M7 12H18M7 12L5 10H1L3 12L1 14H5L7 12ZM18 12L16 10M18 12L16 14M17.5 22C20.538 22 23 17.523 23 12C23 6.477 20.538 2 17.5 2C14.462 2 12 6.477 12 12C12 17.523 14.462 22 17.5 22Z"
      stroke="#50834B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ArrowCircleIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ArrowCircleSvg} {...props} />
);
