export const AnalysisTabs = {
  Payor: "Payor",
  LineOfBusiness: "Line of Business",
  Clinic: "Clinic",
  Provider: "Provider",
};

export const TargetTabs = {
  Payor: "Payor",
  LineOfBusiness: "Line of Business",
  Clinic: "Clinic",
};
