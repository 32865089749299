import { Theme } from "common/interfaces/Theme";
import { useContext } from "react";
import { AppContext } from "../context/AppProvider";

const UseTheme = (): Theme => {
  const { theme } = useContext(AppContext);
  if (!theme) throw new Error("Please add App provider");

  return theme;
};

export default UseTheme;
