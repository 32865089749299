import { PaginationResponseDtoOfTargetDto } from "clients/api.generated.clients";
import { ClinicAction } from "./clinicActions";
import { ClinicTargetsActionType } from "./clinicActionTypes";
export interface TargetsState {
  loading: boolean;
  error: string | undefined;
  data: PaginationResponseDtoOfTargetDto | undefined;
  update: boolean;
}

const initialState: TargetsState = {
  loading: false,
  error: undefined,
  data: undefined,
  update: false,
};

const ClinicTargetsReducer = (
  state: TargetsState = initialState,
  action: ClinicAction
): TargetsState => {
  switch (action.type) {
    case ClinicTargetsActionType.GET_CLINIC_TARGETS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case ClinicTargetsActionType.GET_CLINIC_TARGETS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload.data,
      };

    case ClinicTargetsActionType.GET_CLINIC_TARGETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: undefined,
      };

    case ClinicTargetsActionType.SET_CLINIC_TARGETS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case ClinicTargetsActionType.SET_CLINIC_TARGETS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        update: action.payload.data,
      };

    case ClinicTargetsActionType.SET_CLINIC_TARGETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
export default ClinicTargetsReducer;
