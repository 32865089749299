// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateString = (label: any, value: string) => {
  if (!value) {
    return Promise.reject(new Error("Field is required"));
  }

  if (!value.match(/^[a-z]+$/i)) {
    return Promise.reject(new Error(`Enter only text`));
  }

  return Promise.resolve();
};

export const validateStringWithSpace = (label: any, value: string) => {
  if (!value) {
    return Promise.reject(new Error("Field is required"));
  }

  if (!value.match(/^[A-Za-z\s]*$/)) {
    return Promise.reject(new Error(`Only text and space are allowed.`));
  }

  return Promise.resolve();
};

export const validateEmail = (label: any, value: string) => {
  if (!value) {
    return Promise.reject(new Error("Field is required"));
  }

  if (
    !value.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  ) {
    return Promise.reject(new Error(`Enter valid Email Id`));
  }

  return Promise.resolve();
};

export const validateRequired = (label: any, value: string) => {
  if (!value) {
    return Promise.reject(new Error("Field is required"));
  }

  return Promise.resolve();
};

export const validateTextOnly = (label: any, value: string) => {
  if (value) {
    if (!value.match(/^[a-z]+$/i)) {
      return Promise.reject(new Error(`Enter only text`));
    }
  }

  return Promise.resolve();
};

export const validateStringWithCommas = (label: any, value: string) => {
  if (value) {
    if (!value.match(/^[a-zA-Z0-9,\s]+$/)) {
      return Promise.reject(
        new Error(
          `${value.replace(/[a-zA-Z0-9,\s]/g, "")} is not a valid character`
        )
      );
    }
  }

  return Promise.resolve();
};
