import {
  PaginationResponseDtoOfUserDto,
  UserDto,
  UserSettingsDto,
} from "clients/api.generated.clients";
import { Action } from "./actions";
import { UserActionType } from "./actionTypes";

export interface UsersState {
  loading: boolean;
  addLoading: boolean;
  updateLoading: boolean;
  rolePermissionLoading: boolean;
  error: string | undefined;
  data?: PaginationResponseDtoOfUserDto | undefined;
  user?: UserDto | null;
  add?: UserDto | null;
  update: boolean;
  userNames?: string[] | [];
  settings?: UserSettingsDto | undefined;
  updateSettings: boolean;
}

const initialState = {
  loading: false,
  addLoading: false,
  updateLoading: false,
  rolePermissionLoading: false,
  error: undefined,
  data: undefined,
  user: null,
  add: null,
  update: false,
  userNames: [],
  settings: undefined,
  updateSettings: false,
};

const UserReducer = (
  state: UsersState = initialState,
  action: Action
): UsersState => {
  switch (action.type) {
    case UserActionType.ADD_USER:
      return {
        ...state,
        addLoading: true,
        error: undefined,
      };

    case UserActionType.ADD_USER_SUCCESS:
      return {
        ...state,
        addLoading: false,
        error: undefined,
        add: { ...state.add, ...action.payload.data },
      };

    case UserActionType.ADD_USER_FAILURE:
      return {
        ...state,
        addLoading: false,
        error: action.payload,
      };

    case UserActionType.GET_USERS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case UserActionType.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload.data,
      };

    case UserActionType.GET_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UserActionType.UPDATE_USER_ROLE:
      return {
        ...state,
        updateLoading: true,
        error: undefined,
      };

    case UserActionType.UPDATE_USER_ROLE_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        error: undefined,
        update: action.payload.data,
      };

    case UserActionType.UPDATE_USER_ROLE_FAILURE:
      return {
        ...state,
        updateLoading: false,
        error: action.payload,
      };

    case UserActionType.GET_USER_NAMES:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case UserActionType.GET_USER_NAMES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        userNames: action.payload.data.items,
      };

    case UserActionType.GET_USER_NAMES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UserActionType.GET_USER_ROLES_AND_PERMISSIONS:
      return {
        ...state,
        rolePermissionLoading: true,
        error: undefined,
      };

    case UserActionType.GET_USER_ROLES_AND_PERMISSIONS_SUCCESS:
      return {
        ...state,
        rolePermissionLoading: false,
        error: undefined,
        user: action.payload.data,
      };

    case UserActionType.GET_USER_ROLES_AND_PERMISSIONS_FAILURE:
      return {
        ...state,
        rolePermissionLoading: false,
        error: action.payload,
        user: undefined,
      };

    case UserActionType.UPDATE_USER_SETTINGS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case UserActionType.UPDATE_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        updateSettings: action.payload.data,
      };

    case UserActionType.UPDATE_USER_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UserActionType.GET_USER_SETTINGS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case UserActionType.GET_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        settings: action.payload.data,
      };

    case UserActionType.GET_USER_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UserActionType.SET_USER_SETTINGS:
      return {
        ...state,
        settings: { ...state.settings, ...action.payload },
      };

    default:
      return state;
  }
};

export default UserReducer;
