import { Col, List, Modal, Row, Table, Typography } from "antd";
import React, { FC, useEffect, useState } from "react";
import {
  DownOutlined,
  LeftOutlined,
  LoadingOutlined,
  RightOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { ColumnsType, TableRowSelection } from "antd/es/table/interface";
import CustomButton from "common/components/button/CustomButton";
import { UserListConstants } from "./UserList.constant";
import UpdateUserRole from "../update-user-role/UpdateUserRole";
import { useActions } from "hooks/useActions";
import { useTypedSelector } from "hooks/useTypedSelector";
import { UserDto } from "clients/api.generated.clients";
import { useNavigate } from "react-router-dom";
import { PageRoutesConstant } from "common/router/PageRoutes.constant";
import { Permissions } from "common/constants/Permissions";
import { hasAccessByPermissions } from "hooks/useAccess";
import "./UserList.style.scss";

interface DataType {
  key: string;
  user: string;
  name: number;
  role: string;
}

const { Title } = Typography;

const UserList: FC = () => {
  const navigate = useNavigate();
  const { getUsers } = useActions();
  const { users } = useTypedSelector((state) => state);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [expanded, setExpanded] = useState<string>();
  const [userList, setUserList] = useState([]);
  const hasAccess = hasAccessByPermissions(Permissions.UserList);

  useEffect(() => {
    if (!hasAccess) {
      navigate(PageRoutesConstant.Page.AccessDenied.path, {
        replace: true,
      });
    }
    getUsers({});
  }, []);

  const onSelectChange = (selectedRowKeys: string[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection: TableRowSelection<DataType> = {
    onChange: onSelectChange,
    columnTitle: "Select",
  };

  const expand = (index: string) => {
    if (expanded === index) setExpanded(undefined);
    else setExpanded(index);
  };

  useEffect(() => {
    setUserList(
      Object.values(users?.data?.items ?? [])?.map((user: UserDto) => ({
        key: user.id,
        user: user.userName,
        role: user.roles?.join(", "),
        permissions: user?.permissions,
      })) as []
    );
  }, [users]);

  const columns: ColumnsType<DataType> = [
    { title: "User", dataIndex: "user", key: "user" },
    { title: "Role(s)", dataIndex: "role", key: "role", width: 300 },
    {
      title: "Permissions",
      key: "permissions",
      responsive: ["lg"],
      render: (_, record, index) => {
        return (
          <a onClick={() => expand(record.key)} className="userlist_viewaction">
            View{" "}
            {record.key === expanded ? (
              <UpOutlined className="ms-2" />
            ) : (
              <DownOutlined className="ms-2" />
            )}
          </a>
        );
      },
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onUpdatePopupClose = () => {
    handleCancel();
    getUsers({});
  };

  const handleTableChange: any = (pagination, filters, sorter) => {
    getUsers({
      page: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  return (
    <div className="userlist">
      <Row>
        <Col xs={12}>
          <Title level={3} className="userlist_title">
            {UserListConstants.PageTitle}
          </Title>
        </Col>
        <Col xs={12} className="userlist_btn">
          <CustomButton
            className="userlist_btn_modify_selected"
            text={UserListConstants.ModifyButtonText}
            fontColor="rgba(0, 0, 0, 0.25)"
            font={16}
            width={163}
            height={40}
            radius={8}
            disabled={selectedRowKeys.length === 0}
            onClick={showModal}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Table
            scroll={{ x: "100" }}
            rowSelection={rowSelection}
            className="userlist_table"
            columns={columns}
            expandable={{
              expandedRowRender: (record) => (
                <Row justify="end">
                  <Col xs={5} offset={19}>
                    <List
                      itemLayout="horizontal"
                      className="userlist_permissionlist"
                      dataSource={record.permissions}
                      renderItem={(item: string) => (
                        <List.Item className="userlist_permissionlist_item">
                          <List.Item.Meta
                            title={`- ${item}`}
                            className="userlist_permissionlist_item_meta text-start"
                          />
                        </List.Item>
                      )}
                    />
                  </Col>
                </Row>
              ),
              rowExpandable: (record) => true,
              expandIcon: () => <></>,
              expandedRowKeys: [expanded],
              showExpandColumn: false,
            }}
            dataSource={userList}
            pagination={{
              total: users?.data?.totalCount,
              hideOnSinglePage: true,
              responsive: true,
              prevIcon: (
                <CustomButton
                  iconPosition="left"
                  icon={<LeftOutlined />}
                  className="userlist_custom_pagination_btn prev"
                  text="Prev"
                  font={14}
                  radius={8}
                  width={111}
                />
              ),
              nextIcon: (
                <CustomButton
                  iconPosition="right"
                  icon={<RightOutlined />}
                  className="userlist_custom_pagination_btn next"
                  text="Next"
                  font={14}
                  radius={8}
                  width={111}
                />
              ),
            }}
            loading={{
              size: "large",
              tip: "Loading...",
              spinning: users.loading,
              indicator: <LoadingOutlined />,
            }}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
      <div>
        <Modal
          destroyOnClose={true}
          open={isModalOpen}
          onCancel={handleCancel}
          centered
          closable={true}
          footer={null}
        >
          <UpdateUserRole
            userIds={selectedRowKeys}
            onPopupClose={onUpdatePopupClose}
          />
        </Modal>
      </div>
    </div>
  );
};

export default UserList;
