import {
  Button,
  Checkbox,
  Col,
  Form,
  FormInstance,
  InputNumber,
  Row,
  Select,
  SelectProps,
  Switch,
  Table,
  Tabs,
  Typography,
  message,
} from "antd";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTypedSelector } from "hooks/useTypedSelector";
import {
  Measure,
  TargetDto,
  TargetListRequestDto,
} from "clients/api.generated.clients";
import { useActions } from "hooks/useActions";
import { ColumnsType } from "antd/es/table";
import { SorterResult } from "antd/es/table/interface";
import { TargetTabs } from "common/constants/Tabs";
import {
  LeftOutlined,
  LoadingOutlined,
  RightOutlined,
} from "@ant-design/icons";
import CustomButton from "common/components/button/CustomButton";
import { TargetsConstant } from "./Targets.constant";
import "./Targets.style.scss";
import { hasAccessByPermissions } from "hooks/useAccess";
import { Permissions } from "common/constants/Permissions";

const { Text, Title } = Typography;

type TargetRow = TargetDto & { key: string };

type FilterFormValues = {
  measure?: Measure;
  payors?: string[];
  lineOfBusinessGroup?: string;
  clinics?: string[];
  providers: string[];
};

const tabs = [
  { key: TargetTabs.Payor, label: "Payor View" },
  { key: TargetTabs.LineOfBusiness, label: "Line of Business View" },
  { key: TargetTabs.Clinic, label: "Clinic View" },
];

const measureOptions = [
  { value: Measure.AWV, label: "AWV" },
  { value: Measure.A1C, label: "A1C" },
];

const EmptyFormFields: FilterFormValues = {
  measure: undefined,
  payors: undefined,
  lineOfBusinessGroup: undefined,
  clinics: undefined,
  providers: undefined,
};

const Targets: FC = () => {
  const hasAccess = hasAccessByPermissions(Permissions.EditTargets);
  const {
    getPayorTargets,
    setPayorTarget,
    getLineOfBusinessTargets,
    setLineOfBusinessTarget,
    getClinicTargets,
    setClinicTarget,
  } = useActions();
  const [targetListRequest, setTargetListRequest] =
    useState<TargetListRequestDto>({
      measure: Measure.AWV,
      lineOfBusinessGroup: undefined,
      page: 1,
      pageSize: 10,
    });
  const [currentTab, setCurrentTab] = useState<string>(TargetTabs.Payor);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<TargetRow>>({});
  const [clinicTargetCustomValues, setClinicTargetCustomValues] = useState<
    string[]
  >([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [filterForm] = Form.useForm<FilterFormValues>();

  const payorTargets = useTypedSelector((state) => state.payorTargets);
  const lineofBusinessTargets = useTypedSelector(
    (state) => state.linofBusinessTargets
  );
  const clinicTargets = useTypedSelector((state) => state.clinicTargets);

  useEffect(() => onResetAllFilter(), []);

  useEffect(() => {
    if (currentTab === TargetTabs.Payor) {
      getPayorTargets(targetListRequest);
    } else if (currentTab === TargetTabs.LineOfBusiness) {
      getLineOfBusinessTargets({
        ...targetListRequest,
        lineOfBusinessGroup: filterForm.getFieldValue("lineOfBusinessGroup"),
      });
    } else if (currentTab === TargetTabs.Clinic) {
      getLineOfBusinessTargets(
        {
          page: -1,
          pageSize: -1,
          measure: targetListRequest.measure,
        },
        (res) => {
          if (res.success) {
            const currentValue =
              filterForm.getFieldValue("lineOfBusinessGroup") ||
              res?.data?.items?.[0]?.name;
            filterForm.setFieldValue("lineOfBusinessGroup", currentValue);
            getClinicTargets({
              ...targetListRequest,
              lineOfBusinessGroup: filterForm.getFieldValue(
                "lineOfBusinessGroup"
              ),
            });
          }
        }
      );
    }
  }, [targetListRequest]);

  const targetsViewData = useMemo<TargetDto[] | undefined>(() => {
    let targetItems: TargetDto[] | undefined;

    switch (currentTab) {
      case TargetTabs.Payor:
        targetItems = payorTargets?.data?.items;
        break;
      case TargetTabs.LineOfBusiness:
        targetItems = lineofBusinessTargets?.data?.items;
        break;
      case TargetTabs.Clinic:
        targetItems = clinicTargets?.data?.items;
        setClinicTargetCustomValues(
          targetItems?.filter((item) => item.isCustom).map((item) => item.name)
        );
        break;
      default:
        targetItems = [];
    }

    return targetItems?.map((target: TargetDto) => ({
      key: target.name,
      ...target,
    }));
  }, [payorTargets?.data, lineofBusinessTargets?.data, clinicTargets?.data]);

  useEffect(() => {
    if (filterForm.getFieldValue("measure")) {
      const currentValue =
        filterForm.getFieldValue("lineOfBusinessGroup") ||
        lineofBusinessTargets?.data?.items?.[0]?.name;

      filterForm.setFieldValue("lineOfBusinessGroup", currentValue);
    }
  }, [currentTab === TargetTabs.Clinic]);

  const handleTargetInputBlur = (val: number, record: TargetRow) => {
    if (record.target !== val) {
      if (currentTab === TargetTabs.Payor) {
        setPayorTarget(
          {
            payor: record.name,
            target: val,
            measure: targetListRequest.measure,
          },
          (res) => {
            if (res.success) {
              getPayorTargets(targetListRequest);
            }
            if (res.errors) {
              const errors = res.errors;

              if (errors && Object.keys(errors).length > 0) {
                const errorKey = Object.keys(errors)[0];
                const errorArray = errors[errorKey];

                if (errorArray && errorArray.length > 0) {
                  message.error(errorArray[0]);
                }
              }
            }
          }
        );
      } else if (currentTab === TargetTabs.LineOfBusiness) {
        setLineOfBusinessTarget(
          {
            lineOfBusiness: record.name,
            target: val,
            measure: targetListRequest?.measure,
          },
          (res) => {
            if (res.success) {
              getLineOfBusinessTargets(targetListRequest);
            }
            if (res.errors) {
              const errors = res.errors;

              if (errors && Object.keys(errors).length > 0) {
                const errorKey = Object.keys(errors)[0];
                const errorArray = errors[errorKey];

                if (errorArray && errorArray.length > 0) {
                  message.error(errorArray[0]);
                }
              }
            }
          }
        );
      }
    }
    if (
      currentTab === TargetTabs.Clinic &&
      record[
        `${filterForm
          .getFieldValue("lineOfBusinessGroup")
          ?.toLowerCase()}Target`
      ] !== val
    ) {
      record[
        `${filterForm
          .getFieldValue("lineOfBusinessGroup")
          ?.toLowerCase()}Target`
      ] = val;
      record.isCustom = clinicTargetCustomValues.includes(record.name);
      setClinicTarget(
        { ...record, clinic: record.name, measure: targetListRequest.measure },
        (res) => {
          if (res.success) {
            getClinicTargets({
              ...targetListRequest,
              lineOfBusinessGroup: filterForm.getFieldValue(
                "lineOfBusinessGroup"
              ),
            });
          }
          if (res.errors) {
            const errors = res.errors;

            if (errors && Object.keys(errors).length > 0) {
              const errorKey = Object.keys(errors)[0];
              const errorArray = errors[errorKey];

              if (errorArray && errorArray.length > 0) {
                message.error(errorArray[0]);
              }
            }
          }
        }
      );
    }
  };

  const getSortOrder = (columnKey: string) => {
    if (currentTab === TargetTabs.Payor) {
      return !payorTargets.loading && sortedInfo.columnKey === columnKey
        ? sortedInfo.order
        : null;
    } else if (currentTab === TargetTabs.LineOfBusiness) {
      return !lineofBusinessTargets.loading &&
        sortedInfo.columnKey === columnKey
        ? sortedInfo.order
        : null;
    } else {
      return !clinicTargets.loading && sortedInfo.columnKey === columnKey
        ? sortedInfo.order
        : null;
    }
  };

  const handleCheck = (value?: boolean, name?: string) => {
    if (value) {
      setClinicTargetCustomValues([...clinicTargetCustomValues, name]);
      targetsViewData.map((item) => {
        if (item.name === name) {
          item.isCustom = value;
        }
        return item;
      });
    } else {
      const filterCustomValue = clinicTargetCustomValues.filter(
        (val) => val !== name
      );
      setClinicTargetCustomValues(filterCustomValue);
      setClinicTarget(
        { clinic: name, isCustom: value, measure: targetListRequest.measure },
        (res) => {
          if (res.success) {
            getClinicTargets(targetListRequest);
          }
        }
      );
    }
  };

  const columns: ColumnsType<TargetRow> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: targetsViewData?.length > 0 ? true : false,
      sortOrder: getSortOrder("name"),
      render: (_, record, index) => {
        return <Text>{record.name}</Text>;
      },
    },
    {
      title:
        currentTab === TargetTabs.Clinic ? (
          <Title level={5} className="d-flex align-items-center">
            {filterForm.getFieldValue("lineOfBusinessGroup")}
            {filterForm.getFieldValue("lineOfBusinessGroup") && (
              <>
                <div className="orange-box p-2 mx-2">
                  {lineofBusinessTargets?.data?.items.find(
                    (lob) =>
                      lob.name ===
                      filterForm.getFieldValue("lineOfBusinessGroup")
                  )?.target || 0}
                </div>
                %
              </>
            )}
          </Title>
        ) : (
          <></>
        ),
      dataIndex: "target",
      key: "target",
      align: "center",
      render: (_, record, index) => (
        <div className="d-flex align-items-center">
          <InputNumber
            key={`in_${
              targetListRequest?.lineOfBusinessGroup
            }_${record.key.replaceAll(" ", "_")}`}
            size="large"
            required={true}
            className="me-2 targets_number"
            value={
              currentTab === TargetTabs.Clinic
                ? record[
                    `${filterForm
                      .getFieldValue("lineOfBusinessGroup")
                      ?.toLowerCase()}Target`
                  ]
                : record.target
            }
            readOnly={
              currentTab === TargetTabs.Clinic
                ? isEditMode
                  ? !clinicTargetCustomValues.includes(record.name)
                  : true
                : !isEditMode
            }
            onBlur={
              isEditMode
                ? (e) =>
                    handleTargetInputBlur(parseFloat(e.target.value), record)
                : undefined
            }
          />
          %
          {currentTab === TargetTabs.Clinic && (
            <Checkbox
              className="ms-3"
              key={`chk_${
                targetListRequest?.lineOfBusinessGroup
              }_${record.key.replaceAll(" ", "_")}`}
              checked={record.isCustom}
              disabled={!isEditMode}
              onChange={(e) => handleCheck(e.target.checked, record.name)}
            >
              Custom
            </Checkbox>
          )}
        </div>
      ),
    },
      ];

  const handleTableChange: any = (
    pagination,
    filters,
    sorter: SorterResult<TargetRow>
  ) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
    setSortedInfo(sorter);
    setTargetListRequest({
      ...targetListRequest,
      sortBy: sorter.column ? sorter.columnKey.toString() : undefined,
      sortOrderAsc: sorter.order === "ascend",
      page: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const onResetAllFilter = () => {
    setCurrentPage(1);
    setPageSize(10);
    setSortedInfo({});
    filterForm.setFieldsValue(EmptyFormFields);
    filterForm.setFieldValue("measure", Measure.AWV);
    setTargetListRequest({
      ...targetListRequest,
      measure: Measure.AWV,
      payors: undefined,
      lineOfBusinessGroup: undefined,
      clinics: undefined,
      providers: undefined,
      sortBy: undefined,
      sortOrderAsc: undefined,
      page: 1,
      pageSize: pageSize,
    });
    setIsEditMode(false);
  };

  const onApplyFilter = () => {
    filterForm
      .validateFields()
      .then((values) => {
        setTargetListRequest({
          ...targetListRequest,
          measure: values.measure,
          payors: values.payors,
          lineOfBusinessGroup: values.lineOfBusinessGroup,
          clinics: values.clinics,
          providers: values.providers,
          sortBy: undefined,
          sortOrderAsc: undefined,
          page: 1,
          pageSize: pageSize,
        });
        setCurrentPage(1);
      })
      .catch((info) => {
        console.log("Filter Validate Failed:", info);
      });
  };

  const handleDeselect = () => {
    filterForm
      .validateFields()
      .then((values) => {
        setTargetListRequest({
          ...targetListRequest,
          measure: values.measure,
          payors: values.payors,
          lineOfBusinessGroup: values.lineOfBusinessGroup,
          clinics: values.clinics,
          providers: values.providers,
        });
      })
      .catch((info) => {
        console.log("Filter Validate Failed:", info);
      });
  };

  const handleTabChange = (tab: string) => {
    setCurrentTab(tab);
    setTargetListRequest({
      ...targetListRequest,
      page: 1,
      pageSize: 10,
    });
  };

  return (
    <div className="page_layout targets">
      <Row>
        <Col xs={24}>
          <Title className="targets_title" level={2}>
            Targets
          </Title>
        </Col>
      </Row>
      <br />
      <Row gutter={[24, 10]}>
        <Col xs={24} md={6} xl={5}>
          <Filters
            form={filterForm}
            onApplyFilter={onApplyFilter}
            onResetAllFilter={onResetAllFilter}
            handleDeselect={handleDeselect}
          />
        </Col>
        <Col xs={24} md={18} xl={19}>
          <Tabs
            size="large"
            type="card"
            className="targets_tabs"
            defaultActiveKey={currentTab}
            onChange={(value) => handleTabChange(value)}
            items={tabs.map((tab) => {
              return {
                label: tab.label,
                key: tab.key,
                children: (
                  <div className="targets_tabs_table">
                    <Table
                      dataSource={targetsViewData}
                      columns={columns}
                      showSorterTooltip={false}
                      title={() => (
                        <Row
                          align="middle"
                          className="d-flex align-items-center"
                          gutter={[0, { xs: 10 }]}
                        >
                          <Col
                            xs={24}
                            sm={12}
                            md={18}
                            className="d-flex align-items-center justify-content-center justify-content-sm-start"
                          >
                            <Title level={5} className="mb-0">
                              AWV Targets
                            </Title>
                          </Col>
                          {hasAccess && targetsViewData?.length > 0 && (
                            <Col xs={24} sm={12} md={6}>
                              <Row
                                align="middle"
                                justify={{ xs: "center", sm: "end" }}
                                gutter={[10, 0]}
                              >
                                <Col className="d-flex align-items-center justify-content-end">
                                  <Title level={5} className="mb-0">
                                    Edit Mode
                                  </Title>
                                  <Switch
                                    checked={isEditMode}
                                    className="ms-2"
                                    onChange={() => setIsEditMode(!isEditMode)}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          )}
                        </Row>
                      )}
                      loading={{
                        size: "large",
                        tip: "Loading...",
                        spinning:
                          currentTab === TargetTabs.Payor
                            ? payorTargets?.loading
                            : currentTab === TargetTabs.LineOfBusiness
                            ? lineofBusinessTargets?.loading
                            : clinicTargets?.loading,
                        indicator: <LoadingOutlined />,
                      }}
                      onChange={handleTableChange}
                      pagination={{
                        current: currentPage,
                        hideOnSinglePage: true,
                        total:
                          currentTab === TargetTabs.Payor
                            ? payorTargets?.data?.totalCount
                            : currentTab === TargetTabs.LineOfBusiness
                            ? lineofBusinessTargets?.data?.totalCount
                            : clinicTargets?.data?.totalCount,
                        pageSize: pageSize,
                        prevIcon: (
                          <CustomButton
                            iconPosition="left"
                            icon={<LeftOutlined />}
                            className="targets_tabs_table_custom_pagination_btn prev"
                            text={TargetsConstant.PrevButtonText}
                            font={16}
                            radius={8}
                            width={111}
                          />
                        ),
                        nextIcon: (
                          <CustomButton
                            iconPosition="right"
                            icon={<RightOutlined />}
                            className="targets_tabs_table_custom_pagination_btn next"
                            text={TargetsConstant.NextButtonText}
                            font={16}
                            radius={8}
                            width={111}
                          />
                        ),
                      }}
                      scroll={{ x: "auto" }}
                    />
                  </div>
                ),
              };
            })}
          ></Tabs>
        </Col>
      </Row>
    </div>
  );
};

type FiltersProps = {
  form: FormInstance;
  onApplyFilter: () => void;
  onResetAllFilter: () => void;
  handleDeselect: () => void;
};

const Filters: FC<FiltersProps> = ({
  form,
  onApplyFilter,
  onResetAllFilter,
  handleDeselect,
}) => {
  const {
    getAllPayors,
    getAllLineOfBusinessGroups,
    getAllProviderGroupNames,
    getProviders,
  } = useActions();
  const payors = useTypedSelector((state) => state.payors);
  const lineOfBusiness = useTypedSelector((state) => state.lineOfBusiness);
  const providerGroup = useTypedSelector((state) => state.providerGroup);
  const providers = useTypedSelector((state) => state.provider);

  useEffect(() => {
    getAllPayors();
    getAllLineOfBusinessGroups();
    getAllProviderGroupNames();
    getProviders();
  }, []);

  const payorOptions = useMemo<SelectProps["options"]>(
    () =>
      payors?.data.map((payor) => ({
        label: payor,
        value: payor,
      })),
    [payors?.data]
  );

  const lineOfBusinessOptions = useMemo<SelectProps["options"]>(
    () =>
      lineOfBusiness?.groups.map((lob) => ({
        label: lob,
        value: lob,
      })),
    [lineOfBusiness?.groups]
  );

  const clinicOptions = useMemo<SelectProps["options"]>(
    () =>
      providerGroup?.groups.map((group) => ({
        label: group,
        value: group,
      })),
    [providerGroup?.groups]
  );

  const providerOptions = useMemo<SelectProps["options"]>(
    () =>
      providers?.data.map((provider) => ({
        label: provider,
        value: provider,
      })),
    [providers?.data]
  );

  return (
    <div className="targets_box">
      <div className="targets_box_filter">
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Text className="targets_box_text">
              Choose parameters to set targets:
            </Text>
          </Col>
          <Col span={24}>
            <Form form={form}>
              <Row gutter={[7, 12]}>
                <Col span={24}>
                  <Title level={5} className="mb-0 targets_box_filter_title">
                    Measures
                  </Title>
                  <Form.Item
                    className="mb-0"
                    name="measure"
                    initialValue={[Measure.AWV]}
                  >
                    <Select
                      size="large"
                      placeholder="Select a Measure"
                      allowClear
                      popupClassName="targets_box_filter_options_popup"
                      options={measureOptions}
                      onClear={handleDeselect}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Title level={5} className="mb-0 targets_box_filter_title">
                    Payors
                  </Title>
                  <Form.Item className="mb-0" name="payors">
                    <Select
                      size="large"
                      placeholder="Select a Payors"
                      allowClear
                      popupClassName="targets_box_filter_options_popup"
                      options={payorOptions}
                      mode="multiple"
                      onDeselect={handleDeselect}
                      onClear={handleDeselect}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Title level={5} className="mb-0 targets_box_filter_title">
                    Line of Businesses
                  </Title>
                  <Form.Item className="mb-0" name="lineOfBusinessGroup">
                    <Select
                      size="large"
                      placeholder="Select a Line of Businesses"
                      allowClear
                      popupClassName="targets_box_filter_options_popup"
                      options={lineOfBusinessOptions}
                      onClear={handleDeselect}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Title level={5} className="mb-0 targets_box_filter_title">
                    Clinics
                  </Title>
                  <Form.Item className="mb-0" name="clinics">
                    <Select
                      size="large"
                      placeholder="Select a Clinics"
                      allowClear
                      popupClassName="targets_box_filter_options_popup"
                      options={clinicOptions}
                      mode="multiple"
                      onDeselect={handleDeselect}
                      onClear={handleDeselect}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Title level={5} className="mb-0 targets_box_filter_title">
                    Providers
                  </Title>
                  <Form.Item className="mb-0" name="providers">
                    <Select
                      size="large"
                      placeholder="Select a Providers"
                      allowClear
                      popupClassName="targets_box_filter_options_popup"
                      options={providerOptions}
                      mode="multiple"
                      onDeselect={handleDeselect}
                      onClear={handleDeselect}
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>
              <br />
              <Row gutter={[10, 10]}>
                <Col span={12}>
                  <Button
                    type="default"
                    size="large"
                    className="targets_box_filter_custom_button"
                    onClick={onResetAllFilter}
                  >
                    Reset All
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    type="primary"
                    size="large"
                    className="targets_box_filter_custom_button"
                    onClick={onApplyFilter}
                  >
                    Apply
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Targets;
