import { useEffect, useState } from "react";

interface ResponsiveState {
  windowWidth: number;
  isMobile: boolean;
}

const useResponsive = (): ResponsiveState => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleResize = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
    setIsMobile(width < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { windowWidth, isMobile };
};

export default useResponsive;
