import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Dropdown,
  Form,
  FormInstance,
  List,
  MenuProps,
  Popover,
  Row,
  Select,
  Switch,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import React, { FC, useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import CustomButton from "common/components/button/CustomButton";
import {
  DownOutlined,
  LeftOutlined,
  LoadingOutlined,
  MenuOutlined,
  RightOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { PatientsConstant } from "./Patients.constant";
import useResponsive from "hooks/useResponsive";
import { A1CValues } from "common/constants/FilterOptions";
import {
  DATE_FORMAT_DEFAULT,
  FormatDataDate,
  FormatDate,
  ParseDayJsDate,
} from "common/utilities/format";
import { validateRequired } from "hooks/validator";
import {
  ShownFieldsLabel,
  ShownFieldsName,
  TableColumns,
  TableFieldsName,
} from "common/constants/ShownFields";
import { useActions } from "hooks/useActions";
import { useTypedSelector } from "hooks/useTypedSelector";
import {
  TimeRange,
  PatientDto,
  PatientListRequestDto,
  PatientFilterOptionsDto,
  UserSettingsDto,
  PatientKeyDto,
} from "clients/api.generated.clients";
import { SortOrder, SorterResult } from "antd/es/table/interface";
import { Messages } from "common/constants/Messages";
import { PageRoutesConstant } from "common/router/PageRoutes.constant";
import { useNavigate } from "react-router-dom";
import { DownloadFile } from "common/utilities/converter";
import { InfoIcon, SliderIcon, WriteIcon } from "assets/icons";
import { ReportRoutesConstant } from "common/router/ReportRoutesConstant";
import dayjs from "dayjs";
import PatientSearch from "./search/PatientSearch";
import { useDebounced } from "hooks/useDebounced";
import "./Patients.style.scss";

const { Title, Paragraph, Text } = Typography;
const { Option } = Select;

const LastUpdatedOptions = [
  { value: TimeRange.LastWeek, label: "Within 1 Week" },
  { value: TimeRange.LastMonth, label: "Within 1 Month" },
  { value: TimeRange.LastQuarter, label: "Within 1 Quarter" },
  { value: TimeRange.LastYear, label: "Within 1 Year" },
  { value: TimeRange.CustomRange, label: "Custom Range" },
];

const WellnessVisitOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
  { value: "Show All", label: "Show All" },
];

const LastVisitOptions = [
  { value: TimeRange.LastWeek, label: "Within 1 Week" },
  { value: TimeRange.LastMonth, label: "Within 1 Month" },
  { value: TimeRange.LastQuarter, label: "Within 1 Quarter" },
  { value: TimeRange.LastYear, label: "Within 1 Year" },
  { value: TimeRange.CustomRange, label: "Custom Range" },
];

type FilterFormValues = {
  payor?: string;
  lastUpdated?: TimeRange;
  lastUpdatedStartDate?: dayjs.Dayjs;
  lastUpdatedEndDate?: dayjs.Dayjs;
  wellnessVisitThisYear?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: dayjs.Dayjs;
  lastVisit?: TimeRange;
  lastVisitStartDate?: dayjs.Dayjs;
  lastVisitEndDate?: dayjs.Dayjs;
  a1cValues?: string;
  lineOfBusinessGroup?: string;
  providerGroupName?: string;
};

type PatientReportRow = PatientDto & {
  key: string;
  claimsWithin3Years: string;
  view: string;
};

const EmptyFormFields: FilterFormValues = {
  lastUpdated: undefined,
  lastUpdatedStartDate: undefined,
  lastUpdatedEndDate: undefined,
  a1cValues: undefined,
  wellnessVisitThisYear: undefined,
  payor: undefined,
  firstName: undefined,
  lastName: undefined,
  dateOfBirth: undefined,
  lastVisit: undefined,
  lastVisitStartDate: undefined,
  lastVisitEndDate: undefined,
  lineOfBusinessGroup: undefined,
  providerGroupName: undefined,
};

const Patients: FC = () => {
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  const {
    getAllPatients,
    setUserSettings,
    getUserSettingsByUserName,
    updateUserSettings,
    exportData,
  } = useActions();
  const getAllPatientsDebounced = useDebounced(200, getAllPatients);

  const patients = useTypedSelector((state) => state.patients);
  const users = useTypedSelector((state) => state.users);
  const [filterForm] = Form.useForm<FilterFormValues>();
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});

  const [shownFields, setShownFields] = useState<string[]>(
    Array.from(
      new Set(
        Object.values(TableFieldsName).concat(
          users?.settings?.patientShownFields
            ? users.settings.patientShownFields.filter((item) =>
                Object.values(ShownFieldsName).includes(item)
              )
            : Object.values(ShownFieldsName)
        )
      )
    ) ||
      Array.from(
        new Set(
          Object.values(TableFieldsName).concat(Object.values(ShownFieldsName))
        )
      )
  );

  const [shownColumns, setShownColumns] = useState<string[]>(
    users?.settings?.patientShownColumns
      ? users?.settings?.patientShownColumns.filter((item) =>
          TableColumns.map((item) => item.dataIndex).includes(item)
        )
      : TableColumns.map((item) => item.dataIndex)
  );

  const [patientListRequest, setPatientListRequest] =
    useState<PatientListRequestDto>(() => {
      const patientFilterSettings = users?.settings?.patientFilterOptions;
      return {
        shownFields: users?.settings?.patientShownFields || shownFields,
        payor: patientFilterSettings?.payor,
        lastUpdated: patientFilterSettings?.lastUpdated,
        wellnessVisitThisYear: patientFilterSettings?.wellnessVisitThisYear,
        lastUpdatedStartDate: patientFilterSettings?.lastUpdatedStartDate,
        lastUpdatedEndDate: patientFilterSettings?.lastUpdatedEndDate,
        sortBy: users?.settings?.patientSortOptions?.sortBy,
        sortOrderAsc:
          (users?.settings?.patientSortOptions?.sortOrder as SortOrder) ===
          "ascend",
        page: 1,
        pageSize: 10,
        firstName: patientFilterSettings?.firstName,
        lastName: patientFilterSettings?.lastName,
        dateOfBirth: FormatDataDate(patientFilterSettings?.dateOfBirth),
        lastVisit: patientFilterSettings?.lastVisit,
        lastVisitStartDate: patientFilterSettings?.lastVisitStartDate,
        lastVisitEndDate: patientFilterSettings?.lastVisitEndDate,
        lineOfBusinessGroup: patientFilterSettings?.lineOfBusinessGroup,
        providerGroupName: patientFilterSettings?.providerGroupName,
      };
    });

  const [updatesMade, setUpdatesMade] = useState<boolean>(false);

  const [resetAllDisable, setResetAllDisable] = useState<boolean>(false);

  const handleModifyShownFields = (value: string[]) => {
    setResetAllDisable(false);
    setShownFields(
      Array.from(new Set(Object.values(TableFieldsName).concat(value)))
    );
    setUserSettings({
      ...users?.settings,
      patientShownFields: Array.from(
        new Set(Object.values(TableFieldsName).concat(value))
      ),
    });
    setPatientListRequest({
      ...patientListRequest,
      shownFields: Array.from(
        new Set(Object.values(TableFieldsName).concat(value))
      ),
    });
  };

  const [expandedViewKey, setExpandedViewKey] = useState<string>();
  const [expandedNameKey, setExpandedNameKey] = useState<string>();
  const [reportList, setReportList] = useState<PatientReportRow[]>([]);

  const expandView = (key: string) => {
    if (expandedViewKey === key) setExpandedViewKey(undefined);
    else setExpandedViewKey(key);
  };

  const expandName = (key: string) => {
    if (expandedNameKey === key) setExpandedNameKey(undefined);
    else setExpandedNameKey(key);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const handleTableChange: any = (pagination, filters, sorter) => {
    setResetAllDisable(false);
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
    setSortedInfo(sorter as SorterResult<any>);
    setUserSettings({
      ...users?.settings,
      patientSortOptions: {
        sortBy: sorter.column ? sorter.columnKey : undefined,
        sortOrder: sorter.order,
      },
    });
    setPatientListRequest({
      ...patientListRequest,
      sortBy: sorter.column ? sorter.columnKey : undefined,
      sortOrderAsc: sorter.order === "ascend",
      page: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const [selectedPatientSearch, setSelectedPatientSearch] =
    useState<PatientKeyDto>(() => {
      if (!patientListRequest?.memberId) {
        return undefined;
      }
      return {
        memberId: patientListRequest?.memberId,
        firstName: patientListRequest?.firstName,
        lastName: patientListRequest?.lastName,
      };
    });
  const updateSelectedPatientSearch = (selectedResult?: PatientKeyDto) => {
    setSelectedPatientSearch(selectedResult);
    setPatientListRequest({
      ...patientListRequest,
      memberId: selectedResult?.memberId,
      firstName: selectedResult?.firstName,
      lastName: selectedResult?.lastName,
      page: 1,
      pageSize: 10,
    });
    setCurrentPage(1);
  };

  const getSortOrder = (columnKey: string) => {
    return !users.loading && sortedInfo.columnKey === columnKey
      ? sortedInfo.order
      : null;
  };

  const columns: ColumnsType<PatientReportRow> = [
    {
      title: isMobile ? (
        "Name"
      ) : (
        <span
          onClick={(e) => {
            e.stopPropagation();
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <PatientSearch
            selectedResult={selectedPatientSearch}
            updateSelectedResult={updateSelectedPatientSearch}
          />
        </span>
      ),
      dataIndex: "fullName",
      key: "fullName",
      sorter: reportList.length > 0 ? true : false,
      sortOrder: getSortOrder("fullName"),
      ellipsis: true,
      width: 300,
      render: (_, record, index) => {
        return isMobile ? (
          <Text className="patients_cell_text">{record.fullName}</Text>
        ) : (
          <Text
            onClick={() => expandName(record.key)}
            className="patients_viewaction"
          >
            {record.key === expandedNameKey ? (
              <UpOutlined className="me-2" />
            ) : (
              <DownOutlined className="me-2" />
            )}{" "}
            {record.fullName}
          </Text>
        );
      },
    },
    {
      title: "DOB",
      dataIndex: "birthDate",
      key: "birthDate",
      sorter: reportList.length > 0 ? true : false,
      sortOrder: getSortOrder("birthDate"),
      render: (_, record, index) => {
        return (
          <Text className="patients_cell_text">
            {FormatDate(record.birthDate, isMobile)}
          </Text>
        );
      },
    },
    {
      title: "Provider Group Name",
      dataIndex: "providerGroupName",
      key: "providerGroupName",
      sorter: reportList.length > 0 ? true : false,
      sortOrder: getSortOrder("providerGroupName"),
      responsive: ["md"],
    },
    {
      title: "Payor",
      dataIndex: "payor",
      key: "payor",
      sorter: reportList.length > 0 ? true : false,
      sortOrder: getSortOrder("payor"),
      ellipsis: true,
      responsive: ["md"],
    },
    {
      title: "Line of Business",
      dataIndex: "lineOfBusinessGroup",
      key: "lineOfBusinessGroup",
      sorter: reportList.length > 0 ? true : false,
      sortOrder: getSortOrder("lineOfBusinessGroup"),
      ellipsis: true,
      responsive: ["md"],
    },
    // {
    //   title: "Info",
    //   dataIndex: "info",
    //   key: "info",
    //   responsive: ["md"],
    //   width: 80,
    //   align: "center",
    //   render: (_, record) => {
    //     return <InfoPopover record={record} />;
    //   },
    // },
    {
      title: "Last Wellness Visit",
      dataIndex: "lastWellnessVisitDate",
      key: "lastWellnessVisitDate",
      sorter: reportList.length > 0 ? true : false,
      sortOrder: getSortOrder("lastWellnessVisitDate"),
      responsive: ["md"],
      render: (_, record, index) => {
        return (
          <Text className="patients_cell_text">
            {FormatDate(record.lastWellnessVisitDate, isMobile)}
          </Text>
        );
      },
    },
    {
      title: "Claims Within 3 Years",
      dataIndex: "claimsWithin3Years",
      key: "claimsWithin3Years",
      responsive: ["md"],
      render: (_, record, index) => {
        return (
          <Text
            className={`patients_cell_text ${
              record.hasClaims && "patients_click_view"
            }`}
          >
            {record.hasClaims ? record.claimsWithin3Years : "No Claims"}
          </Text>
        );
      },
      onCell: (record) => {
        return {
          onClick: () => {
            record.hasClaims &&
              navigate(
                `${ReportRoutesConstant.Report.ClaimsByMemberId.path.replace(
                  ":memberId",
                  record.memberId
                )}`
              );
          },
        };
      },
    },
    {
      title: "View",
      dataIndex: "view",
      key: "view",
      responsive: ["xs"],
      render: (_, record, index) => {
        return (
          <Text
            onClick={() => expandView(record.key)}
            className="patients_viewaction"
          >
            {record.key === expandedViewKey ? <UpOutlined /> : <DownOutlined />}
          </Text>
        );
      },
    },
  ];

  const toggleShownColumn = (value: string) => {
    const updatedColumns = shownColumns.includes(value)
      ? shownColumns.filter((val) => val !== value)
      : [...shownColumns, value];

    setShownColumns(updatedColumns);

    setUserSettings({
      ...users?.settings,
      patientShownColumns: updatedColumns,
    });
  };

  const onExportDataClick = () => {
    exportData({ ...patientListRequest, page: -1, pageSize: -1 }, (res) => {
      if (res.success) {
        DownloadFile(
          res?.data?.contentType,
          res?.data?.rawData,
          res?.data?.fileName
        );
      } else {
        if (res.message === Messages.AccessDenied) {
          navigate(PageRoutesConstant.Page.AccessDenied.path, {
            replace: true,
          });
        }
        message.error(res.message, 5);
      }
    });
  };

  const items: MenuProps["items"] = [
    {
      label: (
        <CustomButton
          className="patients_header_btn_wrapper_export_data_btn"
          text={
            patients?.exportLoading
              ? "Loading…"
              : PatientsConstant.ExportDataButtonText
          }
          radius={8}
          onClick={onExportDataClick}
          disabled={
            patients?.data?.items?.length === 0 || patients?.exportLoading
          }
          loading={patients?.exportLoading}
        />
      ),
      key: "0",
    },
    // {
    //   label: (
    //     <CustomButton
    //       className="patients_header_btn_wrapper_printtopdf_data_btn"
    //       text={PatientsConstant.PdfToFileButtonText}
    //       radius={8}
    //       disabled={patients?.data?.items?.length === 0}
    //     />
    //   ),
    //   key: "1",
    // },
  ];

  const expandedViewRowRender = (record) => {
    return (
      <div className="patients_expanded_content">
        <Row gutter={[23, 14]}>
          <Col span={24}>
            <Title level={5} className="patients_expanded_row_title mb-0">
              Provider Group Name
            </Title>
            <Text className="patients_expanded_row_cell_text">
              {record.providerGroupName}
            </Text>
          </Col>
          <Col span={24}>
            <Title level={5} className="patients_expanded_row_title mb-0">
              Payor
            </Title>
            <Text className="patients_expanded_row_cell_text">
              {record.payor}
            </Text>
          </Col>
          <Col span={24}>
            <Title level={5} className="patients_expanded_row_title mb-0">
              Line of Business
            </Title>
            <Text className="patients_expanded_row_cell_text">
              {record.lineOfBusinessGroup}
            </Text>
          </Col>
          <Col span={24}>
            <Title level={5} className="patients_expanded_row_title mb-0">
              Last Wellness Visit
            </Title>
            <Text className="patients_expanded_row_cell_text">
              {FormatDate(record.lastWellnessVisitDate)}
            </Text>
          </Col>
          <Col span={24}>
            <Title level={5} className="patients_expanded_row_title mb-0">
              Claims Within 3 Years
            </Title>
            <Text
              className={`patients_expanded_row_cell_text ${
                record.hasClaims && "patients_click_view"
              }`}
              onClick={() => {
                record.hasClaims &&
                  navigate(
                    `${ReportRoutesConstant.Report.ClaimsByMemberId.path.replace(
                      ":memberId",
                      record.memberId
                    )}`
                  );
              }}
            >
              {record.hasClaims ? record.claimsWithin3Years : "No Claims"}
            </Text>
          </Col>
          <Col xs={24} className="patients_expanded_wrapper" key="memberId">
            <Title level={5} className="patients_expanded_name_row_title mb-0">
              Member Id
            </Title>
            <Paragraph className="patients_expanded_name_row_cell_text mb-0">
              {record.memberId}
            </Paragraph>
          </Col>
          {Object.keys(ShownFieldsLabel).map((field) => {
            if (shownFields.includes(ShownFieldsName[field])) {
              return (
                <Col xs={24} className="patients_expanded_wrapper" key={field}>
                  <Title
                    level={5}
                    className="patients_expanded_name_row_title mb-0"
                  >
                    {ShownFieldsLabel[field]}
                  </Title>
                  <Paragraph className="patients_expanded_name_row_cell_text mb-0">
                    {record[field]}
                  </Paragraph>
                </Col>
              );
            }
            return null;
          })}
          <Col
            xs={24}
            className="patients_expanded_wrapper"
            key="lastUpdatedDate"
          >
            <Title level={5} className="patients_expanded_name_row_title mb-0">
              Last Updated Date
            </Title>
            <Paragraph className="patients_expanded_name_row_cell_text mb-0">
              {FormatDate(record.lastUpdatedDate)}
            </Paragraph>
          </Col>
        </Row>
      </div>
    );
  };

  const expandedNameRowRender = (record: any) => {
    return (
      <Row gutter={[24, 24]} className="patients_expanded_row">
        <Col className="patients_expanded_wrapper" key="memberId">
          <Title level={5} className="patients_expanded_name_row_title mb-0">
            Member Id
          </Title>
          <Paragraph className="patients_expanded_name_row_cell_text mb-0">
            {record.memberId}
          </Paragraph>
        </Col>
        {Object.keys(ShownFieldsLabel).map((field) => {
          if (shownFields.includes(ShownFieldsName[field])) {
            return (
              <Col className="patients_expanded_wrapper" key={field}>
                <Title
                  level={5}
                  className="patients_expanded_name_row_title mb-0"
                >
                  {ShownFieldsLabel[field]}
                </Title>
                <Paragraph className="patients_expanded_name_row_cell_text mb-0">
                  {record[field]}
                </Paragraph>
              </Col>
            );
          }
          return null;
        })}
        <Col className="patients_expanded_wrapper" key="lastUpdatedDate">
          <Title level={5} className="patients_expanded_name_row_title mb-0">
            Last Updated Date
          </Title>
          <Paragraph className="patients_expanded_name_row_cell_text mb-0">
            {FormatDate(record.lastUpdatedDate)}
          </Paragraph>
        </Col>
      </Row>
    );
  };

  const [isFilterOptionOpen, setIsFilterOptionOpen] =
    useState<boolean>(undefined);
  const [isLastUpdatedCustomRangeShow, setIsLastUpdatedCustomRangeShow] =
    useState<boolean>(
      users?.settings?.patientFilterOptions?.lastUpdated ===
        TimeRange.CustomRange
    );
  const [isLastVisitCustomRangeShow, setIsLastVisitCustomRangeShow] =
    useState<boolean>(
      users?.settings?.patientFilterOptions?.lastVisit === TimeRange.CustomRange
    );

  const onLastUpdatedChange = (value: TimeRange) => {
    setIsLastUpdatedCustomRangeShow(value === TimeRange.CustomRange);
    if (value !== TimeRange.CustomRange) {
      filterForm.setFieldValue("lastUpdatedStartDate", undefined);
      filterForm.setFieldValue("lastUpdatedEndDate", undefined);
    }
  };

  const onLastVisitChange = (value: TimeRange) => {
    setIsLastVisitCustomRangeShow(value === TimeRange.CustomRange);
    if (value !== TimeRange.CustomRange) {
      filterForm.setFieldValue("lastVisitStartDate", undefined);
      filterForm.setFieldValue("lastVisitEndDate", undefined);
    }
  };

  const [filteredValue, setFilteredValue] = useState<PatientFilterOptionsDto>({
    payor: users?.settings?.patientFilterOptions?.payor,
    lastUpdated: users?.settings?.patientFilterOptions?.lastUpdated,
    wellnessVisitThisYear:
      users?.settings?.patientFilterOptions?.wellnessVisitThisYear,
    lastUpdatedStartDate: FormatDataDate(
      users?.settings?.patientFilterOptions?.lastUpdatedStartDate,
      true
    ),
    lastUpdatedEndDate: FormatDataDate(
      users?.settings?.patientFilterOptions?.lastUpdatedEndDate,
      true
    ),
    firstName: users?.settings?.patientFilterOptions?.firstName,
    lastName: users?.settings?.patientFilterOptions?.lastName,
    dateOfBirth: FormatDataDate(
      users?.settings?.patientFilterOptions?.dateOfBirth,
      true
    ),
    lastVisit: users?.settings?.patientFilterOptions?.lastVisit,
    lastVisitStartDate: FormatDataDate(
      users?.settings?.patientFilterOptions?.lastVisitStartDate,
      true
    ),
    lastVisitEndDate: FormatDataDate(
      users?.settings?.patientFilterOptions?.lastVisitEndDate,
      true
    ),
    lineOfBusinessGroup:
      users?.settings?.patientFilterOptions?.lineOfBusinessGroup,
    providerGroupName: users?.settings?.patientFilterOptions?.providerGroupName,
  });

  const onFilter = () => {
    setResetAllDisable(false);
    filterForm
      .validateFields()
      .then((values) => {
        setExpandedNameKey(undefined);
        setExpandedViewKey(undefined);
        // TODO: set types on actions and check dates vs strings
        setUserSettings({
          ...users?.settings,
          patientFilterOptions: {
            payor: values.payor,
            lastUpdated: values.lastUpdated,
            wellnessVisitThisYear: values.wellnessVisitThisYear,
            lastUpdatedStartDate: values.lastUpdatedStartDate,
            lastUpdatedEndDate: values.lastUpdatedEndDate,
            firstName: values.firstName,
            lastName: values.lastName,
            dateOfBirth: FormatDataDate(values.dateOfBirth),
            lastVisit: values.lastVisit,
            lastVisitStartDate: values.lastVisitStartDate,
            lastVisitEndDate: values.lastVisitEndDate,
            lineOfBusinessGroup: values.lineOfBusinessGroup,
            providerGroupName: values.providerGroupName,
          },
        });
        setFilteredValue({
          payor: values.payor,
          lastUpdated: values.lastUpdated,
          wellnessVisitThisYear: values.wellnessVisitThisYear,
          lastUpdatedStartDate: FormatDataDate(
            values.lastUpdatedStartDate,
            true
          ),
          lastUpdatedEndDate: FormatDataDate(values.lastUpdatedEndDate, true),
          firstName: values.firstName,
          lastName: values.lastName,
          dateOfBirth: FormatDataDate(values.dateOfBirth, true),
          lastVisit: values.lastVisit,
          lastVisitStartDate: FormatDataDate(values.lastVisitStartDate, true),
          lastVisitEndDate: FormatDataDate(values.lastVisitEndDate, true),
          lineOfBusinessGroup: values.lineOfBusinessGroup,
          providerGroupName: values.providerGroupName,
        });
        setPatientListRequest({
          ...patientListRequest,
          payor: values.payor,
          lastUpdated: values.lastUpdated,
          wellnessVisitThisYear: values.wellnessVisitThisYear,
          lastUpdatedStartDate: values.lastUpdatedStartDate as any,
          lastUpdatedEndDate: values.lastUpdatedEndDate as any,
          firstName: values.firstName,
          lastName: values.lastName,
          dateOfBirth: FormatDataDate(values.dateOfBirth),
          lastVisit: values.lastVisit,
          lastVisitStartDate: values.lastVisitStartDate as any,
          lastVisitEndDate: values.lastVisitEndDate as any,
          page: 1,
          pageSize: pageSize,
          lineOfBusinessGroup: values.lineOfBusinessGroup,
          providerGroupName: values.providerGroupName,
        });
        setIsFilterOptionOpen(false);
        setCurrentPage(1);
      })
      .catch((info) => {
        console.log("Filter Validate Failed:", info);
      });
  };

  const onClearFilter = () => {
    setCurrentPage(1);
    setExpandedNameKey(undefined);
    setExpandedViewKey(undefined);
    setIsLastUpdatedCustomRangeShow(false);
    setIsLastVisitCustomRangeShow(false);
    setUserSettings({
      ...users?.settings,
      patientFilterOptions: undefined,
    });
    filterForm.setFieldsValue(EmptyFormFields);
    setFilteredValue({});
    setPatientListRequest({
      ...patientListRequest,
      payor: undefined,
      lastUpdated: undefined,
      wellnessVisitThisYear: undefined,
      lastUpdatedStartDate: undefined,
      lastUpdatedEndDate: undefined,
      memberId: undefined,
      firstName: undefined,
      lastName: undefined,
      dateOfBirth: undefined,
      lastVisit: undefined,
      lastVisitStartDate: undefined,
      lastVisitEndDate: undefined,
      page: 1,
      pageSize: pageSize,
      lineOfBusinessGroup: undefined,
      providerGroupName: undefined,
    });
    setIsFilterOptionOpen(false);
    filterForm.validateFields();
  };

  const onResetAll = () => {
    const tableColumns = TableColumns.map((item) => item.dataIndex);
    setResetAllDisable(true);
    setCurrentPage(1);
    setPageSize(10);
    setExpandedNameKey(undefined);
    setExpandedViewKey(undefined);
    setShownFields(
      Array.from(
        new Set(
          Object.values(TableFieldsName).concat(Object.values(ShownFieldsName))
        )
      )
    );
    setShownColumns(tableColumns);
    setUserSettings({
      ...users?.settings,
      patientShownColumns: tableColumns,
      patientShownFields: Array.from(
        new Set(
          Object.values(TableFieldsName).concat(Object.values(ShownFieldsName))
        )
      ),
      patientFilterOptions: undefined,
      patientSortOptions: undefined,
    });
    setFilteredValue({});
    setSortedInfo({});
    setIsFilterOptionOpen(false);
    setIsLastUpdatedCustomRangeShow(false);
    setIsLastVisitCustomRangeShow(false);
    filterForm.setFieldsValue(EmptyFormFields);
    setSelectedPatientSearch(undefined);
    setPatientListRequest({
      ...patientListRequest,
      shownFields: Array.from(
        new Set(
          Object.values(TableFieldsName).concat(Object.values(ShownFieldsName))
        )
      ),
      payor: undefined,
      lastUpdated: undefined,
      wellnessVisitThisYear: undefined,
      lastUpdatedStartDate: undefined,
      lastUpdatedEndDate: undefined,
      firstName: undefined,
      lastName: undefined,
      memberId: undefined,
      dateOfBirth: undefined,
      lastVisit: undefined,
      lastVisitStartDate: undefined,
      lastVisitEndDate: undefined,
      sortBy: undefined,
      sortOrderAsc: undefined,
      page: 1,
      pageSize: 10,
      lineOfBusinessGroup: undefined,
      providerGroupName: undefined,
    });
  };

  const onCloseFilterOptions = () => {
    if (
      filterForm.getFieldValue("lastUpdated") === TimeRange.CustomRange &&
      !filterForm.getFieldValue("lastUpdatedStartDate") &&
      !filterForm.getFieldValue("lastUpdatedEndDate")
    ) {
      filterForm.resetFields(["lastUpdated"]);
      setIsLastUpdatedCustomRangeShow(false);
    }
    if (
      filterForm.getFieldValue("lastVisit") === TimeRange.CustomRange &&
      !filterForm.getFieldValue("lastVisitStartDate") &&
      !filterForm.getFieldValue("lastVisitEndDate")
    ) {
      filterForm.resetFields(["lastVisit"]);
      setIsLastVisitCustomRangeShow(false);
    }
    setIsFilterOptionOpen(false);
  };

  useEffect(() => {
    getUserSettingsByUserName();
  }, []);

  useEffect(() => {
    if (!users.loading) {
      if (updatesMade) {
        updateUserSettings({
          ...users?.settings,
        });
      }
      setUpdatesMade(true);
    }
    setIsLastUpdatedCustomRangeShow(
      users?.settings?.patientFilterOptions?.lastUpdated ===
        TimeRange.CustomRange
    );
    setIsLastVisitCustomRangeShow(
      users?.settings?.patientFilterOptions?.lastVisit === TimeRange.CustomRange
    );
    setSortedInfo({
      columnKey: users?.settings?.patientSortOptions?.sortBy,
      order: users?.settings?.patientSortOptions?.sortOrder as SortOrder,
    });
  }, [users?.settings]);

  useEffect(() => {
    setExpandedNameKey(undefined);
  }, [isMobile]);

  useEffect(() => {
    setExpandedViewKey(undefined);
  }, [!isMobile]);

  useEffect(() => {
    if (!patients.loading && Object.keys(patientListRequest).length > 0) {
      getAllPatientsDebounced({
        ...patientListRequest,
      });
    }
  }, [patientListRequest]);

  useEffect(() => {
    if (patients?.error === Messages.AccessDenied) {
      navigate(PageRoutesConstant.Page.AccessDenied.path, {
        replace: true,
      });
    }
  }, [patients?.error]);

  useEffect(() => {
    setReportList(
      Object.values(patients?.data?.items ?? [])?.map(
        (patient: PatientDto) => ({
          key: patient.id,
          ...patient,
          claimsWithin3Years: "Click to View",
          view: "",
        })
      )
    );
  }, [patients?.data]);

  const [openModifyShown, setOpenModifyShown] = useState(false);
  const handleOpenModifyShownChange = (newOpen: boolean) => {
    setOpenModifyShown(newOpen);
  };

  const handleOpenFilterOptionsChange = (newOpen: boolean) => {
    setIsFilterOptionOpen(newOpen);
  };

  const removeFilteredValue = (key: keyof FilterFormValues) => {
    if (
      key === "lastUpdated" ||
      key === "lastUpdatedStartDate" ||
      key === "lastUpdatedEndDate"
    ) {
      filterForm.setFieldsValue({
        lastUpdated: undefined,
        lastUpdatedStartDate: undefined,
        lastUpdatedEndDate: undefined,
      });
      setIsLastUpdatedCustomRangeShow(false);
    } else if (
      key === "lastVisit" ||
      key === "lastVisitStartDate" ||
      key === "lastVisitEndDate"
    ) {
      filterForm.setFieldsValue({
        lastVisit: undefined,
        lastVisitStartDate: undefined,
        lastVisitEndDate: undefined,
      });
      setIsLastVisitCustomRangeShow(false);
    } else {
      filterForm.setFieldValue(key, undefined);
    }
    const values = filterForm.getFieldsValue();
    setExpandedNameKey(undefined);
    setExpandedViewKey(undefined);
    // TODO: set types on actions and check dates vs strings
    setUserSettings({
      ...users?.settings,
      patientFilterOptions: {
        payor: values.payor,
        lastUpdated: values.lastUpdated,
        wellnessVisitThisYear: values.wellnessVisitThisYear,
        lastUpdatedStartDate: values.lastUpdatedStartDate,
        lastUpdatedEndDate: values.lastUpdatedEndDate,
        firstName: values.firstName,
        lastName: values.lastName,
        dateOfBirth: FormatDataDate(values.dateOfBirth),
        lastVisit: values.lastVisit,
        lastVisitStartDate: values.lastVisitStartDate,
        lastVisitEndDate: values.lastVisitEndDate,
        lineOfBusinessGroup: values.lineOfBusinessGroup,
        providerGroupName: values.providerGroupName,
      },
    });
    setFilteredValue({
      payor: values.payor,
      lastUpdated: values.lastUpdated,
      wellnessVisitThisYear: values.wellnessVisitThisYear,
      lastUpdatedStartDate: FormatDataDate(values.lastUpdatedStartDate, true),
      lastUpdatedEndDate: FormatDataDate(values.lastUpdatedEndDate, true),
      firstName: values.firstName,
      lastName: values.lastName,
      dateOfBirth: FormatDataDate(values.dateOfBirth, true),
      lastVisit: values.lastVisit,
      lastVisitStartDate: FormatDataDate(values.lastVisitStartDate, true),
      lastVisitEndDate: FormatDataDate(values.lastVisitEndDate, true),
      lineOfBusinessGroup: values.lineOfBusinessGroup,
      providerGroupName: values.providerGroupName,
    });
    setPatientListRequest({
      ...patientListRequest,
      payor: values.payor,
      lastUpdated: values.lastUpdated,
      wellnessVisitThisYear: values.wellnessVisitThisYear,
      lastUpdatedStartDate: values.lastUpdatedStartDate as any,
      lastUpdatedEndDate: values.lastUpdatedEndDate as any,
      firstName: values.firstName,
      lastName: values.lastName,
      dateOfBirth: FormatDataDate(values.dateOfBirth),
      lastVisit: values.lastVisit,
      lastVisitStartDate: values.lastVisitStartDate as any,
      lastVisitEndDate: values.lastVisitEndDate as any,
      page: 1,
      pageSize: pageSize,
      lineOfBusinessGroup: values.lineOfBusinessGroup,
      providerGroupName: values.providerGroupName,
    });
    setIsFilterOptionOpen(false);
    setCurrentPage(1);
  };

  return (
    <div className="page_layout">
      <div className="patients">
        <Row>
          <Col xs={24} className="px-md-4">
            <Row align="middle" gutter={[10, 10]} className="mb-4">
              <Col>
                <Popover
                  arrow={false}
                  placement="bottomRight"
                  content={
                    <ToggleShownColumnsFields
                      handleModifyShownFields={handleModifyShownFields}
                      shownFields={shownFields}
                      shownColumns={shownColumns}
                      toggleShownColumn={toggleShownColumn}
                    />
                  }
                  trigger="click"
                  open={openModifyShown}
                  onOpenChange={handleOpenModifyShownChange}
                  className="patients_shown_columns_fields"
                  overlayClassName="patients_shown_columns_fields_popup"
                >
                  <WriteIcon />
                </Popover>
              </Col>
              <Col>
                <div className="divider" />
              </Col>
              <Col>
                <Popover
                  placement="bottomRight"
                  arrow={false}
                  content={
                    <FilterOptions
                      form={filterForm}
                      userSettings={users.settings}
                      onLastUpdatedChange={onLastUpdatedChange}
                      isLastUpdatedCustomRangeShow={
                        isLastUpdatedCustomRangeShow
                      }
                      onLastVisitChange={onLastVisitChange}
                      isLastVisitCustomRangeShow={isLastVisitCustomRangeShow}
                      onFilter={onFilter}
                      onClearFilter={onClearFilter}
                      onCloseFilterOptions={onCloseFilterOptions}
                    />
                  }
                  trigger="click"
                  open={isFilterOptionOpen}
                  onOpenChange={handleOpenFilterOptionsChange}
                  className="patients_filter_options"
                  overlayClassName="patients_filter_options_popup"
                >
                  <SliderIcon />
                </Popover>
              </Col>
              <Col className="d-flex flex-wrap">
                {filteredValue &&
                  Object.keys(filteredValue).map(
                    (key: keyof FilterFormValues, i) =>
                      filteredValue[key] !== null &&
                      filteredValue[key] !== "" &&
                      filteredValue[key] !== undefined && (
                        <Tag
                          key={i}
                          bordered={false}
                          closable={true}
                          className="filter-val-tag my-1"
                          onClose={() => removeFilteredValue(key)}
                        >
                          {key === "lastUpdated" || key === "lastVisit"
                            ? TimeRange[filteredValue[key]]
                            : filteredValue[key]}
                        </Tag>
                      )
                  )}
              </Col>
              <Col>
                <Button
                  className="patients_reset_all_button"
                  onClick={onResetAll}
                  size="large"
                  disabled={resetAllDisable}
                  type="text"
                >
                  Reset All
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              showSorterTooltip={false}
              dataSource={reportList}
              expandable={{
                expandedRowRender: (record) =>
                  isMobile
                    ? expandedViewRowRender(record)
                    : expandedNameRowRender(record),
                expandIcon: () => <></>,
                expandedRowKeys: [isMobile ? expandedViewKey : expandedNameKey],
                showExpandColumn: false,
              }}
              columns={columns.filter((col: any) =>
                shownColumns.includes(col.dataIndex)
              )}
              onChange={handleTableChange}
              title={() => (
                <Row align="middle">
                  <Col xs={10} sm={6} className="patients_header">
                    <Title level={4} className="mb-0 patients_header_title">
                      {PatientsConstant.PageTitle}
                    </Title>
                  </Col>
                  <Col sm={18} className="patients_header_btn_wrapper">
                    <CustomButton
                      className="patients_header_btn_wrapper_export_data_btn"
                      text={
                        patients?.exportLoading
                          ? "Loading…"
                          : PatientsConstant.ExportDataButtonText
                      }
                      onClick={onExportDataClick}
                      disabled={
                        patients?.data?.items?.length === 0 ||
                        patients?.exportLoading
                      }
                      loading={patients?.exportLoading}
                    />
                    {/* <CustomButton
                      className="patients_header_btn_wrapper_printtopdf_data_btn"
                      text={PatientsConstant.PdfToFileButtonText}
                      disabled={patients?.data?.items?.length === 0}
                    /> */}
                  </Col>
                  <Col xs={14} className="patients_menu">
                    <Dropdown
                      menu={{ items }}
                      trigger={["click"]}
                      className="patients_header_dropdown"
                      overlayClassName="patients_header_dropdown_overlay"
                    >
                      <MenuOutlined
                        style={{ fontSize: 24, color: "#808080" }}
                      />
                    </Dropdown>
                  </Col>
                </Row>
              )}
              pagination={{
                current: currentPage,
                hideOnSinglePage: true,
                total: patients?.data?.totalCount,
                pageSize: pageSize,
                prevIcon: (
                  <CustomButton
                    iconPosition="left"
                    icon={<LeftOutlined />}
                    className="patients_custom_pagination_btn prev"
                    text={PatientsConstant.PrevButtonText}
                    font={16}
                    radius={8}
                    width={111}
                  />
                ),
                nextIcon: (
                  <CustomButton
                    iconPosition="right"
                    icon={<RightOutlined />}
                    className="patients_custom_pagination_btn next"
                    text={PatientsConstant.NextButtonText}
                    font={16}
                    radius={8}
                    width={111}
                  />
                ),
              }}
              loading={{
                size: "large",
                tip: "Loading...",
                spinning: patients.loading,
                indicator: <LoadingOutlined />,
              }}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

type InfoPopoverProps = {
  record: PatientDto;
};
const InfoPopover: FC<InfoPopoverProps> = ({ record }) => {
  return (
    <Button
      type="text"
      size="large"
      className="btn-info"
      disabled={!record.hasClaims}
    >
      <Popover
        trigger="hover"
        overlayClassName="info-overlay"
        arrow={false}
        content={
          <div className="p-2">
            <Row>
              <Col xs={24}>
                <Title level={5} italic>
                  {record.fullName}
                </Title>
              </Col>
              {/* TODO: Show more details  */}
            </Row>
          </div>
        }
      >
        <InfoIcon />
      </Popover>
    </Button>
  );
};

type ToggleShownColumnsFieldsProps = {
  handleModifyShownFields: (fields: string[]) => void;
  shownFields: string[];
  shownColumns: string[];
  toggleShownColumn: (value: string) => void;
};
const ToggleShownColumnsFields: FC<ToggleShownColumnsFieldsProps> = ({
  handleModifyShownFields,
  shownFields,
  shownColumns,
  toggleShownColumn,
}) => {
  const [selectAllFieldsChecked, setSelectAllFieldsChecked] =
    useState<boolean>(false);

  const onCheckChange = (value: string) => {
    handleModifyShownFields(
      shownFields.includes(value)
        ? shownFields.filter((val) => val !== value)
        : [...shownFields, value]
    );
  };

  const onHeaderFieldsCheckboxChange = (): void => {
    const checked = !selectAllFieldsChecked;
    setSelectAllFieldsChecked(checked);

    const updatedShownFields = checked ? Object.values(ShownFieldsName) : [];

    handleModifyShownFields(updatedShownFields);
  };

  const matchingFieldsValues = shownFields.filter((value: string) =>
    Object.values(ShownFieldsName).includes(value)
  );

  useEffect(() => {
    matchingFieldsValues.length === Object.keys(ShownFieldsLabel).length
      ? setSelectAllFieldsChecked(true)
      : setSelectAllFieldsChecked(false);
  }, [matchingFieldsValues.length]);

  return (
    <>
      <List
        size="small"
        header={
          <Title level={5} className="mb-0">
            Columns
          </Title>
        }
        className="columns_list"
        dataSource={TableColumns}
        renderItem={(item) =>
          item.isVisible && (
            <List.Item className="d-flex align-items-center">
              <Title level={5} className="mb-0">
                {item.title}
              </Title>
              {item.hasHideOption && (
                <Switch
                  className="ms-5"
                  defaultChecked={shownColumns.includes(item.dataIndex)}
                  checked={shownColumns.includes(item.dataIndex)}
                  onChange={() => toggleShownColumn(item.dataIndex)}
                />
              )}
            </List.Item>
          )
        }
      />
      <List
        size="small"
        header={
          <Row align="middle" justify="space-between">
            <Col>
              <Title level={5} className="mb-0">
                Fields
              </Title>
            </Col>
            <Col>
              <Checkbox
                className="fields_checkbox"
                onChange={onHeaderFieldsCheckboxChange}
                checked={selectAllFieldsChecked}
              />
            </Col>
          </Row>
        }
        className="fields_list"
        dataSource={Object.keys(ShownFieldsLabel)}
        renderItem={(item) => {
          return (
            <List.Item className="d-flex align-items-center">
              <Checkbox
                className="fields_checkbox"
                onChange={() => onCheckChange(ShownFieldsName[item])}
                defaultChecked={shownFields.includes(ShownFieldsName[item])}
                checked={shownFields.includes(ShownFieldsName[item])}
              >
                {ShownFieldsLabel[item]}
              </Checkbox>
            </List.Item>
          );
        }}
      />
    </>
  );
};

type FilterOptionsProps = {
  form: FormInstance;
  userSettings: UserSettingsDto;
  onLastUpdatedChange: (value: any) => void;
  isLastUpdatedCustomRangeShow: boolean;
  onLastVisitChange: (value: any) => void;
  isLastVisitCustomRangeShow: boolean;
  onFilter: () => void;
  onClearFilter: () => void;
  onCloseFilterOptions: () => void;
};

const FilterOptions: FC<FilterOptionsProps> = ({
  form,
  userSettings,
  onLastUpdatedChange,
  isLastUpdatedCustomRangeShow,
  onLastVisitChange,
  isLastVisitCustomRangeShow,
  onFilter,
  onClearFilter,
  onCloseFilterOptions,
}) => {
  const { getAllPayors, getAllLineOfBusinessGroups, getAllProviderGroupNames } =
    useActions();
  const payors = useTypedSelector((state) => state.payors);
  const lineOfBusiness = useTypedSelector((state) => state.lineOfBusiness);
  const providerGroup = useTypedSelector((state) => state.providerGroup);

  useEffect(() => {
    getAllPayors();
    getAllLineOfBusinessGroups();
    getAllProviderGroupNames();
  }, []);

  return (
    <div className="p-3">
      <Form form={form}>
        <Row gutter={[15, 10]}>
          <Col xs={24} sm={12} md={12}>
            <Row gutter={[7, 12]}>
              <Col span={24}>
                <Title level={5} className="mb-0 patients_filter_options_title">
                  Last Updated
                </Title>
                <Form.Item
                  name="lastUpdated"
                  label=""
                  className="mb-0"
                  initialValue={userSettings?.patientFilterOptions?.lastUpdated}
                >
                  <Select
                    placeholder="View All"
                    className="patients_filter_options_last_updated"
                    popupClassName="patients_filter_options_last_updated_popup"
                    size="large"
                    allowClear
                    onChange={onLastUpdatedChange}
                  >
                    {LastUpdatedOptions.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {isLastUpdatedCustomRangeShow && (
                <Col span={24}>
                  <Row gutter={[7, 13]}>
                    <Col xs={24}>
                      <Form.Item
                        name="lastUpdatedStartDate"
                        label=""
                        className="mb-0"
                        initialValue={ParseDayJsDate(
                          userSettings?.patientFilterOptions
                            ?.lastUpdatedStartDate
                        )}
                        rules={[
                          {
                            required: isLastUpdatedCustomRangeShow,
                            validator: validateRequired,
                          },
                        ]}
                      >
                        <DatePicker
                          size="large"
                          className="patients_filter_options_custom_date_picker"
                          format={DATE_FORMAT_DEFAULT}
                          placeholder="Start Date"
                          onChange={(date) =>
                            !date ||
                            (date.isAfter(
                              form.getFieldValue("lastUpdatedEndDate")
                            ) &&
                              form.setFieldValue("lastUpdatedEndDate", ""))
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        name="lastUpdatedEndDate"
                        label=""
                        className="mb-0"
                        initialValue={ParseDayJsDate(
                          userSettings?.patientFilterOptions?.lastUpdatedEndDate
                        )}
                        rules={[
                          {
                            required: isLastUpdatedCustomRangeShow,
                            validator: validateRequired,
                          },
                        ]}
                      >
                        <DatePicker
                          size="large"
                          className="patients_filter_options_custom_date_picker"
                          format={DATE_FORMAT_DEFAULT}
                          placeholder="End Date"
                          disabledDate={(date) =>
                            !date ||
                            date.isBefore(
                              form.getFieldValue("lastUpdatedStartDate")
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )}
              <Col span={24}>
                <Title level={5} className="mb-0 patients_filter_options_title">
                  Wellness Visit This Year
                </Title>
                <Form.Item
                  name="wellnessVisitThisYear"
                  label=""
                  className="mb-0"
                  initialValue={
                    userSettings?.patientFilterOptions?.wellnessVisitThisYear
                  }
                >
                  <Select
                    placeholder="View All"
                    className="patients_filter_options_wellness_visit"
                    popupClassName="patients_filter_options_wellness_visit_popup"
                    size="large"
                    allowClear
                  >
                    {WellnessVisitOptions.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Title level={5} className="mb-0 patients_filter_options_title">
                  Payor
                </Title>
                <Form.Item
                  name="payor"
                  label=""
                  className="mb-0"
                  initialValue={userSettings?.patientFilterOptions?.payor}
                >
                  <Select
                    placeholder="View All"
                    className="patients_filter_options_payor"
                    popupClassName="patients_filter_options_payor_popup"
                    size="large"
                    allowClear
                  >
                    {payors?.data?.map((payor) => (
                      <Option key={payor} value={payor}>
                        {payor}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Title level={5} className="mb-0 patients_filter_options_title">
                  Line Of Business Group
                </Title>
                <Form.Item
                  name="lineOfBusinessGroup"
                  label=""
                  className="mb-0"
                  initialValue={
                    userSettings?.patientFilterOptions?.lineOfBusinessGroup
                  }
                >
                  <Select
                    placeholder="View All"
                    className="patients_filter_options_lineofbusinessgroup"
                    popupClassName="patients_filter_options_lineofbusinessgroup_popup"
                    size="large"
                    allowClear
                  >
                    {lineOfBusiness?.groups?.map((lobg) => (
                      <Option key={lobg} value={lobg}>
                        {lobg}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Row gutter={[7, 12]}>
              <Col span={24}>
                <Title level={5} className="mb-0 patients_filter_options_title">
                  Date of Birth
                </Title>
                <Form.Item
                  name="dateOfBirth"
                  label=""
                  className="mb-0"
                  initialValue={ParseDayJsDate(
                    userSettings?.patientFilterOptions?.dateOfBirth
                  )}
                >
                  <DatePicker
                    size="large"
                    className="patients_filter_options_custom_date_picker"
                    format={DATE_FORMAT_DEFAULT}
                    placeholder={DATE_FORMAT_DEFAULT}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Title level={5} className="mb-0 patients_filter_options_title">
                  Last Visit
                </Title>
                <Form.Item
                  name="lastVisit"
                  label=""
                  className="mb-0"
                  initialValue={userSettings?.patientFilterOptions?.lastVisit}
                >
                  <Select
                    placeholder="View All"
                    className="patients_filter_options_last_visit"
                    popupClassName="patients_filter_options_last_visit_popup"
                    size="large"
                    allowClear
                    onChange={onLastVisitChange}
                  >
                    {LastVisitOptions.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {isLastVisitCustomRangeShow && (
                <Col span={24}>
                  <Row gutter={[7, 13]}>
                    <Col xs={24}>
                      <Form.Item
                        name="lastVisitStartDate"
                        label=""
                        className="mb-0"
                        initialValue={ParseDayJsDate(
                          userSettings?.patientFilterOptions?.lastVisitStartDate
                        )}
                        rules={[
                          {
                            required: isLastVisitCustomRangeShow,
                            validator: validateRequired,
                          },
                        ]}
                      >
                        <DatePicker
                          size="large"
                          className="patients_filter_options_custom_date_picker"
                          format={DATE_FORMAT_DEFAULT}
                          placeholder="Start Date"
                          onChange={(date) =>
                            !date ||
                            (date.isAfter(
                              form.getFieldValue("lastVisitEndDate")
                            ) &&
                              form.setFieldValue("lastVisitEndDate", ""))
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        name="lastVisitEndDate"
                        label=""
                        className="mb-0"
                        initialValue={ParseDayJsDate(
                          userSettings?.patientFilterOptions?.lastVisitEndDate
                        )}
                        rules={[
                          {
                            required: isLastVisitCustomRangeShow,
                            validator: validateRequired,
                          },
                        ]}
                      >
                        <DatePicker
                          size="large"
                          className="patients_filter_options_custom_date_picker"
                          format={DATE_FORMAT_DEFAULT}
                          placeholder="End Date"
                          disabledDate={(date) =>
                            !date ||
                            date.isBefore(
                              form.getFieldValue("lastVisitStartDate")
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )}
              <Col span={24}>
                <Title level={5} className="mb-0 patients_filter_options_title">
                  Provider Group Name
                </Title>
                <Form.Item
                  name="providerGroupName"
                  label=""
                  className="mb-0"
                  initialValue={
                    userSettings?.patientFilterOptions?.providerGroupName
                  }
                >
                  <Select
                    placeholder="View All"
                    className="patients_filter_options_providergroupname"
                    popupClassName="patients_filter_options_providergroupname_popup"
                    size="large"
                    allowClear
                  >
                    {providerGroup?.groups?.map((name) => (
                      <Option key={name} value={name}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Title level={5} className="mb-0 patients_filter_options_title">
                  A1C Values
                </Title>
                <Form.Item name="a1cValues" label="" className="mb-0">
                  <Select
                    placeholder="View All"
                    disabled
                    className="patients_filter_options_av_values"
                    popupClassName="patients_filter_options_av_values_popup"
                    size="large"
                    allowClear
                  >
                    {Object.keys(A1CValues).map((key) => {
                      return (
                        <Option key={key + A1CValues[key]} value={key}>
                          {A1CValues[key]}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[5, 5]}>
              <Col xs={24} md={8}>
                <CustomButton
                  className="patients_filter_options_custom_button"
                  text="Filter"
                  backgroundColor="#B8C7B9"
                  radius={8}
                  font={16}
                  color="#1A1419"
                  buttonType="submit"
                  onClick={onFilter}
                ></CustomButton>
              </Col>
              <Col xs={24} md={16}>
                <Row gutter={[5, { xs: 5, md: 0 }]}>
                  <Col xs={12} sm={12}>
                    <CustomButton
                      className="patients_filter_options_custom_button"
                      backgroundColor="#B8C7B9"
                      text="Clear"
                      radius={8}
                      font={16}
                      color="#1A1419"
                      onClick={onClearFilter}
                    ></CustomButton>
                  </Col>
                  <Col xs={12}>
                    <CustomButton
                      className="patients_filter_options_custom_button"
                      backgroundColor="#B8C7B9"
                      text="Close"
                      radius={8}
                      font={16}
                      color="#1A1419"
                      onClick={onCloseFilterOptions}
                    ></CustomButton>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Patients;
