import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Layout,
  MenuProps,
  Modal,
  Row,
  Space,
} from "antd";
import UseTheme from "common/settings/theme/UseTheme";
import React, { FC, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { DownOutlined, MenuOutlined } from "@ant-design/icons";
import { PageRoutesConstant } from "common/router/PageRoutes.constant";
import useResponsive from "hooks/useResponsive";
import { useMsal } from "@azure/msal-react";
import { EndSessionRequest } from "@azure/msal-browser";
import {
  hasAccessByUserPermissions,
  hasAccessByUserRoles,
} from "hooks/useAccess";
import { MainHeaderConstants } from "./MainHeader.constant";
import { AdminFlowRoutesConstant } from "common/router/AdminFlowRoutes.constant";
import "./MainHeader.style.scss";
import { useTypedSelector } from "hooks/useTypedSelector";

const { Header } = Layout;

const MainHeader: FC = () => {
  const { accounts, instance } = useMsal();
  const name = accounts?.[0]?.idTokenClaims?.name;
  const { logo, mobileLogo } = UseTheme();
  const { isMobile } = useResponsive();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const hamburgerButtonRef = useRef<HTMLButtonElement>(null);
  const { user, error } = useTypedSelector((state) => state.users);
  const userRoles = user?.roles;

  const handleMenuOk = () => {
    setMenuOpen(false);
  };
  const handleMenuCancel = () => {
    setMenuOpen(false);
    if (hamburgerButtonRef.current) {
      hamburgerButtonRef.current.blur();
    }
  };

  const logout = () => {
    const aadConfig = localStorage.getItem("aadConfig");
    const { authority } = aadConfig && JSON.parse(aadConfig);
    localStorage.clear();
    const logOutRequest: EndSessionRequest = {
      authority: authority,
      account: accounts[0],
      postLogoutRedirectUri: "/",
    };
    instance.logoutRedirect(logOutRequest);
  };

  const isActivePath = (path?: string) => {
    const { pathname } = location;

    if (path === PageRoutesConstant.Page.Home.path) {
      return pathname === path;
    }

    return (
      pathname === path ||
      pathname.includes(path) ||
      (path === PageRoutesConstant.Page.Reports.path &&
        pathname.includes(PageRoutesConstant.Page.Reports.path)) ||
      (path === PageRoutesConstant.Page.Admin.path &&
        pathname.includes(PageRoutesConstant.Page.Admin.path))
    );
  };

  const items: MenuProps["items"] = MainHeaderConstants.ReportMenuList.filter(
    (rm) =>
      hasAccessByUserRoles(userRoles, error, rm.roles) &&
      hasAccessByUserPermissions(user, error, rm.permissions)
  ).map((item, key) => ({
    key: key,
    label: (
      <Link
        to={item.disabled ? "" : item.path}
        className={`text-decoration-none text ${
          isActivePath(item.path) ? "active_item" : ""
        } ${item.disabled ? "disabled-events" : ""}`}
      >
        {item.title}
      </Link>
    ),
    icon: <item.icon viewBox={item.viewBox} className="menu_icon" />,
    onClick: () => {
      if (isMobile) {
        setMenuOpen(false);
      }
    },
  }));

  const adminItems: MenuProps["items"] =
    MainHeaderConstants.AdminMenuList.filter(
      (am) =>
        hasAccessByUserRoles(userRoles, error, am?.roles) &&
        hasAccessByUserPermissions(user, error, am.permissions)
    ).map((item, key) => ({
      key: key,
      label: (
        <Link
          to={item.path}
          className={`text-decoration-none text ${
            (item.path === AdminFlowRoutesConstant.Admin.UploadedReports.path &&
              location.pathname.includes(
                AdminFlowRoutesConstant.Admin.UploadedReports.path
              )) ||
            (item.path === AdminFlowRoutesConstant.Admin.Targets.path &&
              location.pathname.includes(
                AdminFlowRoutesConstant.Admin.Targets.path
              ))
              ? "active_item"
              : location.pathname.includes(item.path) &&
                !location.pathname.includes(
                  AdminFlowRoutesConstant.Admin.UploadedReports.path
                ) &&
                !location.pathname.includes(
                  AdminFlowRoutesConstant.Admin.Targets.path
                )
              ? "active_item"
              : ""
          }`}
        >
          {item.title}
        </Link>
      ),
      icon: <item.icon viewBox={item.viewBox} className="menu_icon" />,
      onClick: () => {
        if (isMobile) {
          setMenuOpen(false);
        }
      },
    }));

  return (
    <Header className="px-2">
      <Row align="middle" justify="start">
        <Col className="p-0">
          <Link to={PageRoutesConstant.Page.Home.path} reloadDocument>
            <img className="img-logo" src={isMobile ? mobileLogo : logo} />
          </Link>
        </Col>
        {accounts.length > 0 && (
          <Col className="d-flex align-items-center">
            <div className="div-bar"></div>
            <div className="div-text w-100 text-start text-md-center">
              Pop Health
            </div>
          </Col>
        )}
        {accounts.length > 0 && (
          <Col flex="auto">
            <div className="d-block d-xl-none text-end">
              <Button
                type="text"
                className="hamburger-btn"
                ref={hamburgerButtonRef}
                onClick={() => {
                  setMenuOpen(true);
                  hamburgerButtonRef.current?.blur();
                }}
              >
                <MenuOutlined className="hamburger-icon" />
              </Button>
            </div>
            <div className="d-none d-xl-block">
              <Row align="middle" justify={{ xl: "space-between" }}>
                <Col md={10} xl={15} xxl={18}>
                  <div className="d-flex align-items-center">
                    <div className="div-bar me-xl-4"></div>
                    {MainHeaderConstants.MenuList.filter((m) =>
                      hasAccessByUserRoles(userRoles, error, m.roles)
                    ).map((item, index) => (
                      <div
                        key={`mh_${item.title}_${index}`}
                        className="text-center me-3"
                      >
                        {item.path === PageRoutesConstant.Page.Reports.path ? (
                          <Dropdown
                            menu={{ items }}
                            trigger={["hover"]}
                            className="reports_sub_menu_items"
                            overlayClassName="reports_sub_menu_items_style "
                          >
                            <Link
                              to={""}
                              onClick={(e) => e.preventDefault()}
                              className={`menu-link ${
                                isActivePath(item.path) ? "active" : ""
                              }`}
                            >
                              <Space>
                                REPORTS
                                <DownOutlined />
                              </Space>
                            </Link>
                          </Dropdown>
                        ) : item.path === PageRoutesConstant.Page.Admin.path ? (
                          <Dropdown
                            menu={{ items: adminItems }}
                            trigger={["hover"]}
                            className="admin_sub_menu_items"
                            overlayClassName="admin_sub_menu_items_style "
                          >
                            <Link
                              to={""}
                              onClick={(e) => e.preventDefault()}
                              className={`menu-link ${
                                isActivePath(item.path) ? "active" : ""
                              }`}
                            >
                              <Space>
                                ADMIN
                                <DownOutlined />
                              </Space>
                            </Link>
                          </Dropdown>
                        ) : (
                          <Link
                            to={item.path}
                            className={`menu-link
                             ${isActivePath(item.path) ? "active" : ""}
                            `}
                          >
                            {item.title}
                          </Link>
                        )}
                      </div>
                    ))}
                  </div>
                </Col>
                <Col md={10} xl={9} xxl={6}>
                  <Row align="middle" justify="space-between">
                    <Col
                      flex="auto"
                      className="img-profilename justify-content-md-end"
                    >
                      <label className="profile-name">
                        Hi, <b>{name}</b>
                      </label>
                      <Avatar className="profile-name-case">
                        {name[0].toUpperCase()}
                      </Avatar>
                    </Col>
                    <Col className="ms-2 text-md-end">
                      <Button
                        size="large"
                        className="btn-logout"
                        onClick={logout}
                      >
                        Log Out
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <Modal
              className="menu-modal"
              style={{ top: 85 }}
              open={menuOpen}
              closable={false}
              footer={null}
              keyboard={true}
              maskClosable={true}
              confirmLoading={true}
              onOk={handleMenuOk}
              onCancel={handleMenuCancel}
            >
              <Row align="middle" justify="start" gutter={[12, 16]}>
                {MainHeaderConstants.MenuList.filter((m) =>
                  hasAccessByUserRoles(userRoles, error, m.roles)
                ).map((item, index) => (
                  <Col key={`mp_${item.title}_${index}`} xs={24}>
                    {item.path === PageRoutesConstant.Page.Reports.path ? (
                      <Dropdown
                        menu={{ items }}
                        trigger={["hover"]}
                        className="reports_sub_menu_items"
                        overlayClassName="reports_sub_menu_items_style"
                      >
                        <Link
                          to={""}
                          onClick={(e) => e.preventDefault()}
                          className={`menu-link ${
                            isActivePath(item.path) ? "active" : ""
                          }`}
                        >
                          <Space>
                            REPORTS
                            <DownOutlined />
                          </Space>
                        </Link>
                      </Dropdown>
                    ) : item.path === PageRoutesConstant.Page.Admin.path ? (
                      <Dropdown
                        menu={{ items: adminItems }}
                        trigger={["hover"]}
                        className="admin_sub_menu_items"
                        overlayClassName="admin_sub_menu_items_style"
                      >
                        <Link
                          to={""}
                          onClick={(e) => e.preventDefault()}
                          className={`menu-link ${
                            isActivePath(item.path) ? "active" : ""
                          }`}
                        >
                          <Space>
                            ADMIN
                            <DownOutlined />
                          </Space>
                        </Link>
                      </Dropdown>
                    ) : (
                      <Link
                        to={item.path}
                        className={`menu-link ${
                          isActivePath(item.path) ? "active" : ""
                        }`}
                        onClick={() => {
                          setMenuOpen(false);
                          hamburgerButtonRef.current?.blur();
                        }}
                      >
                        {item.title}
                      </Link>
                    )}
                  </Col>
                ))}
                <Col xs={24} className="img-profilename">
                  <label className="profile-name">
                    Hi, <b>{name}</b>
                  </label>
                  <Avatar className="profile-name-case">
                    {name[0].toUpperCase()}
                  </Avatar>
                </Col>
                <Col xs={12} sm={10}>
                  <Button size="large" className="btn-logout" onClick={logout}>
                    Log Out
                  </Button>
                </Col>
              </Row>
            </Modal>
          </Col>
        )}
      </Row>
    </Header>
  );
};

export default MainHeader;
