import { httpWithTokenInHeader } from "clients/api.clients.base";
import { BlobStorageClient } from "clients/api.generated.clients";
import { Dispatch } from "react";
import { Action } from "store/blobstorage/action";
import { BlobStorageActionType } from "store/blobstorage/actionTypes";

const blobStorageClient = new BlobStorageClient("", httpWithTokenInHeader);

export const getSignedBlobServiceUri =
  (cb?: (res) => void) => async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: BlobStorageActionType.GET_BLOB_STORAGE_SERVICE_URI,
    });
    await blobStorageClient
      .getSignedBlobServiceUri()
      .then((response) => {
        if (response.success) {
          dispatch({
            type: BlobStorageActionType.GET_BLOB_STORAGE_SERVICE_URI_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: BlobStorageActionType.GET_BLOB_STORAGE_SERVICE_URI_FAILURE,
            payload: response?.message,
          });
        }
        cb && cb(response);
      })
      .catch((err) => {
        dispatch({
          type: BlobStorageActionType.GET_BLOB_STORAGE_SERVICE_URI_FAILURE,
          payload: err.message,
        });
        cb && cb(err);
      });
  };
