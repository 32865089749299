import { PageRoutesConstant } from "common/router/PageRoutes.constant";
import { useTypedSelector } from "hooks/useTypedSelector";
import React, { FC } from "react";
import { Navigate, Outlet, useLocation } from "react-router";
import { MainHeaderConstants } from "../header/MainHeader.constant";

interface AccessProps {
  roles: string[];
}

const RoleAccess: FC<AccessProps> = ({ roles = [] }) => {
  const { user } = useTypedSelector((state) => state.users);
  const location = useLocation();

  return !roles.length || roles.some((item) => user?.roles?.includes(item)) ? (
    MainHeaderConstants.MenuList.find(
      (p) =>
        location.pathname === p.path ||
        (p.path === PageRoutesConstant.Page.Admin.path &&
          location.pathname.includes(PageRoutesConstant.Page.Admin.path)) ||
        (p.path === PageRoutesConstant.Page.Reports.path &&
          location.pathname.includes(PageRoutesConstant.Page.Reports.path))
    ).roles.some((item) => user?.roles?.includes(item)) ? (
      <Outlet />
    ) : (
      <Navigate to={PageRoutesConstant.Page.AccessDenied.path} replace />
    )
  ) : (
    <Navigate to={PageRoutesConstant.Page.AccessDenied.path} replace />
  );
};

export default RoleAccess;
