import { LineOfBusinessActionType } from "./actionTypes";
import { Action } from "./actions";

export interface LineOfBusinessState {
  loading: boolean;
  error: string | undefined;
  groups: string[] | [];
}

const initialState = {
  loading: false,
  error: undefined,
  groups: [],
};

const LineOfBusinessReducer = (
  state: LineOfBusinessState = initialState,
  action: Action
): LineOfBusinessState => {
  switch (action.type) {
    case LineOfBusinessActionType.GET_LINE_OF_BUSINESS_GROUPS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case LineOfBusinessActionType.GET_LINE_OF_BUSINESS_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        groups: action.payload.data,
      };

    case LineOfBusinessActionType.GET_LINE_OF_BUSINESS_GROUPS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default LineOfBusinessReducer;
