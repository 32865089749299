import React, { FC, useEffect } from "react";
import { Col, List, Row, Spin, Typography } from "antd";
import { AdminDashboardConstants } from "./AdminDashboard.constant";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { hasAccessByPermissions } from "hooks/useAccess";
import { LoadingOutlined } from "@ant-design/icons";
import { useTypedSelector } from "hooks/useTypedSelector";
import { PageRoutesConstant } from "common/router/PageRoutes.constant";
import { AdminFlowRoutesConstant } from "common/router/AdminFlowRoutes.constant";
import { Messages } from "common/constants/Messages";
import { Permissions } from "common/constants/Permissions";
import "./AdminDashboardLayout.style.scss";

const { Title } = Typography;

type ContainerProps = {
  children: React.ReactNode;
};

export const AdminDashboardLayout: FC<ContainerProps> = (
  props: ContainerProps
) => {
  const location = useLocation();
  const { rolePermissionLoading, error, user } = useTypedSelector(
    (state) => state.users
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (
      user?.permissions?.includes(Permissions.UserList) &&
      location.pathname === PageRoutesConstant.Page.Admin.path
    ) {
      navigate(AdminFlowRoutesConstant.Admin.UserList.path);
    }
  }, []);

  useEffect(() => {
    if (error && error === Messages.AccessDenied) {
      navigate(PageRoutesConstant.Page.AccessDenied.path, {
        replace: true,
      });
    }
  }, [error]);

  return (
    <div className="page_layout">
      <Row gutter={[0, 24]}>
        <Col
          xs={{ span: 24, offset: 0 }}
          md={{ span: 6, offset: 1 }}
          xl={{ span: 5 }}
          xxl={{ span: 4, offset: 1 }}
        >
          <div className="admin_dashboard_box_border">
            <div className="admin_dashboard_box_left_menu">
              <Row>
                <Col xs={24}>
                  <Title level={3} className="admin_dashboard_box_title">
                    {AdminDashboardConstants.Title}
                  </Title>
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <Spin
                    size="small"
                    tip="Loading..."
                    spinning={rolePermissionLoading}
                    indicator={<LoadingOutlined />}
                  >
                    <List
                      size="small"
                      itemLayout="horizontal"
                      dataSource={AdminDashboardConstants.MenuList.filter(
                        (ml) => hasAccessByPermissions(ml.permissions)
                      )}
                      renderItem={(item) => (
                        <List.Item className="list_item">
                          <List.Item.Meta
                            title={
                              <Link
                                to={item.path}
                                className={`list_item_link ${
                                  location.pathname === item.path
                                    ? "active"
                                    : ""
                                }`}
                              >
                                {item.title}
                              </Link>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  </Spin>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col
          xs={{ span: 24, offset: 0 }}
          md={{ span: 15, offset: 1 }}
          xl={{ span: 14 }}
          xxl={{ span: 12, offset: 1 }}
        >
          <div className="admin_dashboard_content_box_border">
            <div className="admin_dashboard_content_box_content">
              {props.children}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
