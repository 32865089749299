import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "antd";
import { ReportRoutesConstant } from "../ReportRoutesConstant";
import { PageRoutesConstant } from "../PageRoutes.constant";

const ReportRoutes: FC = () => {
  return (
    <Layout>
      <Routes>
        {Object.keys(ReportRoutesConstant.Report).map((key) => {
          const route = ReportRoutesConstant.Report[key];
          return (
            <Route
              key={key}
              path={route.path.replace(
                PageRoutesConstant.Page.Reports.path,
                ""
              )}
              Component={route.component}
            />
          );
        })}
      </Routes>
    </Layout>
  );
};

export default ReportRoutes;
