import { Content } from "common/interfaces/Content";
import { Theme } from "common/interfaces/Theme";
import React, { FC, createContext } from "react";
import { InitialTheme } from "../theme/InitialTheme";
import { InitialContent } from "../content/InitialContent";

interface IContext {
  theme: Theme;
  content: Content;
}

export const AppContext = createContext<IContext>(undefined);

export const AppProvider: FC<IContext & { children?: React.ReactNode }> = ({
  theme = InitialTheme,
  content = InitialContent,
  children,
}) => {
  const value = { theme, content };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
