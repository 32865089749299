import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Dropdown,
  Form,
  FormInstance,
  Input,
  List,
  MenuProps,
  Popover,
  Row,
  Select,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";
import React, { FC, useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import CustomButton from "common/components/button/CustomButton";
import {
  ArrowLeftOutlined,
  DownOutlined,
  InfoCircleOutlined,
  LeftOutlined,
  LoadingOutlined,
  MenuOutlined,
  RightOutlined,
  UpOutlined,
} from "@ant-design/icons";
import useResponsive from "hooks/useResponsive";
import {
  DATE_FORMAT_DEFAULT,
  FormatCurrencyWithCurrencySymbol,
  FormatDataDate,
  FormatDate,
  ParseDayJsDate,
} from "common/utilities/format";
import { validateStringWithCommas, validateTextOnly } from "hooks/validator";
import {
  ShownFieldsLabel,
  ShownFieldsName,
  TableFieldsName,
  ShownInitialFieldsName,
} from "common/constants/ClaimShownFields";
import { useActions } from "hooks/useActions";
import { useTypedSelector } from "hooks/useTypedSelector";
import {
  ClaimDto,
  ClaimFilterOptionsDto,
  ClaimItemDto,
  ClaimListRequestDto,
  UserSettingsDto,
} from "clients/api.generated.clients";
import {
  FilterValue,
  SortOrder,
  SorterResult,
  TablePaginationConfig,
} from "antd/es/table/interface";
import { SliderIcon, WriteIcon } from "assets/icons";
import { ClaimsConstant } from "./Claims.constant";
import { useNavigate, useParams } from "react-router-dom";
import { Messages } from "common/constants/Messages";
import { PageRoutesConstant } from "common/router/PageRoutes.constant";
import { DownloadFile } from "common/utilities/converter";
import { ReportRoutesConstant } from "common/router/ReportRoutesConstant";
import dayjs from "dayjs";
import { useDebounced } from "hooks/useDebounced";
import "./Claims.style.scss";

const { Title, Paragraph, Text } = Typography;
const { Option } = Select;

type FilterFormValues = {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: dayjs.Dayjs;
  diagnosis?: string;
  type?: string;
};

const EmptyFormFields: FilterFormValues = {
  firstName: undefined,
  lastName: undefined,
  dateOfBirth: undefined,
  diagnosis: undefined,
  type: undefined,
};

type ClaimRow = ClaimDto & {
  key: string;
  viewClaimItems: string;
};

const Claims: FC = () => {
  const { memberId } = useParams();
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  const {
    getAllClaimsWithin3Years,
    setUserSettings,
    getUserSettingsByUserName,
    updateUserSettings,
    getPatientByMemberId,
    exportClaimData,
  } = useActions();
  const getAllClaimsWithin3YearsDebounced = useDebounced(
    200,
    getAllClaimsWithin3Years
  );

  const claims = useTypedSelector((state) => state.claims);
  const users = useTypedSelector((state) => state.users);
  const patients = useTypedSelector((state) => state.patients);

  const [claimWithin3YearsData, setClaimWithin3YearsData] = useState<
    ClaimRow[]
  >([]);

  const [sortedInfo, setSortedInfo] = useState<SorterResult<ClaimRow>>({});

  const [shownFields, setShownFields] = useState<string[]>(
    Array.from(
      new Set(
        Object.values(TableFieldsName).concat(
          users?.settings?.claimShownFields?.length > 0
            ? users.settings.claimShownFields.filter((item) =>
                Object.values(ShownFieldsName).includes(item)
              )
            : Object.values(ShownInitialFieldsName)
        )
      )
    ) ||
      Array.from(
        new Set(
          Object.values(TableFieldsName).concat(Object.values(ShownFieldsName))
        )
      )
  );

  const [claimWithin3YearsRequest, setClaimWithin3YearsRequest] =
    useState<ClaimListRequestDto>(() => {
      const claimsFilterSettings = users?.settings?.claimFilterOptions;
      return {
        shownFields: users?.settings?.claimShownFields || shownFields,
        memberId: memberId,
        page: 1,
        pageSize: 10,
        sortBy: users?.settings?.claimSortOptions?.sortBy,
        sortOrderAsc:
          (users?.settings?.claimSortOptions?.sortOrder as SortOrder) ===
          "ascend",
        firstName: claimsFilterSettings?.firstName,
        lastName: claimsFilterSettings?.lastName,
        dateOfBirth: FormatDataDate(claimsFilterSettings?.dateOfBirth),
        diagnosis: claimsFilterSettings?.diagnosis,
        type: claimsFilterSettings?.type,
      };
    });

  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [updatesMade, setUpdatesMade] = useState<boolean>(false);

  const [resetAllDisable, setResetAllDisable] = useState<boolean>(false);

  const handleModifyShownFields = (value: string[]) => {
    setResetAllDisable(false);
    setShownFields(
      Array.from(new Set(Object.values(TableFieldsName).concat(value)))
    );
    setUserSettings({
      ...users?.settings,
      claimShownFields: Array.from(
        new Set(Object.values(TableFieldsName).concat(value))
      ),
    });
    setClaimWithin3YearsRequest({
      ...claimWithin3YearsRequest,
      shownFields: Array.from(
        new Set(Object.values(TableFieldsName).concat(value))
      ),
    });
  };

  const [expandedViewItemKey, setExpandedViewItemKey] = useState<string>();

  const expandViewItem = (key: string) => {
    if (expandedViewItemKey === key) setExpandedViewItemKey(undefined);
    else setExpandedViewItemKey(key);
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorters: SorterResult<ClaimRow> | SorterResult<ClaimRow>[]
  ) => {
    setResetAllDisable(false);
    const sorter = Array.isArray(sorters) ? sorters[0] : sorters;
    setDefaultCurrent(pagination.current);
    setPageSize(pagination.pageSize);
    setSortedInfo(sorter);
    setUserSettings({
      ...users?.settings,
      claimSortOptions: {
        sortBy: sorter.column ? sorter.columnKey : undefined,
        sortOrder: sorter.order,
      },
    });
    setClaimWithin3YearsRequest({
      ...claimWithin3YearsRequest,
      sortBy: sorter.column ? sorter.columnKey?.toString() : undefined,
      sortOrderAsc: sorter.order === "ascend",
      page: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const [filterForm] = Form.useForm<FilterFormValues>();
  const [isFilterOptionOpen, setIsFilterOptionOpen] =
    useState<boolean>(undefined);

  const [filteredValue, setFilteredValue] = useState<ClaimFilterOptionsDto>({
    firstName: users?.settings?.claimFilterOptions?.firstName,
    lastName: users?.settings?.claimFilterOptions?.lastName,
    dateOfBirth: FormatDataDate(
      users?.settings?.claimFilterOptions?.dateOfBirth,
      true
    ),
    diagnosis: users?.settings?.claimFilterOptions?.diagnosis,
    type: users?.settings?.claimFilterOptions?.type,
  });

  const onFilter = () => {
    setResetAllDisable(false);
    filterForm
      .validateFields()
      .then((values) => {
        setExpandedViewItemKey(undefined);
        setUserSettings({
          ...users?.settings,
          claimFilterOptions: {
            firstName: values.firstName,
            lastName: values.lastName,
            dateOfBirth: FormatDataDate(values.dateOfBirth),
            diagnosis: values.diagnosis,
            type: values.type,
          },
        });
        setFilteredValue({
          firstName: values.firstName,
          lastName: values.lastName,
          dateOfBirth: FormatDataDate(values.dateOfBirth, true),
          diagnosis: values.diagnosis,
          type: values.type,
        });
        setClaimWithin3YearsRequest({
          ...claimWithin3YearsRequest,
          firstName: values.firstName,
          lastName: values.lastName,
          dateOfBirth: FormatDataDate(values.dateOfBirth),
          diagnosis: values.diagnosis,
          type: values.type,
          page: 1,
          pageSize: pageSize,
        });
        setIsFilterOptionOpen(false);
        setDefaultCurrent(1);
      })
      .catch((info) => {
        console.log("Filter Validate Failed:", info);
      });
  };

  const onClearFilter = () => {
    setDefaultCurrent(1);
    setExpandedViewItemKey(undefined);
    setUserSettings({
      ...users?.settings,
      claimFilterOptions: undefined,
    });
    filterForm.setFieldsValue(EmptyFormFields);
    setFilteredValue({});
    setClaimWithin3YearsRequest({
      ...claimWithin3YearsRequest,
      firstName: undefined,
      lastName: undefined,
      dateOfBirth: undefined,
      diagnosis: undefined,
      type: undefined,
      page: 1,
      pageSize: pageSize,
    });
    setIsFilterOptionOpen(false);
    filterForm.validateFields();
  };

  const onResetAll = () => {
    setResetAllDisable(true);
    setDefaultCurrent(1);
    setPageSize(10);
    setExpandedViewItemKey(undefined);
    setShownFields(Object.values(ShownInitialFieldsName));
    setUserSettings({
      ...users?.settings,
      claimShownFields: Array.from(
        new Set(
          Object.values(TableFieldsName).concat(
            Object.values(ShownInitialFieldsName)
          )
        )
      ),
      claimFilterOptions: undefined,
      claimSortOptions: undefined,
    });
    setSortedInfo({});
    setIsFilterOptionOpen(false);
    filterForm.setFieldsValue(EmptyFormFields);
    setFilteredValue({});
    setClaimWithin3YearsRequest({
      ...claimWithin3YearsRequest,
      shownFields: Array.from(
        new Set(
          Object.values(TableFieldsName).concat(Object.values(ShownFieldsName))
        )
      ),
      firstName: undefined,
      lastName: undefined,
      dateOfBirth: undefined,
      diagnosis: undefined,
      type: undefined,
      sortBy: undefined,
      sortOrderAsc: undefined,
      page: 1,
      pageSize: 10,
    });
  };

  const onCloseFilterOptions = () => {
    setIsFilterOptionOpen(false);
  };

  useEffect(() => {
    getUserSettingsByUserName();
  }, []);

  useEffect(() => {
    if (memberId && !patients.loading) {
      getPatientByMemberId({ memberId });
    }
    const firstName = memberId
      ? undefined
      : users?.settings?.claimFilterOptions?.firstName;
    const lastName = memberId
      ? undefined
      : users?.settings?.claimFilterOptions?.lastName;
    const dateOfBirth = memberId
      ? undefined
      : FormatDataDate(users?.settings?.claimFilterOptions?.dateOfBirth, true);
    setFilteredValue({
      ...filteredValue,
      firstName: firstName,
      lastName: lastName,
      dateOfBirth: dateOfBirth,
    });
    setClaimWithin3YearsRequest({
      ...claimWithin3YearsRequest,
      firstName: firstName,
      lastName: lastName,
      dateOfBirth: dateOfBirth,
      memberId: memberId,
    });
  }, [memberId]);

  useEffect(() => {
    if (!users.loading) {
      if (updatesMade) {
        updateUserSettings({
          ...users?.settings,
        });
      }
      setUpdatesMade(true);
    }
    setSortedInfo({
      columnKey: users?.settings?.claimSortOptions?.sortBy,
      order: users?.settings?.claimSortOptions?.sortOrder as SortOrder,
    });
  }, [users?.settings]);

  useEffect(() => {
    if (!claims.loading && Object.keys(claimWithin3YearsRequest).length > 0) {
      getAllClaimsWithin3YearsDebounced({
        ...claimWithin3YearsRequest,
      });
    }
  }, [claimWithin3YearsRequest]);

  useEffect(() => {
    if (claims?.error === Messages.AccessDenied) {
      navigate(PageRoutesConstant.Page.AccessDenied.path, {
        replace: true,
      });
    }
  }, [claims?.error]);

  useEffect(() => {
    setClaimWithin3YearsData(
      Object.values(claims?.data?.items ?? [])?.map((claims: ClaimDto) => ({
        key: claims.id,
        ...claims,
        viewClaimItems: "View Claim Items",
      })) as []
    );
  }, [claims?.data]);

  useEffect(() => {
    setExpandedViewItemKey(undefined);
  }, [isMobile]);

  const columns: ColumnsType<ClaimRow> = [
    {
      title: "Date of Service",
      dataIndex: "serviceDate",
      key: "serviceDate",
      width: 200,
      sorter: claimWithin3YearsData.length > 0 ? true : false,
      sortOrder:
        !claims.loading && sortedInfo.columnKey === "serviceDate"
          ? sortedInfo.order
          : null,
      render: (_, record, index) => {
        return (
          <Text className="claims_cell_text">
            {FormatDate(record.serviceDate, isMobile)}
          </Text>
        );
      },
    },
    {
      title: "RX Description",
      dataIndex: "rxDescription",
      key: "rxDescription",
      responsive: ["md"],
    },
    {
      title: "Diagnostic Label Definition",
      dataIndex: "diagnoses",
      key: "diagnoses",
      render: (_, record) => {
        return (
          <Text className="claims_cell_text">
            {record.diagnoses?.join(", ")}
          </Text>
        );
      },
      responsive: ["md"],
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 120,
      responsive: ["md"],
    },
    {
      title: "View Claim Items",
      dataIndex: "viewClaimItems",
      key: "viewClaimItems",
      width: 200,
      render: (_, record, index) => {
        return (
          <Text
            onClick={() => expandViewItem(record.key)}
            className="claims_cell_text claims_click_view"
          >
            {record.viewClaimItems}{" "}
            {record.key === expandedViewItemKey ? (
              <UpOutlined />
            ) : (
              <DownOutlined />
            )}
          </Text>
        );
      },
    },
  ];

  const onExportDataClick = () => {
    exportClaimData(
      { ...claimWithin3YearsRequest, page: -1, pageSize: -1 },
      (res) => {
        if (res.success) {
          DownloadFile(
            res?.data?.contentType,
            res?.data?.rawData,
            res?.data?.fileName
          );
        } else {
          if (res.message === Messages.AccessDenied) {
            navigate(PageRoutesConstant.Page.AccessDenied.path, {
              replace: true,
            });
          }
          message.error(res.message, 5);
        }
      }
    );
  };

  const items: MenuProps["items"] = [
    {
      label: (
        <CustomButton
          className="reports_header_btn_wrapper_export_data_btn"
          text={
            claims?.exportLoading
              ? "Loading…"
              : ClaimsConstant.ExportDataButtonText
          }
          radius={8}
          onClick={onExportDataClick}
          disabled={claims?.data?.items?.length === 0 || claims?.exportLoading}
          loading={claims?.exportLoading}
        />
      ),
      key: "0",
    },
    // {
    //   label: (
    //     <CustomButton
    //       className="reports_header_btn_wrapper_printtopdf_data_btn"
    //       text={ReportsConstant.PdfToFileButtonText}
    //       radius={8}
    //       disabled={patients?.data?.items?.length === 0}
    //     />
    //   ),
    //   key: "1",
    // },
  ];

  const [openModifyShown, setOpenModifyShown] = useState(false);
  const handleOpenModifyShownChange = (newOpen: boolean) => {
    setOpenModifyShown(newOpen);
  };

  const handleOpenFilterOptionsChange = (newOpen: boolean) => {
    setIsFilterOptionOpen(newOpen);
  };

  const removeFilteredValue = (key: keyof FilterFormValues) => {
    filterForm.setFieldValue(key, undefined);
    const values = filterForm.getFieldsValue();
    setExpandedViewItemKey(undefined);
    setUserSettings({
      ...users?.settings,
      claimFilterOptions: {
        firstName: values.firstName,
        lastName: values.lastName,
        dateOfBirth: FormatDataDate(values.dateOfBirth),
        diagnosis: values.diagnosis,
        type: values.type,
      },
    });
    setFilteredValue({
      firstName: values.firstName,
      lastName: values.lastName,
      dateOfBirth: FormatDataDate(values.dateOfBirth, true),
      diagnosis: values.diagnosis,
      type: values.type,
    });
    setClaimWithin3YearsRequest({
      ...claimWithin3YearsRequest,
      firstName: values.firstName,
      lastName: values.lastName,
      dateOfBirth: FormatDataDate(values.dateOfBirth),
      diagnosis: values.diagnosis,
      type: values.type,
      page: 1,
      pageSize: pageSize,
    });
    setIsFilterOptionOpen(false);
    setDefaultCurrent(1);
  };

  return (
    <div className="page_layout">
      <div className="claims">
        <Row>
          <Col xs={24} className="px-md-4">
            <Row align="middle" gutter={[10, 10]} className="mb-4">
              <Col>
                <Popover
                  arrow={false}
                  placement="bottomRight"
                  content={
                    <ToggleShownFields
                      handleModifyShownFields={handleModifyShownFields}
                      shownFields={shownFields}
                    />
                  }
                  trigger="click"
                  open={openModifyShown}
                  onOpenChange={handleOpenModifyShownChange}
                  className="claims_shown_fields"
                  overlayClassName="claims_shown_fields_popup"
                >
                  <WriteIcon />
                </Popover>
              </Col>
              <Col>
                <div className="divider" />
              </Col>
              <Col>
                <Popover
                  placement="bottomRight"
                  arrow={false}
                  content={
                    <FilterOptions
                      form={filterForm}
                      userSettings={users.settings}
                      onFilter={onFilter}
                      onClearFilter={onClearFilter}
                      onCloseFilterOptions={onCloseFilterOptions}
                    />
                  }
                  trigger="click"
                  open={isFilterOptionOpen}
                  onOpenChange={handleOpenFilterOptionsChange}
                  className="claims_filter_options"
                  overlayClassName="claims_filter_options_popup"
                >
                  <SliderIcon />
                </Popover>
              </Col>
              <Col className="d-flex flex-wrap">
                {filteredValue &&
                  Object.keys(filteredValue).map(
                    (key: keyof FilterFormValues, i) =>
                      filteredValue[key] !== null &&
                      filteredValue[key] !== "" &&
                      filteredValue[key] !== undefined && (
                        <Tag
                          key={i}
                          bordered={false}
                          closable={true}
                          className="filter-val-tag my-1"
                          onClose={() => removeFilteredValue(key)}
                        >
                          {filteredValue[key]}
                        </Tag>
                      )
                  )}
              </Col>
              <Col>
                <Button
                  className="claims_reset_all_button"
                  onClick={onResetAll}
                  size="large"
                  disabled={resetAllDisable}
                  type="text"
                >
                  Reset All
                </Button>
              </Col>
              {memberId && (
                <Col flex="auto" className="d-flex justify-content-end">
                  <CustomButton
                    className="claims_back_to_patients_button"
                    text="Back to Patients"
                    radius={8}
                    font={16}
                    color="#1A1419"
                    onClick={() => {
                      navigate(ReportRoutesConstant.Report.Patients.path);
                    }}
                    icon={<ArrowLeftOutlined />}
                    iconPosition="left"
                  ></CustomButton>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        {memberId && (
          <Row className="claims_patient">
            <Col span={24}>
              <Row>
                <Col>
                  <Title
                    level={2}
                    className="claims_patient_information_title mb-2"
                  >
                    Patient Claims Information
                  </Title>
                </Col>
              </Row>
              <Spin
                size={isMobile ? "small" : "large"}
                tip="Loading..."
                spinning={patients?.loading}
                indicator={<LoadingOutlined />}
              >
                {!patients?.loading && (
                  <Row>
                    <Col xs={24} md={8}>
                      <Row>
                        <Col className="claims_patient_content_text" span={24}>
                          <span className="claims_patient_content_text_highlight">
                            Name:
                          </span>{" "}
                          {patients?.patient?.fullName}
                        </Col>
                        <Col className="claims_patient_content_text" span={24}>
                          <span className="claims_patient_content_text_highlight">
                            MemberID:
                          </span>{" "}
                          {patients?.patient?.memberId}
                        </Col>
                        <Col className="claims_patient_content_text" span={24}>
                          <span className="claims_patient_content_text_highlight">
                            DOB:
                          </span>{" "}
                          {FormatDate(patients?.patient?.birthDate)}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} md={16}>
                      <Row>
                        <Col className="claims_patient_content_text" span={24}>
                          <span className="claims_patient_content_text_highlight">
                            Address:
                          </span>{" "}
                          {[
                            patients?.patient?.address1,
                            patients?.patient?.address2,
                            patients?.patient?.city,
                            patients?.patient?.state,
                            patients?.patient?.zipCode,
                          ]
                            .filter(Boolean)
                            .join(", ")}
                        </Col>
                        <Col className="claims_patient_content_text" span={24}>
                          <span className="claims_patient_content_text_highlight">
                            Payor:
                          </span>{" "}
                          {patients?.patient?.payor}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </Spin>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24}>
            <Table
              showSorterTooltip={false}
              dataSource={claimWithin3YearsData}
              columns={columns}
              expandable={{
                expandedRowRender: (record) => (
                  <ExpandedViewItemRowRender
                    claim={record}
                    shownFields={shownFields}
                  />
                ),
                expandIcon: () => <></>,
                expandedRowKeys: [expandedViewItemKey],
                showExpandColumn: false,
              }}
              onChange={handleTableChange}
              title={() => (
                <Row align="middle">
                  <Col xs={20} sm={18} className="claims_header">
                    <Title level={4} className="mb-0 claims_header_title">
                      {ClaimsConstant.PageTitle}
                    </Title>
                  </Col>
                  <Col sm={6} className="claims_header_btn_wrapper">
                    <CustomButton
                      className="claims_header_btn_wrapper_export_data_btn"
                      text={
                        claims?.exportLoading
                          ? "Loading…"
                          : ClaimsConstant.ExportDataButtonText
                      }
                      onClick={onExportDataClick}
                      disabled={
                        claims?.data?.items?.length === 0 ||
                        claims?.exportLoading
                      }
                      loading={claims?.exportLoading}
                    />
                  </Col>
                  <Col xs={4} className="claims_menu">
                    <Dropdown
                      menu={{ items }}
                      trigger={["click"]}
                      className="claims_header_dropdown"
                      overlayClassName="claims_header_dropdown_overlay"
                    >
                      <MenuOutlined
                        style={{ fontSize: 24, color: "#808080" }}
                      />
                    </Dropdown>
                  </Col>
                </Row>
              )}
              pagination={{
                current: defaultCurrent,
                hideOnSinglePage: true,
                total: claims?.data?.totalCount,
                pageSize: pageSize,
                prevIcon: (
                  <CustomButton
                    iconPosition="left"
                    icon={<LeftOutlined />}
                    className="claims_custom_pagination_btn prev"
                    text={ClaimsConstant.PrevButtonText}
                    font={16}
                    radius={8}
                    width={111}
                  ></CustomButton>
                ),
                nextIcon: (
                  <CustomButton
                    iconPosition="right"
                    icon={<RightOutlined />}
                    className="claims_custom_pagination_btn next"
                    text={ClaimsConstant.NextButtonText}
                    font={16}
                    radius={8}
                    width={111}
                  ></CustomButton>
                ),
              }}
              loading={{
                size: "large",
                tip: "Loading...",
                spinning: claims?.loading,
                indicator: <LoadingOutlined />,
              }}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

type ExpandedViewItemRowRenderProps = {
  claim: ClaimRow;
  shownFields: string[];
};
const ExpandedViewItemRowRender: FC<ExpandedViewItemRowRenderProps> = ({
  claim,
  shownFields,
}) => {
  return (
    <div className="claims_expanded_content">
      <Row gutter={[10, 10]} className="">
        {claim.items.length > 0 ? (
          claim.items.map((item, index) => (
            <ClaimItemRow
              key={index}
              claimItem={item}
              shownFields={shownFields}
            />
          ))
        ) : (
          <Col xs={24} className="claims_expanded_col">
            No Claim Items
          </Col>
        )}
      </Row>
    </div>
  );
};

type ClaimItemRowProps = {
  claimItem: ClaimItemDto;
  shownFields: string[];
};
const ClaimItemRow: FC<ClaimItemRowProps> = ({
  shownFields,
  claimItem: item,
}) => {
  const getFieldDisplay = (field: string, value: unknown) => {
    switch (field as keyof ClaimItemDto) {
      case "paidDate":
        return FormatDate(item[field]);
      case "billedAmount":
      case "eligibleAmount":
      case "paidAmount":
        const numberValue =
          typeof value === "number"
            ? value
            : Number(item[field]?.replace(/[\\$()]/g, ""));
        return FormatCurrencyWithCurrencySymbol(numberValue);
      case "procedureModifiers":
        if (Array.isArray(value)) {
          return value?.join();
        }
        return value ? `${value}` : null;
      default:
        return value ? `${value}` : null;
    }
  };

  return (
    <Col xs={24} className="claims_expanded_col">
      <Row gutter={[5, 5]} className="claims_expanded_row">
        {Object.keys(ShownFieldsLabel).map((field) => {
          if (shownFields.includes(ShownFieldsName[field])) {
            return (
              <Col className="claims_expanded_wrapper" key={field}>
                <Title
                  level={5}
                  className="claims_expanded_name_row_title mb-0"
                >
                  {ShownFieldsLabel[field]}
                </Title>
                <Paragraph className="claims_expanded_name_row_cell_text mb-0">
                  {getFieldDisplay(field, item[field])}
                </Paragraph>
              </Col>
            );
          }
          return null;
        })}
      </Row>
    </Col>
  );
};

type ToggleShownFieldsProps = {
  handleModifyShownFields: (fields: string[]) => void;
  shownFields: string[];
};

const ToggleShownFields: FC<ToggleShownFieldsProps> = ({
  handleModifyShownFields,
  shownFields,
}) => {
  const onCheckChange = (value: string) => {
    handleModifyShownFields(
      shownFields.includes(value)
        ? shownFields.filter((val) => val !== value)
        : [...shownFields, value]
    );
  };

  return (
    <>
      <List
        size="small"
        header={
          <Title level={5} className="mb-0">
            Fields
          </Title>
        }
        className="fields_list"
        dataSource={Object.keys(ShownFieldsLabel)}
        renderItem={(item) => {
          return (
            <List.Item className="d-flex align-items-center">
              <Checkbox
                className="fields_checkbox"
                onChange={() => onCheckChange(ShownFieldsName[item])}
                defaultChecked={shownFields.includes(ShownFieldsName[item])}
                checked={shownFields.includes(ShownFieldsName[item])}
              >
                {ShownFieldsLabel[item]}
              </Checkbox>
            </List.Item>
          );
        }}
      />
    </>
  );
};

type FilterOptionsProps = {
  form: FormInstance;
  userSettings: UserSettingsDto;
  onFilter: () => void;
  onClearFilter: () => void;
  onCloseFilterOptions: () => void;
};

const FilterOptions: FC<FilterOptionsProps> = ({
  form,
  userSettings,
  onFilter,
  onClearFilter,
  onCloseFilterOptions,
}) => {
  const { memberId } = useParams();
  const { getClaimTypes } = useActions();
  const claims = useTypedSelector((state) => state.claims);

  useEffect(() => {
    getClaimTypes();
  }, []);

  return (
    <div className="p-3">
      <Form form={form}>
        <Row gutter={[15, 10]}>
          <Col xs={24}>
            <Row gutter={[7, 12]}>
              {!memberId && (
                <>
                  <Col span={24}>
                    <Title
                      level={5}
                      className="mb-0 claims_filter_options_title"
                    >
                      First Name
                    </Title>
                    <Form.Item
                      name="firstName"
                      label=""
                      className="mb-0"
                      initialValue={
                        userSettings?.claimFilterOptions?.firstName || undefined
                      }
                      rules={[
                        {
                          required: false,
                          validator: validateTextOnly,
                          validateTrigger: "onBlur",
                        },
                      ]}
                    >
                      <Input
                        autoComplete="off"
                        className="claims_filter_options_first_name"
                        placeholder="First Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Title
                      level={5}
                      className="mb-0 claims_filter_options_title"
                    >
                      Last Name
                    </Title>
                    <Form.Item
                      name="lastName"
                      label=""
                      className="mb-0"
                      initialValue={
                        userSettings?.claimFilterOptions?.lastName || undefined
                      }
                      rules={[
                        {
                          required: false,
                          validator: validateTextOnly,
                          validateTrigger: "onBlur",
                        },
                      ]}
                    >
                      <Input
                        autoComplete="off"
                        placeholder="Last Name"
                        className="claims_filter_options_last_name"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Title
                      level={5}
                      className="mb-0 claims_filter_options_title"
                    >
                      Date of Birth
                    </Title>
                    <Form.Item
                      name="dateOfBirth"
                      label=""
                      className="mb-0"
                      initialValue={ParseDayJsDate(
                        userSettings?.claimFilterOptions?.dateOfBirth
                      )}
                    >
                      <DatePicker
                        size="large"
                        className="claims_filter_options_custom_date_picker"
                        format={DATE_FORMAT_DEFAULT}
                        placeholder={DATE_FORMAT_DEFAULT}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
              <Col span={24}>
                <Title level={5} className="mb-0 claims_filter_options_title">
                  Diagnosis
                </Title>
                <Form.Item
                  name="diagnosis"
                  label=""
                  className="mb-0"
                  initialValue={userSettings?.claimFilterOptions?.diagnosis}
                  rules={[
                    {
                      required: false,
                      validator: validateStringWithCommas,
                      validateTrigger: "onBlur",
                    },
                  ]}
                >
                  <Input
                    suffix={
                      <Tooltip
                        title="Enter multiple values separated by commas"
                        color="#50834B"
                      >
                        <InfoCircleOutlined className="claims_filter_options_info_icon" />
                      </Tooltip>
                    }
                    autoComplete="off"
                    placeholder="Diagnosis"
                    className="claims_filter_options_diagnosis"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Title level={5} className="mb-0 claims_filter_options_title">
                  Type
                </Title>
                <Form.Item
                  name="type"
                  label=""
                  className="mb-0"
                  initialValue={userSettings?.claimFilterOptions?.type}
                >
                  <Select
                    placeholder="View All"
                    className="claims_filter_options_type"
                    popupClassName="claims_filter_options_type_popup"
                    size="large"
                    allowClear
                  >
                    {claims?.types?.map((type) => (
                      <Option key={type} value={type}>
                        {type}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[5, 5]}>
              <Col xs={24}>
                <CustomButton
                  className="claims_filter_options_custom_button"
                  text="Filter"
                  backgroundColor="#B8C7B9"
                  radius={8}
                  font={16}
                  color="#1A1419"
                  buttonType="submit"
                  onClick={onFilter}
                ></CustomButton>
              </Col>
              <Col xs={24}>
                <Row gutter={[5, { xs: 5, md: 0 }]}>
                  <Col xs={12} sm={12}>
                    <CustomButton
                      className="claims_filter_options_custom_button"
                      backgroundColor="#B8C7B9"
                      text="Clear"
                      radius={8}
                      font={16}
                      color="#1A1419"
                      onClick={onClearFilter}
                    ></CustomButton>
                  </Col>
                  <Col xs={12}>
                    <CustomButton
                      className="claims_filter_options_custom_button"
                      backgroundColor="#B8C7B9"
                      text="Close"
                      radius={8}
                      font={16}
                      color="#1A1419"
                      onClick={onCloseFilterOptions}
                    ></CustomButton>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Claims;
