import { Button, Col, Row, Typography } from "antd";
import React, { FC } from "react";
import { FallbackProps } from "react-error-boundary";
import "./ErrorFallback.style.scss";

const { Paragraph, Title, Text } = Typography;

const ErrorFallback: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <div className="error_fallback">
      <Row>
        <Col span={24}>
          <div className="error_fallback_wrapper">
            <Row justify="center">
              <Col xs={24} className="error_fallback_img">
                <img src="images/coipa/logo.png" alt="logo" />
              </Col>
              <Col xs={24} className="error_fallback_title">
                <Title level={2} className="error_fallback_title_text mb-0">
                  Something went wrong!
                </Title>
              </Col>
              <Col xs={24} md={13}>
                <Paragraph className="error_fallback_text">
                  <Text strong className="error_fallback_text">
                    Error Message -
                  </Text>{" "}
                  {error.message}
                </Paragraph>
              </Col>
              <Col xs={24} className="text-center">
                <Button
                  type="primary"
                  size="large"
                  className="error_fallback_btn"
                  onClick={resetErrorBoundary}
                >
                  Go to Home
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ErrorFallback;
