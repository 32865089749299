import {
  Row,
  Col,
  Typography,
  Form,
  Select,
  Input,
  Checkbox,
  Button,
  Modal,
  Spin,
  Space,
  message,
} from "antd";
import React, { FC, useEffect, useState } from "react";
import { AdjustRolePermissionConstant } from "./AdjustRolePermission.constant";
import CustomButton from "common/components/button/CustomButton";
import DeleteRole from "../delete/DeleteRole";
import { useActions } from "hooks/useActions";
import { useTypedSelector } from "hooks/useTypedSelector";
import { PermissionDto, RoleDto } from "clients/api.generated.clients";
import { LoadingOutlined } from "@ant-design/icons";
import { validateRequired } from "hooks/validator";
import { Messages } from "common/constants/Messages";
import { useNavigate } from "react-router-dom";
import { PageRoutesConstant } from "common/router/PageRoutes.constant";
import { Permissions } from "common/constants/Permissions";
import { hasAccessByPermissions } from "hooks/useAccess";
import "./AdjustRolePermission.style.scss";

const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const AdjustRolePermission: FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const {
    getPermissions,
    getRoles,
    updateRole,
    getUserNamesByRoleId,
    getUserRolesAndPermissionsByUserName,
  } = useActions();
  const [roleList, setRoleList] = useState<RoleDto[]>([]);
  const { roles, permissions, users } = useTypedSelector((state) => state);
  const [permissionList, setPermissionList] = useState<
    { label: string; value: string }[]
  >([]);
  const [isDeleteButtonEnabled, setIsDeleteButtonEnabled] =
    useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const hasAccess = hasAccessByPermissions(Permissions.AdjustRolePermissions);

  useEffect(() => {
    if (!hasAccess) {
      navigate(PageRoutesConstant.Page.AccessDenied.path, {
        replace: true,
      });
    }
    getRoles({});
    getPermissions({});
  }, []);

  useEffect(() => {
    setRoleList(
      Object.values(roles?.data?.items ?? [])?.map(
        (role: RoleDto) => role
      ) as RoleDto[]
    );
  }, [roles]);

  useEffect(() => {
    const newPermissionList = (permissions?.data?.items ?? []).map(
      ({ id, name }: PermissionDto) => ({
        label: name,
        value: id,
      })
    );
    setPermissionList(newPermissionList);
  }, [permissions]);

  let role: RoleDto;
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRoleChange = (value) => {
    role = roleList.find((r) => r.id === value?.value);
    if (role) {
      setIsDeleteButtonEnabled(true);
      getUserNamesByRoleId(role.id);
      form.setFieldValue("description", role?.description);
      form.setFieldValue("permissions", role?.permissions);
    } else {
      setIsDeleteButtonEnabled(false);
      form.setFieldValue("name", undefined);
      form.setFieldValue("description", undefined);
      form.setFieldValue("permissions", undefined);
    }
  };

  useEffect(() => {
    setIsDelete(users.userNames.length === 0);
  }, [users.userNames]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onDeleteRolePopUpClose = () => {
    handleCancel();
    form.resetFields();
    getRoles({});
  };

  const onUpdateRole = () => {
    setIsLoading(true);
    form
      .validateFields()
      .then((values) => {
        updateRole(
          { ...values, ...{ id: values.name.value, name: values.name.label } },
          (res) => {
            if (res.success) {
              form.resetFields();
              getRoles({});
              message.success(
                AdjustRolePermissionConstant.SuccessMessageText,
                5
              );
              getUserRolesAndPermissionsByUserName();
            } else {
              if (res.message === Messages.AccessDenied) {
                navigate(PageRoutesConstant.Page.AccessDenied.path, {
                  replace: true,
                });
              }
              message.error(res.message, 5);
            }
            setIsLoading(false);
          }
        );
      })
      .catch((info) => {
        setIsLoading(false);
        console.log("Validate Failed:", info);
      });
  };

  return (
    <div className="adjust_role_content">
      <Form form={form} layout="vertical" onFinish={onUpdateRole}>
        <Row className="adjust_role_wrap" align="middle">
          <Col xs={18}>
            <Title level={3} className="adjust_role_title mt-1 ">
              {AdjustRolePermissionConstant.PageTitle}
            </Title>
          </Col>
          <Col xs={6}>
            <Button
              onClick={showModal}
              type="text"
              className="adjust_role_wrap_delete_role"
              disabled={!isDeleteButtonEnabled}
            >
              Delete Role
            </Button>
          </Col>
        </Row>
        <Col xs={24} xl={14} xxl={16}>
          <Form.Item
            name="name"
            label=""
            rules={[{ required: true, validator: validateRequired }]}
          >
            <Select
              labelInValue={true}
              loading={roles.loading}
              className="adjust_role_dropdown"
              placeholder="Select Role"
              allowClear
              onChange={handleRoleChange}
              size="large"
            >
              {roleList.map((role) => (
                <Option key={role.id} value={role.id}>
                  {role.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} xl={14} xxl={16}>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, validator: validateRequired }]}
          >
            <TextArea className="adjust_role_textarea" rows={4} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Title level={5} className="adjust_role_permission_title">
            {AdjustRolePermissionConstant.PermissionTitle}
          </Title>
        </Col>
        <Col span={24}>
          <Spin
            size="large"
            tip="Loading..."
            spinning={permissions.loading}
            indicator={<LoadingOutlined />}
          >
            <Form.Item name="permissions">
              <Checkbox.Group>
                <Space direction="vertical">
                  {permissionList.map(({ label, value }) => (
                    <Checkbox
                      key={value}
                      value={value}
                      defaultChecked={true}
                      className="create_role_permission_name"
                    >
                      {label}
                    </Checkbox>
                  ))}
                </Space>
              </Checkbox.Group>
            </Form.Item>
          </Spin>
        </Col>
        <Row align="middle" justify="end">
          <Col>
            <CustomButton
              className="adjust_role_custombutton"
              type="link"
              backgroundColor="#B8C7B9"
              text={AdjustRolePermissionConstant.SubmitButtonText}
              fontColor="#000000"
              radius={8}
              width={128}
              font={16}
              height={40}
              buttonType="submit"
              onClick={onUpdateRole}
              disabled={isLoading}
            />
          </Col>
        </Row>
      </Form>
      <div>
        <Modal
          destroyOnClose={true}
          open={isModalOpen}
          onCancel={handleCancel}
          centered
          closable={true}
          footer={null}
        >
          <DeleteRole
            roleId={form.getFieldValue("name")?.value}
            onPopupClose={isDelete ? onDeleteRolePopUpClose : handleCancel}
          />
        </Modal>
      </div>
    </div>
  );
};

export default AdjustRolePermission;
