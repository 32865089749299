import {
  ClaimDto,
  PaginationResponseDtoOfClaimDto,
} from "clients/api.generated.clients";
import { Action } from "./actions";
import { ClaimActionType } from "./actionTypes";

export interface ClaimsState {
  loading: boolean;
  error: string | undefined;
  mostRecentClaim: ClaimDto | undefined;
  claimsWithin3Years: ClaimDto[] | [];
  data: PaginationResponseDtoOfClaimDto | undefined;
  exportLoading: boolean;
  loadingTypes: boolean;
  types: string[] | [];
}

const initialState = {
  loading: false,
  error: undefined,
  mostRecentClaim: undefined,
  claimsWithin3Years: [],
  data: undefined,
  exportLoading: false,
  loadingTypes: false,
  types: [],
};

const ClaimReducer = (
  state: ClaimsState = initialState,
  action: Action
): ClaimsState => {
  switch (action.type) {
    case ClaimActionType.GET_MOST_RECENT_CLAIM:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case ClaimActionType.GET_MOST_RECENT_CLAIM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        mostRecentClaim: action.payload.data,
      };

    case ClaimActionType.GET_MOST_RECENT_CLAIM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ClaimActionType.GET_CLAIMS_WITHIN_3_YEARS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case ClaimActionType.GET_CLAIMS_WITHIN_3_YEARS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        claimsWithin3Years: action.payload.data,
      };

    case ClaimActionType.GET_CLAIMS_WITHIN_3_YEARS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ClaimActionType.GET_ALL_CLAIMS_WITHIN_3_YEARS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case ClaimActionType.GET_ALL_CLAIMS_WITHIN_3_YEARS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload.data,
      };

    case ClaimActionType.GET_ALL_CLAIMS_WITHIN_3_YEARS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ClaimActionType.EXPORT_DATA_EXCEL:
      return {
        ...state,
        exportLoading: true,
        error: undefined,
      };

    case ClaimActionType.EXPORT_DATA_EXCEL_SUCCESS:
      return {
        ...state,
        exportLoading: false,
        error: undefined,
      };

    case ClaimActionType.EXPORT_DATA_EXCEL_FAILURE:
      return {
        ...state,
        exportLoading: false,
        error: action.payload,
      };

    case ClaimActionType.GET_CLAIM_TYPES:
      return {
        ...state,
        loadingTypes: true,
        error: undefined,
      };

    case ClaimActionType.GET_CLAIM_TYPES_SUCCESS:
      return {
        ...state,
        loadingTypes: false,
        error: undefined,
        types: action.payload.data,
      };

    case ClaimActionType.GET_CLAIM_TYPES_FAILURE:
      return {
        ...state,
        loadingTypes: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default ClaimReducer;
