import Claims from "pages/claims/Claims";
import { PageRoutesConstant } from "./PageRoutes.constant";
import Patients from "pages/patients/Patients";
import AnalysisReport from "pages/reports/analysis/AnalysisReport";

interface RouteKey {
  [key: string]: {
    path: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: any;
  };
}

interface IRoute {
  Report: RouteKey;
}

export const ReportRoutesConstant: IRoute = {
  Report: {
    Patients: {
      path: `${PageRoutesConstant.Page.Reports.path}/patients`,
      component: Patients,
    },
    Claims: {
      path: `${PageRoutesConstant.Page.Reports.path}/claims`,
      component: Claims,
    },
    ClaimsByMemberId: {
      path: `${PageRoutesConstant.Page.Reports.path}/claims/:memberId`,
      component: Claims,
    },
    Analysis: {
      path: `${PageRoutesConstant.Page.Reports.path}/analysis`,
      component: AnalysisReport
    }
  },
};
