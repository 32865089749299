export const Permissions = {
  UserList: "user.list",
  AddUsers: "user.add",
  DeleteUsers: "user.delete",
  CreateRoles: "role.create",
  AdjustRolePermissions: "role.adjust.permissions",
  ViewHistory: "history.view",
  A1CReports: "report.a1c.view",
  PayorReports: "report.payor.view",
  ClaimsReports: "report.claims.view",
  WellnessVisitStatusReports: "report.wellness.visit.status.view",
  MemberExtractData: "visibility.member.extract.data",
  ClaimsData: "visibility.claims.data",
  RxClaimsData: "visibility.rx.claims.data",
  PatientEHRClinicalData: "visibility.patient.ehr.clinical.data",
  UploadedReports: "uploaded.reports.view",
  ViewTargets: "targets.view",
  EditTargets: "targets.edit",
};
