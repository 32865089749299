import { BlobStorageDto } from "clients/api.generated.clients";
import { Action } from "./action";
import { BlobStorageActionType } from "./actionTypes";

export interface BlobStorageState {
  loading: boolean;
  error: string | undefined;
  data: BlobStorageDto | undefined;
}

const initialState = {
  loading: false,
  error: undefined,
  data: undefined,
};

const BlobStorageReducer = (
  state: BlobStorageState = initialState,
  action: Action
): BlobStorageState => {
  switch (action.type) {
    case BlobStorageActionType.GET_BLOB_STORAGE_SERVICE_URI:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case BlobStorageActionType.GET_BLOB_STORAGE_SERVICE_URI_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload.data,
      };

    case BlobStorageActionType.GET_BLOB_STORAGE_SERVICE_URI_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default BlobStorageReducer;
