import { httpWithTokenInHeader } from "clients/api.clients.base";
import { RoleClient, RoleListRequestDto } from "clients/api.generated.clients";
import { Dispatch } from "react";
import { RoleActionType } from "store/role/actionTypes";
import { Action } from "store/role/actions";

const roleClient = new RoleClient("", httpWithTokenInHeader);

export const createRole =
  (values: any, cb?: (res) => void) => async (dispatch: Dispatch<Action>) => {
    const role = {
      ...values,
    };

    dispatch({
      type: RoleActionType.CREATE_ROLE,
    });

    await roleClient
      .createRole(role)
      .then((response) => {
        if (response.success) {
          dispatch({
            type: RoleActionType.CREATE_ROLE_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: RoleActionType.CREATE_ROLE_FAILURE,
            payload: response?.message,
          });
        }
        cb && cb(response);
      })
      .catch((err) => {
        dispatch({
          type: RoleActionType.CREATE_ROLE_FAILURE,
          payload: err.message,
        });
        cb && cb(err);
      });
  };

export const updateRole =
  (values: any, cb?: (res) => void) => async (dispatch: Dispatch<Action>) => {
    const updateRole = {
      ...values,
    };

    dispatch({
      type: RoleActionType.UPDATE_ROLE,
    });

    await roleClient
      .updateRole(updateRole)
      .then((response) => {
        if (response.success) {
          dispatch({
            type: RoleActionType.UPDATE_ROLE_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: RoleActionType.UPDATE_ROLE_FAILURE,
            payload: response?.message,
          });
        }
        cb && cb(response);
      })
      .catch((err) => {
        dispatch({
          type: RoleActionType.UPDATE_ROLE_FAILURE,
          payload: err.message,
        });
        cb && cb(err);
      });
  };

export const getRoles =
  (values: RoleListRequestDto) => async (dispatch: Dispatch<Action>) => {
    const roleListRequest = {
      ...values,
      page: values.page || -1,
      pageSize: values.pageSize || -1,
    };

    dispatch({
      type: RoleActionType.GET_ROLES,
    });
    await roleClient
      .getAllRoles(roleListRequest)
      .then((response) => {
        if (response.success) {
          dispatch({
            type: RoleActionType.GET_ROLES_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: RoleActionType.GET_ROLES_FAILURE,
            payload: response?.message,
          });
        }
      })
      .catch((err) =>
        dispatch({
          type: RoleActionType.GET_ROLES_FAILURE,
          payload: err.message,
        })
      );
  };

export const deleteRole =
  (value: any, cb?: (res) => void) => async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: RoleActionType.DELETE_ROLE,
    });

    await roleClient
      .deleteRole(value)
      .then((response) => {
        if (response.success) {
          dispatch({
            type: RoleActionType.DELETE_ROLE_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: RoleActionType.DELETE_ROLE_FAILURE,
            payload: response?.message,
          });
        }
        cb && cb(response);
      })
      .catch((err) => {
        dispatch({
          type: RoleActionType.DELETE_ROLE_FAILURE,
          payload: err.message,
        });
        cb && cb(err);
      });
  };
