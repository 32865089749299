import { PayorActionType } from "./actionTypes";
import { Action } from "./actions";

export interface PayorsState {
  loading: boolean;
  error: string | undefined;
  data: string[] | [];
}

const initialState = {
  loading: false,
  error: undefined,
  data: [],
};

const PayorReducer = (
  state: PayorsState = initialState,
  action: Action
): PayorsState => {
  switch (action.type) {
    case PayorActionType.GET_PAYORS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case PayorActionType.GET_PAYORS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload.data,
      };

    case PayorActionType.GET_PAYORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default PayorReducer;
