import React, { FC, useEffect, useMemo, useState } from "react";
import { Col, Row, Select, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import useResponsive from "hooks/useResponsive";
import { FormatDateTime } from "common/utilities/format";
import { useActions } from "hooks/useActions";
import { useTypedSelector } from "hooks/useTypedSelector";
import { ChangesHistoryDto, TimeRange } from "clients/api.generated.clients";
import {
  LeftOutlined,
  LoadingOutlined,
  RightOutlined,
} from "@ant-design/icons";
import CustomButton from "common/components/button/CustomButton";
import { Messages } from "common/constants/Messages";
import { PageRoutesConstant } from "common/router/PageRoutes.constant";
import { useNavigate } from "react-router-dom";
import { Permissions } from "common/constants/Permissions";
import { hasAccessByPermissions } from "hooks/useAccess";
import "./AdminChangesHistory.scss";

const { Title, Text } = Typography;
const { Option } = Select;

const TimeRangeOptions = [
  { value: TimeRange.LastYear, label: "Last Year" },
  { value: TimeRange.LastSixMonths, label: "Last 6 Months" },
  { value: TimeRange.LastQuarter, label: "Last Quarter" },
  { value: TimeRange.LastMonth, label: "Last Month" },
  { value: TimeRange.LastWeek, label: "Last Week" },
];

const AdminChangesHistory: FC = () => {
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  const { getAllChangesHistory } = useActions();

  const changesHistory = useTypedSelector((state) => state.changesHistory);
  const hasAccess = hasAccessByPermissions(Permissions.ViewHistory);

  useEffect(() => {
    if (changesHistory?.error === Messages.AccessDenied || !hasAccess) {
      navigate(PageRoutesConstant.Page.AccessDenied.path, {
        replace: true,
      });
    }
  }, [changesHistory?.error]);

  const historyList = useMemo(() => {
    return (changesHistory?.data?.items ?? [])?.map(
      (history: ChangesHistoryDto) => ({
        key: history.id,
        ...history,
      })
    );
  }, [changesHistory]);

  const [timeRange, setTimeRange] = useState(undefined);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [defaultCurrent, setDefaultCurrent] = useState(1);

  useEffect(() => {
    setExpandedRowKeys(historyList.map((history) => history.key.toString()));
  }, [historyList]);

  useEffect(() => {
    getAllChangesHistory({ timeRange: timeRange });
  }, [timeRange]);

  const onChangeTimeRange = (value: TimeRange) => {
    setTimeRange(value);
    setDefaultCurrent(1);
  };

  const handlePagination = (page) => {
    setDefaultCurrent(page);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record, index) => {
        return (
          <Text className="adminchangeshistory_table_cell_text">
            {FormatDateTime(record.createdAt, isMobile)?.date ?? ""}
          </Text>
        );
      },
    },
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record, index) => {
        return (
          <Text className="adminchangeshistory_table_cell_text">
            {FormatDateTime(record.createdAt, isMobile)?.time ?? ""}
          </Text>
        );
      },
    },
    {
      title: "User",
      dataIndex: "createdBy",
      key: "createdBy",
      ellipsis: true,
    },
    {
      title: "Change Made",
      dataIndex: "changeMade",
      key: "changeMade",
      responsive: ["sm"],
      width: 500,
    },
  ];

  const handleTableChange: any = (pagination, filters, sorter) => {
    setDefaultCurrent(pagination.current);
    getAllChangesHistory({
      timeRange: timeRange,
      page: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  return (
    <div>
      <Row className="adminchangeshistory">
        <Col xs={24}>
          <Title className="adminchangeshistory_title" level={2}>
            Admin Changes History
          </Title>
        </Col>
        <Col xs={24}>
          <Select
            placeholder="View All Changes"
            size="large"
            className="adminchangeshistory_dropdown"
            allowClear
            onChange={onChangeTimeRange}
          >
            {TimeRangeOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={24}>
          <Table
            tableLayout="auto"
            size="small"
            className="adminchangeshistory_table"
            pagination={{
              total: changesHistory?.data?.totalCount,
              hideOnSinglePage: true,
              responsive: true,
              current: defaultCurrent,
              onChange: handlePagination,
              prevIcon: (
                <CustomButton
                  iconPosition="left"
                  icon={<LeftOutlined />}
                  className="adminchangeshistory_custom_pagination_btn prev"
                  text="Prev"
                  font={14}
                  radius={8}
                  width={111}
                />
              ),
              nextIcon: (
                <CustomButton
                  iconPosition="right"
                  icon={<RightOutlined />}
                  className="adminchangeshistory_custom_pagination_btn next"
                  text="Next"
                  font={14}
                  radius={8}
                  width={111}
                />
              ),
            }}
            columns={columns}
            onChange={handleTableChange}
            dataSource={historyList}
            expandable={{
              expandedRowKeys: window.innerWidth < 576 ? expandedRowKeys : [],
              onExpand: (expanded, record) => {
                if (expanded) {
                  setExpandedRowKeys([
                    ...expandedRowKeys,
                    record.key.toString(),
                  ]);
                } else {
                  setExpandedRowKeys(
                    expandedRowKeys.filter(
                      (key) => key !== record.key.toString()
                    )
                  );
                }
              },
              expandedRowRender: (record) =>
                isMobile ? (
                  <Row>
                    <Col className="adminchangeshistory_expanded" span={24}>
                      {record.changeMade}
                    </Col>
                  </Row>
                ) : null,
              rowExpandable: () => isMobile,
              expandIcon: () => <></>,
              showExpandColumn: false,
            }}
            loading={{
              size: "large",
              tip: "Loading...",
              spinning: changesHistory.loading,
              indicator: <LoadingOutlined />,
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AdminChangesHistory;
