import {
  CreateRole,
  UserList,
  AdjustRolePermission,
  AddUser,
  AdminChangesHistory,
} from "pages/admin";
import { PageRoutesConstant } from "./PageRoutes.constant";
import UploadedReports from "pages/reports/uploaded/UploadedReports";
import Targets from "pages/admin/targets/Targets";

interface RouteKey {
  [key: string]: {
    path: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component?: any;
  };
}

interface IRoute {
  Admin: RouteKey;
}

export const AdminFlowRoutesConstant: IRoute = {
  Admin: {
    UserList: {
      path: `${PageRoutesConstant.Page.Admin.path}/user-list`,
      component: UserList,
    },
    AddUser: {
      path: `${PageRoutesConstant.Page.Admin.path}/add-user`,
      component: AddUser,
    },
    CreateRole: {
      path: `${PageRoutesConstant.Page.Admin.path}/create-role`,
      component: CreateRole,
    },
    AdjustRolePermission: {
      path: `${PageRoutesConstant.Page.Admin.path}/adjust-role-permission`,
      component: AdjustRolePermission,
    },
    AdminChangesHistory: {
      path: `${PageRoutesConstant.Page.Admin.path}/view-history`,
      component: AdminChangesHistory,
    },
    UploadedReports: {
      path: `${PageRoutesConstant.Page.Admin.path}/uploaded-reports`,
      component: UploadedReports,
    },
    Targets: {
      path: `${PageRoutesConstant.Page.Admin.path}/targets`,
      component: Targets,
    },
  },
};
