export enum PatientActionType {
  GET_PATIENTS = "GET_PATIENTS",
  GET_PATIENTS_SUCCESS = "GET_PATIENTS_SUCCESS",
  GET_PATIENTS_FAILURE = "GET_PATIENTS_FAILURE",
  EXPORT_DATA_EXCEL = "EXPORT_DATA_EXCEL",
  EXPORT_DATA_EXCEL_SUCCESS = "EXPORT_DATA_EXCEL_SUCCESS",
  EXPORT_DATA_EXCEL_FAILURE = "EXPORT_DATA_EXCEL_FAILURE",
  SEARCH_PATIENTS = "SEARCH_PATIENTS",
  SEARCH_PATIENTS_SUCCESS = "SEARCH_PATIENTS_SUCCESS",
  SEARCH_PATIENTS_FAILURE = "SEARCH_PATIENTS_FAILURE",
  GET_PATIENT_BY_MEMBERID = "GET_PATIENT_BY_MEMBERID",
  GET_PATIENT_BY_MEMBERID_SUCCESS = "GET_PATIENT_BY_MEMBERID_SUCCESS",
  GET_PATIENT_BY_MEMBERID_FAILURE = "GET_PATIENT_BY_MEMBERID_FAILURE",
}
