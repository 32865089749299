import { httpWithTokenInHeader } from "clients/api.clients.base";
import {
  PermissionClient,
  PermissionListRequestDto,
} from "clients/api.generated.clients";
import { Dispatch } from "react";
import { PermissionActionType } from "store/permission/actionTypes";
import { Action } from "store/permission/actions";

const permissionClient = new PermissionClient("", httpWithTokenInHeader);

export const getPermissions =
  (values: PermissionListRequestDto) => async (dispatch: Dispatch<Action>) => {
    const permissionListRequest = {
      ...values,
      page: values.page || -1,
      pageSize: values.pageSize || -1,
    };
    dispatch({
      type: PermissionActionType.GET_PERMISSIONS,
    });
    await permissionClient
      .getAllPermissions(permissionListRequest)
      .then((response) => {
        if (response.success) {
          dispatch({
            type: PermissionActionType.GET_PERMISSIONS_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: PermissionActionType.GET_PERMISSIONS_FAILURE,
            payload: response?.message,
          });
        }
      })
      .catch((err) =>
        dispatch({
          type: PermissionActionType.GET_PERMISSIONS_FAILURE,
          payload: err.message,
        })
      );
  };
