export const DeleteRoleConstant = {
  PageTitle: "Delete Role",
  UserListText:
    "The following users are currently assigned this role and must be assigned a new role before continuing.",
  UsersTitle: "User(s)",
  ListButtonText: "User List",
  CancelButtonText: "Cancel",
  DeleteRoleText: "This action cannot be undone once completed.",
  DeleteButtonText: "Delete Role",
  SuccessMessageText: "Role deleted successfully!",
};
