import React from "react";
import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const LeafSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M7 21C7 21 7.5 16.5 11 12.5"
      stroke="#50834B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.1298 4.24198L19.7238 10.417C20.0978 14.303 17.1838 17.763 13.2988 18.137C9.48578 18.504 6.03178 15.717 5.66478 11.904C5.48843 10.0729 6.04667 8.24681 7.21671 6.82735C8.38674 5.40789 10.0727 4.51136 11.9038 4.33498L18.4748 3.70298C18.5533 3.69539 18.6324 3.70335 18.7078 3.72638C18.7832 3.74942 18.8534 3.78709 18.9142 3.83724C18.975 3.88739 19.0254 3.94904 19.0624 4.01866C19.0994 4.08828 19.1223 4.1635 19.1298 4.24198Z"
      stroke="#50834B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LeafIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={LeafSvg} {...props} />
);