import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const DATE_FORMAT_DATA = "YYYY-MM-DD";
export const DATE_FORMAT_MOBILE = "MM/DD/YY";
export const DATE_FORMAT_DEFAULT = "MM/DD/YYYY";
const TIME_FORMAT = "hh:mm";

/* Reads only the date off of a value and builds a new days.Dayjs object
 * at midnight UTC on that date
 */
const toUTCDate = (
  value: string | dayjs.Dayjs | Date | undefined
): dayjs.Dayjs | undefined => {
  if (!value) return undefined;
  const date = dayjs(value);
  return dayjs(
    new Date(Date.UTC(date.year(), date.month(), date.date()))
  ).utc();
};

export const FormatDataDate = (
  value: string | dayjs.Dayjs | Date | undefined,
  isDisplayOnly = false
): string | undefined => {
  const utcDate = toUTCDate(value);
  return utcDate?.format(
    isDisplayOnly ? DATE_FORMAT_DEFAULT : DATE_FORMAT_DATA
  );
};

export const FormatDate = (
  value: string | dayjs.Dayjs | Date | undefined,
  isMobile = false
): string => {
  if (!value) return "";
  const format = isMobile ? DATE_FORMAT_MOBILE : DATE_FORMAT_DEFAULT;
  let parsed = dayjs(value).utc();

  const parsedUtc = parsed.utc();
  const isDateOnly =
    parsedUtc.hour() == 0 && parsedUtc.minute() == 0 && parsedUtc.second() == 0;
  if (isDateOnly) {
    parsed = parsedUtc;
  }
  return parsed.format(format);
};

export const FormatDateTime = (
  value: string | undefined,
  isMobile: boolean
): { date: string; time: string } | undefined => {
  if (!value) return undefined;
  const dateFormat = isMobile ? DATE_FORMAT_MOBILE : DATE_FORMAT_DEFAULT;
  const dateTime = dayjs(value);
  return {
    date: dateTime.format(dateFormat),
    time: dateTime.format(TIME_FORMAT),
  };
};

// Parse a string to a dayjs.Dayjs object
export const ParseDayJsDate = (
  value: string | Date | undefined
): dayjs.Dayjs | undefined => {
  if (!value) return undefined;
  const defaultFormatted = dayjs(value, DATE_FORMAT_DEFAULT, true);
  if (defaultFormatted.isValid()) return defaultFormatted;
  return dayjs(value);
};

export const FormatCurrencyWithCurrencySymbol = (
  value: number,
  culture = "en-US",
  currency = "USD"
): string => {
  if ((value || value === 0) && culture) {
    const locales = Intl.NumberFormat(culture, {
      style: "currency",
      currency: currency,
    });
    return locales.format(value);
  }
};

export const FormatCurrencyWithoutCurrencySymbol = (
  value: number,
  culture = "en-US"
): string => {
  if (value && culture) {
    const locales = Intl.NumberFormat(culture);
    return locales.format(value);
  }
};
