import { httpWithTokenInHeader } from "clients/api.clients.base";
import { PatientClient } from "clients/api.generated.clients";
import { Dispatch } from "react";
import { ProviderActionType } from "store/provider/actionTypes";
import { Action } from "store/provider/actions";

const patientClient = new PatientClient("", httpWithTokenInHeader);

export const getProviders = () => async (dispatch: Dispatch<Action>) => {
  dispatch({
    type: ProviderActionType.GET_PROVIDERS,
  });
  await patientClient
    .getProviders()
    .then((response) => {
      if (response.success) {
        dispatch({
          type: ProviderActionType.GET_PROVIDERS_SUCCESS,
          payload: response,
        });
      } else {
        dispatch({
          type: ProviderActionType.GET_PROVIDERS_FAILURE,
          payload: response?.message,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: ProviderActionType.GET_PROVIDERS_FAILURE,
        payload: err.message,
      });
    });
};
