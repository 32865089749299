import { httpWithTokenInHeader } from "clients/api.clients.base";
import {
  PatientClient,
  PatientListRequestDto,
  PatientRequestDto,
} from "clients/api.generated.clients";
import { Dispatch } from "react";
import { PatientActionType } from "store/patient/actionTypes";
import { Action } from "store/patient/actions";

const patientClient = new PatientClient("", httpWithTokenInHeader);

export const getAllPatients =
  (values: PatientListRequestDto, cb?: (res) => void) =>
  async (dispatch: Dispatch<Action>) => {
    const reportListRequest = {
      ...values,
    };

    dispatch({
      type: PatientActionType.GET_PATIENTS,
    });
    await patientClient
      .getAllPatients(reportListRequest)
      .then((response) => {
        if (response.success) {
          dispatch({
            type: PatientActionType.GET_PATIENTS_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: PatientActionType.GET_PATIENTS_FAILURE,
            payload: response?.message,
          });
        }
        cb && cb(response);
      })
      .catch((err) => {
        dispatch({
          type: PatientActionType.GET_PATIENTS_FAILURE,
          payload: err.message,
        });
        cb && cb(err);
      });
  };

export const exportData =
  (values: PatientListRequestDto, cb?: (res) => void) =>
  async (dispatch: Dispatch<Action>) => {
    const reportListRequest = {
      ...values,
    };

    dispatch({
      type: PatientActionType.EXPORT_DATA_EXCEL,
    });

    await patientClient
      .exportData(reportListRequest)
      .then((response) => {
        if (response.success) {
          dispatch({
            type: PatientActionType.EXPORT_DATA_EXCEL_SUCCESS,
            payload: response.success,
          });
        } else {
          dispatch({
            type: PatientActionType.EXPORT_DATA_EXCEL_FAILURE,
            payload: response?.message,
          });
        }
        cb && cb(response);
      })
      .catch((err) => {
        dispatch({
          type: PatientActionType.EXPORT_DATA_EXCEL_FAILURE,
          payload: err.message,
        });
        cb && cb(err);
      });
  };

export const searchPatients =
  (value: any, cb?: (res) => void) => async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: PatientActionType.SEARCH_PATIENTS,
    });
    await patientClient
      .searchPatients(value)
      .then((response) => {
        if (response.success) {
          dispatch({
            type: PatientActionType.SEARCH_PATIENTS_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: PatientActionType.SEARCH_PATIENTS_FAILURE,
            payload: response?.message,
          });
        }
        cb && cb(response);
      })
      .catch((err) => {
        dispatch({
          type: PatientActionType.SEARCH_PATIENTS_FAILURE,
          payload: err.message,
        });
        cb && cb(err);
      });
  };

export const getPatientByMemberId =
  (values: PatientRequestDto, cb?: (res) => void) => async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: PatientActionType.GET_PATIENT_BY_MEMBERID,
    });
    await patientClient
      .getPatientByMemberId(values)
      .then((response) => {
        if (response.success) {
          dispatch({
            type: PatientActionType.GET_PATIENT_BY_MEMBERID_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: PatientActionType.GET_PATIENT_BY_MEMBERID_FAILURE,
            payload: response?.message,
          });
        }
        cb && cb(response);
      })
      .catch((err) => {
        dispatch({
          type: PatientActionType.GET_PATIENT_BY_MEMBERID_FAILURE,
          payload: err.message,
        });
        cb && cb(err);
      });
  };
