import { PaginationResponseDtoOfTargetDto } from "clients/api.generated.clients";
import { LineofBusinessTargetsActionType } from "./lobActionType";
import { LineofBusinessAction } from "./lobAction";

export interface TargetsState {
  loading: boolean;
  error: string | undefined;
  data: PaginationResponseDtoOfTargetDto | undefined;
  update: boolean;
}

const initialState: TargetsState = {
  loading: false,
  error: undefined,
  data: undefined,
  update: false,
};

const lineofBusinessTargetsReducer = (
  state: TargetsState = initialState,
  action: LineofBusinessAction
): TargetsState => {
  switch (action.type) {
    case LineofBusinessTargetsActionType.GET_LINE_OF_BUSINESS_TARGETS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case LineofBusinessTargetsActionType.GET_LINE_OF_BUSINESS_TARGETS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload.data,
      };

    case LineofBusinessTargetsActionType.GET_LINE_OF_BUSINESS_TARGETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: undefined,
      };

    case LineofBusinessTargetsActionType.SET_LINE_OF_BUSINESS_TARGETS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case LineofBusinessTargetsActionType.SET_LINE_OF_BUSINESS_TARGETS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        update: action.payload.data,
      };

    case LineofBusinessTargetsActionType.SET_LINE_OF_BUSINESS_TARGETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
export default lineofBusinessTargetsReducer;
