import { httpWithTokenInHeader } from "clients/api.clients.base";
import { PatientClient } from "clients/api.generated.clients";
import { Dispatch } from "react";
import { LineOfBusinessActionType } from "store/lineofbusiness/actionTypes";
import { Action } from "store/lineofbusiness/actions";

const patientClient = new PatientClient("", httpWithTokenInHeader);

export const getAllLineOfBusinessGroups =
  () => async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: LineOfBusinessActionType.GET_LINE_OF_BUSINESS_GROUPS,
    });
    await patientClient
      .getAllLineOfBusinessGroups()
      .then((response) => {
        if (response.success) {
          dispatch({
            type: LineOfBusinessActionType.GET_LINE_OF_BUSINESS_GROUPS_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: LineOfBusinessActionType.GET_LINE_OF_BUSINESS_GROUPS_FAILURE,
            payload: response?.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: LineOfBusinessActionType.GET_LINE_OF_BUSINESS_GROUPS_FAILURE,
          payload: err.message,
        });
      });
  };
