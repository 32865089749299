import React from "react";
import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const PadSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M8.5 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V6C20 5.46957 19.7893 4.96086 19.4142 4.58579C19.0391 4.21071 18.5304 4 18 4H15.5"
      stroke="#50834B"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M8 6.4V4.5C8 4.36739 8.05268 4.24021 8.14645 4.14645C8.24021 4.05268 8.36739 4 8.5 4C8.776 4 9.004 3.776 9.052 3.504C9.2 2.652 9.774 1 12 1C14.226 1 14.8 2.652 14.948 3.504C14.996 3.776 15.224 4 15.5 4C15.6326 4 15.7598 4.05268 15.8536 4.14645C15.9473 4.24021 16 4.36739 16 4.5V6.4C16 6.55913 15.9368 6.71174 15.8243 6.82426C15.7117 6.93679 15.5591 7 15.4 7H8.6C8.44087 7 8.28826 6.93679 8.17574 6.82426C8.06321 6.71174 8 6.55913 8 6.4Z"
      stroke="#50834B"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export const PadIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={PadSvg} {...props} />
);