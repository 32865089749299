import { ProviderActionType } from "./actionTypes";
import { Action } from "./actions";

export interface ProviderGroupState {
  loading: boolean;
  error: string | undefined;
  data: string[] | [];
}

const initialState = {
  loading: false,
  error: undefined,
  data: [],
};

const ProviderReducer = (
  state: ProviderGroupState = initialState,
  action: Action
): ProviderGroupState => {
  switch (action.type) {
    case ProviderActionType.GET_PROVIDERS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case ProviderActionType.GET_PROVIDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload.data,
      };

    case ProviderActionType.GET_PROVIDERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default ProviderReducer;
