export const TableFieldsName = {
  fullName: "FullName",
  firstName: "FirstName",
  middleName: "MiddleName",
  lastName: "LastName",
  birthDate: "BirthDate",
  memberId: "MemberId",
  payor: "Payor",
  lineOfBusinessGroup: "LineOfBusinessGroup",
  lastClaimDate: "LastClaimDate",
  lastUpdatedDate: "LastUpdatedDate",
  lastWellnessVisitDate: "LastWellnessVisitDate",
  productLines: "ProductLines",
  providerGroupName: "ProviderGroupName",
};

export const ShownFieldsName = {
  gender: "Gender",
  address1: "Address1",
  city: "City",
  state: "State",
  zipCode: "ZipCode",
  phone: "Phone",
  groupId: "GroupID",
  pcpId: "PCPId",
  pcpName: "PCPName",
  providerGroup: "ProviderGroup",
};

export const ShownFieldsLabel = {
  gender: "Gender",
  address1: "Address 1",
  city: "City",
  state: "State",
  zipCode: "Zip Code",
  phone: "Phone",
  groupId: "Group Id",
  pcpId: "PCP Id",
  pcpName: "PCP Name",
  providerGroup: "Provider Group",
};

export const TableColumns = [
  {
    title: "Name",
    dataIndex: "fullName",
    hasHideOption: false,
    isVisible: true,
  },
  {
    title: "DOB",
    dataIndex: "birthDate",
    hasHideOption: true,
    isVisible: true,
  },
  {
    title: "Provider Group Name",
    dataIndex: "providerGroupName",
    hasHideOption: true,
    isVisible: true,
  },
  {
    title: "Payor",
    dataIndex: "payor",
    hasHideOption: true,
    isVisible: true,
  },
  {
    title: "Line of Business",
    dataIndex: "lineOfBusinessGroup",
    hasHideOption: true,
    isVisible: true,
  },
  {
    title: "Info",
    dataIndex: "info",
    hasHideOption: false,
    isVisible: false,
  },
  {
    title: "Last Wellness Visit",
    dataIndex: "lastWellnessVisitDate",
    hasHideOption: true,
    isVisible: true,
  },
  {
    title: "Claims Within 3 Years",
    dataIndex: "claimsWithin3Years",
    hasHideOption: false,
    isVisible: false,
  },
  {
    title: "View",
    dataIndex: "view",
    hasHideOption: false,
    isVisible: false,
  },
];
